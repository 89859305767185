import { AlertColor } from '@mui/material';
import { BambooIcon, BancardIcon, DLocalIcon, RedenlaceIcon, ToAirlinePayIcon } from '../common/customIcons';
import { ReactElement } from 'react';

export interface ApiResponse<data> {
	status: 200;
	data: data;
	headers: {
		'X-Page': number;
		'X-Page-From': number;
		'X-Page-Size': 15;
		'X-Page-Total': number;
		'X-Page-Count': number;
	};
}

type Order = 'asc' | 'desc';

export const datePickerFormat = 'dd/MM/yyyy';

export interface ISort {
	field: string;
	order: Order;
}

export interface SelectFilterListData {
	id: string | number;
	name: string;
}

export class FilterModel {
	constructor(
		public label: string,
		public type: FilterType,
		public key: string,
		public value?: string | number | boolean | string[] | number[] | boolean[],
		public operator?: OperatorFilterEnum,
	) {}
}
export class DateToFromFilterModel extends FilterModel {
	constructor(
		public label: string,
		public key: string,
		public secondKey: string,
		public value?: string,
		public secondValue?: string,
	) {
		super(label, FilterType.DATEFROMTO, key, undefined, OperatorFilterEnum.BETWEEN);
	}
}

export class SelectFilterModelMultiple extends FilterModel {
	constructor(
		public label: string,
		public key: string,
		public data: SelectFilterListData[],
		public value?: string[] | number[],
	) {
		super(label, FilterType.LIST, key);
	}
}

export class SelectFilterModelSimple extends FilterModel {
	constructor(
		public label: string,
		public key: string,
		public data: SelectFilterListData[],
		public value?: string | number,
	) {
		super(label, FilterType.LIST, key);
	}
}

export interface IHeadCell {
	field: string;
	sortable: boolean;
	headerName: string;
}

export enum FilterType {
	STRING = 'text',
	NUMERIC = 'number',
	DATE = 'date',
	LIST = 'list',
	BOOLEAN = 'boolean',
	TIME = 'time',
	DATEFROMTO = 'dateFromTo',
}

export enum DateType {
	DATE = 'date',
	DATEFROM = 'dateFrom',
	DATETO = 'dateTo',
}

export enum OperatorFilterEnum {
	STARTS,
	CONTAINS,
	ENDS,
	EQUALS,
	GREATER,
	LESSER,
	GREATEROREQUAL,
	LESSEROREQUAL,
	BETWEEN,
}

export enum OrderTypeEnum {
	SIMPLE = 1,
	SIEMPREPAGO,
	BANCARD,
	ATCREDENLACE,
	WOMPI,
	DLOCAL,
}

export enum CountryCodeEnum {
	URUGUAY = 'UY',
	PARAGUAY = 'PY',
	COLOMBIA = 'CO',
	BOLIVIA = 'BO',
	TN_ITAU_PY = 'TN',
}

export interface OrderTypeInterface {
	id: OrderTypeEnum;
	name: string;
	countriesAllow: CountryCodeEnum[];
	icon: () => ReactElement;
}

export const orderTypes: OrderTypeInterface[] = [
	{
		id: OrderTypeEnum.SIMPLE,
		name: 'CardToAirline',
		countriesAllow: [CountryCodeEnum.URUGUAY],
		icon: ToAirlinePayIcon,
	},
	{
		id: OrderTypeEnum.SIEMPREPAGO,
		name: 'Bamboo',
		countriesAllow: [CountryCodeEnum.URUGUAY],
		icon: BambooIcon,
	},
	{
		id: OrderTypeEnum.BANCARD,
		name: 'BanCard',
		countriesAllow: [CountryCodeEnum.PARAGUAY, CountryCodeEnum.TN_ITAU_PY],
		icon: BancardIcon,
	},
	{
		id: OrderTypeEnum.ATCREDENLACE,
		name: 'RedEnlace',
		countriesAllow: [CountryCodeEnum.BOLIVIA],
		icon: RedenlaceIcon,
	},
	{
		id: OrderTypeEnum.WOMPI,
		name: 'Wompi',
		countriesAllow: [CountryCodeEnum.COLOMBIA],
		icon: RedenlaceIcon,
	},
	{
		id: OrderTypeEnum.DLOCAL,
		name: 'dLocal',
		countriesAllow: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
		],
		icon: DLocalIcon,
	},
];

export interface ICountryAgencyConfig {
	id: CountryCodeEnum;
	countryName: string;
	agencyId: number;
	apiKey: string;
	agencyIcon: string;
}

export interface ILocalize {
	city?: string;
	countryCode: string;
	countryName: string;
	citizen?: string;
	phoneCode?: string;
}

export interface ICountryFE {
	code: string;
	plCitizenCode: string;
	coinId: number;
	phoneCode: string;
	name: string;
	enable: boolean;
	plContinentalRegionName: string;
}

export interface ICityFESimple {
	code: string;
	lat?: number;
	lng?: number;
	name: string;
	isIata: boolean;
}

export interface IAirportFE {
	code: string;
	lat?: number;
	lng?: number;
	name: string;
	city: ICityFESimple;
	countryName: string;
}

export enum FormFieldsMaxLength {
	EMAIL = 255,
	PHONE = 20,
}

export enum RolesEnum {
	SUPERADMIN = 10,
	ADMIN = 1,
	USER,
	VIEW,
	SELLER,
	OPERATOR,
}

export interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export interface IMonthLabelValue {
	label: string;
	value: number;
}

export interface IEnvelopeList<T> {
	data: T[];
	status: number;
	headers: {
		'X-Page': number;
		'X-Page-From': number;
		'X-Page-Size': number;
		'X-Page-Total': number;
	};
}

export enum NetsuiteSyncStatusEnum {
	PendingSynchronization = 'PendingSynchronization',
	SynchronizationStart = 'SynchronizationStart',
	Synchronized = 'Synchronized',
	CreatingPurchaseOrders = 'CreatingPurchaseOrders',
	Error = 'Error',
}

export interface INetsuiteSyncStatusDTO {
	status: NetsuiteSyncStatusEnum;
	netSuiteId?: number;
	canExecuteSync?: boolean;
	lastSyncStartAt?: string;
	lastSuccessSyncAt?: string;
	lastError?: string;
	lastErrorAt?: string;
	lastActionExecutedBy?: string;
	detailUrlInNetsuite?: string;
}

export const netsuiteSyncStatusEnumWithName: Record<NetsuiteSyncStatusEnum, { name: string }> = {
	[NetsuiteSyncStatusEnum.Synchronized]: {
		name: 'Sincronizado',
	},
	[NetsuiteSyncStatusEnum.CreatingPurchaseOrders]: {
		name: 'Creando órdenes de compra',
	},
	[NetsuiteSyncStatusEnum.SynchronizationStart]: {
		name: 'Sincronización Iniciada',
	},
	[NetsuiteSyncStatusEnum.Error]: {
		name: 'Error',
	},
	[NetsuiteSyncStatusEnum.PendingSynchronization]: {
		name: 'Pendiente de Sincronización',
	},
};

export interface INimbuxCaseClient {
	id: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	completePhoneNumber?: string;
}
export interface INimbuxCaseSummaryToPower {
	id: number;
	name: string;
	status?: string;
	client?: INimbuxCaseClient;
	legacySaleOrderId?: string;
	agencyId?: number;
	agencyName?: string;
	agentId?: string;
	agentLastName?: string;
	createdAt: string;
	updatedAt?: string;
}
