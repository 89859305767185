import React, { useEffect, useState } from 'react';
import {
	Alert,
	AlertColor,
	Backdrop,
	Box,
	Button,
	Chip,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Snackbar,
	TextField,
	Theme,
	Typography,
	useTheme,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ICoin, IFilledOrderForm, OrderCreatedResult, OrderFormFieldsMaxLength, PaymentQuotas } from '../../types';
import { checkShowErrorMessage } from 'features/common/helpers';
import { getCoinsByOrderType, getFileByDealId, postFormData } from 'features/payment/services';
import { findContactsDataByDealID } from 'services';
import { AutocompleteContactData } from 'features/quotation/types';
import { extractErrorMessage } from 'features/quotation/helpers';
import { SelectMenuProps } from 'constants/constants';
import makeStyles from '@mui/styles/makeStyles';
import { SendClientLinkModal } from '../SendClientLinkModal';
import { emailPattern } from 'features/common/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { CountryCodeEnum, OrderTypeEnum } from 'features/common/types';
import BitrixDealOrCaseSwitch from '../../../common/components/BitrixDealOrCaseSwitch';
import BitrixDealOrCaseUserInfoConfirm from '../../../common/components/BitrixDealOrCaseUserInfoConfirm';
import { getNimbuxCaseSummary } from '../../../common/services';

function getStyles(quota: number, quotas: readonly number[], theme: Theme) {
	return {
		fontWeight: quotas.indexOf(quota) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
	};
}

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: theme.palette.common.white,
	},
	helperTextDanger: {
		color: theme.palette.error.dark,
	},
}));

export interface SimpleClientTransactionProps {
	id: number;
	name: string;
}

export const OrderForm = ({ id, name }: SimpleClientTransactionProps): JSX.Element => {
	const theme = useTheme();
	const classes = useStyles();
	const { auth } = useSelector((state: RootState) => state);

	const countryCode = auth.country;
	const isParaguay = countryCode === CountryCodeEnum.PARAGUAY;
	const isColombia = countryCode === CountryCodeEnum.COLOMBIA;
	const isDLocalOrder = id == OrderTypeEnum.DLOCAL;

	const orderFormDefaultValues = {
		crmTicket: 0,
		name: '',
		email: '',
		phone: '',
		file: '',
		fee: [1, 2, 3, 4, 5, 6],
		coinId: 10,
		amount: 0,
		plCountryCode: auth.country,
	};

	const {
		control,
		handleSubmit,
		watch,
		trigger,
		formState: { errors, isValid },
		getValues,
		setValue,
		reset,
	} = useForm({ mode: 'all', defaultValues: orderFormDefaultValues });
	const [crmTicketAfterConfirm, setCrmTicketAfterConfirm] = useState<number>(0);
	const [selectedContactDataOptionIndex, setSelectedContactDataOptionEmail] = useState<number | null>(null);
	const [filesByDealId, setFilesByDealId] = useState<string[]>([]);
	const [currenciesAllowedByOrderType, setCurrenciesAllowedByOrderType] = useState<ICoin[]>([]);
	const [contactDataOptions, setContactDataOptions] = useState<AutocompleteContactData[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [orderCreatedResult, setOrderCreatedResult] = useState<OrderCreatedResult | null>(null);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [isBitrixDeal, setIsBitrixDeal] = useState<boolean>(true);
	const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
	const crmTicketInFormValue = watch('crmTicket');

	const onSubmit = async () => {
		try {
			setLoading(true);
			const { amount, crmTicket } = getValues();
			const orderFormData = {
				orderType: +id,
				...getValues(),
				isBitrixDeal: isBitrixDeal,
				amount: +amount,
				crmTicket: +crmTicket,
				plCountryCode: auth.country,
				...(isParaguay || isColombia || isDLocalOrder ? { fee: undefined } : {}),
			};

			const clientLink = await postFormData(orderFormData as IFilledOrderForm);
			setOrderCreatedResult(clientLink.data);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Orden de pago creada satisfactoriamente',
			});
			reset();
			setCrmTicketAfterConfirm(0);
			setContactDataOptions([]);
			setSelectedContactDataOptionEmail(null);
			setLoading(false);
			setModalOpen(true);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al crear la orden de pago'),
			});
			setLoading(false);
		}
	};

	const handleCloseModal = () => {
		setOrderCreatedResult(null);
		setModalOpen(false);
	};

	const handleDeleteFee = (selectedQuotas: number[], quotaValue: number, onChange: (...event: any[]) => void) => {
		selectedQuotas.splice(selectedQuotas.indexOf(quotaValue), 1);
		onChange(selectedQuotas);
	};

	const handleSelectedQuota = (event: SelectChangeEvent<number[]>, onChange: (...event: any[]) => void) => {
		const {
			target: { value },
		} = event;
		const selectedQuotasValue = typeof value === 'string' ? value.split(',').map((x) => +x) : value;
		onChange(selectedQuotasValue);
	};

	const handleDealId = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			setLoading(true);
			setCrmTicketAfterConfirm(getValues('crmTicket'));
			setLoading(false);
		}
	};

	const handleSelectContactDataOption = (index: number) => setSelectedContactDataOptionEmail(index);

	const getDataAfterDealId = async () => {
		let files: string[] = [];
		let newContactData = [{ name: '', phone: '', email: '' }] as AutocompleteContactData[];
		setContactDataOptions([]);
		setSelectedContactDataOptionEmail(null);
		if (crmTicketAfterConfirm) {
			try {
				setLoading(true);
				setIsConfirmed(false);
				files = (await getFileByDealId(crmTicketAfterConfirm)).data;
				newContactData = (await findContactsDataByDealID(crmTicketAfterConfirm)).data;
				setContactDataOptions([...newContactData]);
				setLoading(false);
				setIsConfirmed(true);
			} catch (error: any) {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error en la peticion de datos correspondientes al deal ID'),
				});
				setLoading(false);
			}
		}
		setFilesByDealId(files);
	};

	const getDataOfCase = async () => {
		if (crmTicketAfterConfirm) {
			try {
				setIsConfirmed(false);
				setLoading(true);
				const response = await getNimbuxCaseSummary(crmTicketAfterConfirm);
				if (!response.data?.client) {
					setAlert({
						show: true,
						severity: 'error',
						message: 'El caso no tiene cliente asociado, por favor asocie un cliente al caso',
					});
					setContactDataOptions([]);
				} else {
					const client = response.data.client;
					if (!client.email) {
						setAlert({
							show: true,
							severity: 'error',
							message: 'Por favor complete el email del cleinte en el caso para el envío de la orden de pago',
						});
						setContactDataOptions([]);
					} else {
						const autocompleteContactData = {
							name: `${client.firstName ?? ''} ${client.lastName ?? ''}`.trim(),
							email: client.email,
							phone: client.completePhoneNumber,
						} as AutocompleteContactData;
						setContactDataOptions([autocompleteContactData]);
						setIsConfirmed(true);
					}
				}

				setLoading(false);
			} catch (error: any) {
				const errorMessage = extractErrorMessage(error, 'Ocurrió un error al buscar el caso');
				setAlert({
					show: true,
					severity: 'error',
					message: errorMessage,
				});
				setLoading(false);
			} finally {
				setFilesByDealId([]);
			}
		}
	};

	const getCoinsAtLoad = async () => {
		try {
			setLoading(true);
			const coinIds = (await getCoinsByOrderType(id)).data;
			setCurrenciesAllowedByOrderType(coinIds);
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir las Monedas'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getCoinsAtLoad();
	}, []);

	useEffect(() => {
		if (isConfirmed) {
			setIsConfirmed(false);
			setSelectedContactDataOptionEmail(null);
			setContactDataOptions([]);
			setCrmTicketAfterConfirm(0);
			setValue('name', '');
			setValue('phone', '');
			setValue('email', '');
			trigger(['name', 'phone', 'email']);
		}
	}, [crmTicketInFormValue]);

	useEffect(() => {
		if (isBitrixDeal) {
			getDataAfterDealId();
		} else {
			getDataOfCase();
		}
	}, [crmTicketAfterConfirm]);

	useEffect(() => {
		if (selectedContactDataOptionIndex !== null) {
			setValue('name', contactDataOptions[selectedContactDataOptionIndex].name);
			setValue('phone', contactDataOptions[selectedContactDataOptionIndex].phone || '');
			setValue('email', contactDataOptions[selectedContactDataOptionIndex].email || '');
			trigger(['name', 'phone', 'email']);
		}
	}, [selectedContactDataOptionIndex]);

	useEffect(() => {
		setSelectedContactDataOptionEmail(null);
		setContactDataOptions([]);
		reset();
		setCrmTicketAfterConfirm(0);
	}, [isBitrixDeal]);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container gap={2}>
					<Grid item xs={12} display="flex" justifyContent="center">
						<Typography variant="h6">Nueva Orden de Pago {name}</Typography>
					</Grid>
					<Grid item xs={12}>
						<BitrixDealOrCaseSwitch isBitrixDeal={isBitrixDeal} onChange={() => setIsBitrixDeal(!isBitrixDeal)} />
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="crmTicket"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								pattern: {
									value: /^(0|[1-9]\d*)(\.\d+)?$/,
									message: 'El valor debe ser numérico y mayor que 0',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label={isBitrixDeal ? 'Deal ID' : 'Caso'}
									type="number"
									disabled={loading}
									onKeyPress={handleDealId}
									helperText={checkShowErrorMessage(Boolean(errors.crmTicket), errors.crmTicket?.message)}
									error={Boolean(errors.crmTicket)}
									{...field}
								/>
							)}
						/>
						<BitrixDealOrCaseUserInfoConfirm isBitrixDeal={isBitrixDeal} />
					</Grid>
					{contactDataOptions.length > 0 && (
						<Grid item xs={12}>
							<Paper sx={{ width: '100%' }}>
								<Typography sx={{ padding: 2 }}>Seleccione un contacto:</Typography>
								<List
									component="nav"
									aria-label="Device settings"
									sx={{ bgcolor: 'background.paper', maxHeight: '250px', overflowY: 'scroll' }}
								>
									{contactDataOptions.map((option, index) => (
										<MenuItem
											key={'contact-option-' + index}
											selected={index === selectedContactDataOptionIndex}
											onClick={() => handleSelectContactDataOption(index)}
										>
											<ListItem id={'lock-button-' + index} aria-haspopup="listbox" aria-controls="lock-menu">
												<ListItemText primary={option.name} secondary={`${option.phone},${option.email}`} />
											</ListItem>
										</MenuItem>
									))}
								</List>
							</Paper>
						</Grid>
					)}
					<Grid item xs={12}>
						<Controller
							name="file"
							control={control}
							render={({ field }) => (
								<FormControl sx={{ width: '100%', height: '100%' }}>
									<InputLabel id="file-label">File</InputLabel>
									<Select
										labelId="file-label"
										id="file-select"
										label="File"
										disabled={filesByDealId.length < 1 || loading}
										sx={{ width: '100%' }}
										{...field}
									>
										{filesByDealId.map((file) => {
											return (
												<MenuItem key={file} value={file}>
													{file}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="name"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								maxLength: {
									value: OrderFormFieldsMaxLength.NAME,
									message:
										'El Nombre excede el largo máximo permitido de: ' + OrderFormFieldsMaxLength.NAME + ' caracteres',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Nombre"
									disabled={loading}
									helperText={checkShowErrorMessage(Boolean(errors.name), errors.name?.message)}
									error={Boolean(errors.name)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="phone"
							control={control}
							rules={{
								maxLength: {
									value: OrderFormFieldsMaxLength.PHONE,
									message:
										'El telefono excede el largo máximo permitido de: ' +
										OrderFormFieldsMaxLength.PHONE +
										' caracteres',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									fullWidth
									label="Teléfono"
									disabled={loading}
									helperText={checkShowErrorMessage(Boolean(errors.phone), errors.phone?.message)}
									error={Boolean(errors.phone)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="email"
							control={control}
							rules={{
								maxLength: {
									value: OrderFormFieldsMaxLength.EMAIL,
									message:
										'El correo electrónico excede el largo máximo permitido de: ' +
										OrderFormFieldsMaxLength.EMAIL +
										' caracteres',
								},
								pattern: {
									value: emailPattern,
									message: 'Ingrese un email válido',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									fullWidth
									label="Email"
									disabled={loading}
									helperText={checkShowErrorMessage(Boolean(errors.email), errors.email?.message)}
									error={Boolean(errors.email)}
									{...field}
								/>
							)}
						/>
					</Grid>
					{!isParaguay && !isColombia && !isDLocalOrder ? (
						<Grid item xs={12}>
							<Controller
								name="fee"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Por favor seleccione las cuotas permitidas',
									},
									validate: (value) => value.length > 0,
								}}
								render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
									<FormControl color={invalid ? 'error' : undefined} sx={{ width: '100%', height: '100%' }}>
										<InputLabel id="quota-select-label">Cuotas</InputLabel>
										<Select
											labelId="quota-select-label"
											id="quota-select"
											multiple
											label="Cuotas"
											value={value}
											onChange={(event) => handleSelectedQuota(event, onChange)}
											error={Boolean(errors.fee)}
											renderValue={(selected) => (
												<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
													{selected.map((valueSelected) => (
														<Chip
															key={'render' + valueSelected}
															label={valueSelected}
															onMouseDown={(e) => {
																e.stopPropagation();
															}}
															onDelete={() => handleDeleteFee(value, valueSelected, onChange)}
														/>
													))}
												</Box>
											)}
											MenuProps={SelectMenuProps}
										>
											{PaymentQuotas.map((quota) => (
												<MenuItem
													key={`quota-key-${quota}`}
													value={quota}
													style={getStyles(quota, PaymentQuotas, theme)}
												>
													{quota}
												</MenuItem>
											))}
										</Select>
										{error && <FormHelperText sx={{ color: 'red' }}>{error.message}</FormHelperText>}
									</FormControl>
								)}
							/>
						</Grid>
					) : null}
					<Grid item xs={12}>
						<Controller
							name="coinId"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Por favor seleccione la moneda a usar',
								},
							}}
							render={({ field }) => (
								<FormControl sx={{ width: '100%', height: '100%' }}>
									<InputLabel id="coinId-label">Moneda</InputLabel>
									<Select
										labelId="coinId-label"
										id="coinId-select"
										label="Moneda"
										disabled={!currenciesAllowedByOrderType.length || loading}
										sx={{ width: '100%' }}
										{...field}
									>
										{currenciesAllowedByOrderType.map((currency) => {
											return (
												<MenuItem key={currency.id} value={currency.id}>
													{currency.code}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="amount"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								min: {
									value: 1,
									message: 'El monto debe ser mayor que 0',
								},
								pattern: {
									value: /^(0|[1-9]\d*)(\.\d+)?$/,
									message: 'El valor debe ser numérico',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Monto"
									type="number"
									disabled={loading}
									helperText={checkShowErrorMessage(Boolean(errors.amount), errors.amount?.message)}
									error={Boolean(errors.amount)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} display={'flex'} gap={'10px'} justifyContent="flex-end">
						<Button
							variant="contained"
							disabled={loading || !isValid || selectedContactDataOptionIndex === null || !isConfirmed}
							color="success"
							fullWidth
							onClick={onSubmit}
						>
							Generar Orden de Pago
						</Button>
					</Grid>
				</Grid>
			</form>
			{orderCreatedResult && (
				<SendClientLinkModal
					id={orderCreatedResult.id}
					handleClose={handleCloseModal}
					open={modalOpen}
					link={orderCreatedResult.link}
					isViajeNaranja={false}
				/>
			)}
			<Snackbar open={alert.show} autoHideDuration={2500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
