import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
	Alert,
	Backdrop,
	Box,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	MenuItem,
	Pagination,
	Select,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { pageSizeByDefault, pageSizeOptions } from 'constants/constants';

import { PageNoResult } from 'features/common/components/PageNoResults';
import { FilterModel, FilterType, IHeadCell, ISort, OperatorFilterEnum, ShowAlertState } from 'features/common/types';
import { extractErrorMessage } from 'features/quotation/helpers';

import { useStyles } from './styles';
import { useDebounceState } from 'features/common/hooks/useDebounceState';
import { convertFiltersToRecord, createSortHandler, getSortableFields } from 'features/common/helpers';
import { defaultAlertState } from '../../../common/constants';
import { FilterGrid } from '../../../common/components/FilterGrid/FilterGrid';
import { IPassengerList } from '../../types';
import { getPassengersList } from '../../services';
import routes from '../../../../constants/routes';

export const passengerListFilters = [
	{
		label: 'ID',
		type: FilterType.STRING,
		key: 'id',
		operator: OperatorFilterEnum.EQUALS,
	},
	{
		label: 'Nombre',
		type: FilterType.STRING,
		key: 'name',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		label: 'Apellidos',
		type: FilterType.STRING,
		key: 'lastName',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		label: 'País',
		type: FilterType.STRING,
		key: 'countryName',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		label: 'Facturable',
		type: FilterType.BOOLEAN,
		key: 'invoiceable',
		operator: OperatorFilterEnum.EQUALS,
	},
	{
		label: 'Es Persona física',
		type: FilterType.BOOLEAN,
		key: 'isPhysicalPerson',
		operator: OperatorFilterEnum.EQUALS,
	},
];

const headerCells: IHeadCell[] = [
	{
		field: 'id',
		sortable: true,
		headerName: 'ID',
	},
	{
		headerName: 'Nombre / Razón Social',
		sortable: true,
		field: 'name',
	},
	{
		headerName: 'Apellidos / Nom. Fantasía',
		field: 'lastName',
		sortable: true,
	},
	{
		headerName: 'País',
		sortable: true,
		field: 'countryName',
	},
	{
		headerName: 'Facturable',
		sortable: true,
		field: 'invoiceable',
	},
	{
		headerName: 'Personería jurídica',
		sortable: true,
		field: 'isPhysicalPerson',
	},
];

export const PassengersList = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);
	const classes = useStyles();
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [currentSize, setCurrentSize] = useState<number>(pageSizeByDefault);
	const [pageTotal, setPageTotal] = useState<number>(0);
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [searchValue, setSearchValue] = useState<string>('');
	const [sortFields, setSortFields] = useState<ISort[]>([{ field: 'name', order: 'asc' }]);
	const [data, setData] = useState<IPassengerList[]>([]);
	const debouncedSearch: string = useDebounceState(searchValue, 300);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>([]);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};
	const handleApplyFilters = (filters: FilterModel[]) => setFilterApplied([...filters]);
	const handleCloseAlert = () => {
		setAlert(defaultAlertState);
	};

	const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
		setCurrentSize(+event.target.value);
		setCurrentPage(1);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	const loadData = useCallback(async () => {
		try {
			setLoading(true);
			const { data } = await getPassengersList(
				currentPage,
				currentSize,
				sortFields,
				convertFiltersToRecord(filterApplied),
				debouncedSearch,
			);
			setPageTotal(Math.ceil(data.total / currentSize));
			setTotalRecords(data.total);
			setData(data.elements);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado '),
			});
			setLoading(false);
		}
	}, [
		setPageTotal,
		setTotalRecords,
		setData,
		setLoading,
		setAlert,
		currentPage,
		currentSize,
		debouncedSearch,
		filterApplied,
	]);

	useEffect(() => {
		loadData();
	}, [loadData, currentPage, currentSize, debouncedSearch]);

	const sortableFields = getSortableFields(headerCells);

	const handleOpenDetail = (id: string) => {
		const newUrl = routes.passengers.detail.replace(':id', id);
		window.open(newUrl, '_blank');
	};
	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container sx={{ justifyContent: 'center' }} gap={1}>
				<Grid container className={classes.container} sx={{ maxWidth: 'xl' }}>
					<Grid item xs={3}>
						<TextField
							label="Buscar"
							variant="outlined"
							size="small"
							value={searchValue}
							onChange={handleChange}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={9} className={classes.containerButtons} gap={1}>
						{totalRecords || 0} Resultados
						<FilterGrid filters={passengerListFilters} handleAppliedFilters={handleApplyFilters} />
					</Grid>
				</Grid>
				<TableContainer component="main" sx={{ maxWidth: 'xl' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{headerCells.map((cell) => {
										return cell.sortable ? (
											<TableCell
												key={cell.field}
												align="right"
												className={classes.tableHeader}
												sortDirection={
													sortFields.find((x) => x.field === cell.field)
														? sortFields.find((x) => x.field === cell.field)?.order
														: false
												}
											>
												<TableSortLabel
													active={cell.sortable && sortFields.find((x) => x.field === cell.field) !== undefined}
													sx={{
														color: 'white !important',
														'&:hover': {
															color: 'white !important',
														},
													}}
													direction={
														sortFields.find((x) => x.field === cell.field)
															? sortFields.find((x) => x.field === cell.field)?.order
															: 'asc'
													}
													onClick={createSortHandler(cell.field, sortFields, sortableFields, setSortFields)}
												>
													{cell.headerName}
												</TableSortLabel>
											</TableCell>
										) : (
											<TableCell className={classes.tableHeader} key={cell.field} align="right">
												{cell.headerName}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{!data.length ? (
									<TableRow>
										<TableCell colSpan={7}>
											<PageNoResult />
										</TableCell>
									</TableRow>
								) : (
									data.map((item) => (
										<TableRow key={item.id} onDoubleClick={() => handleOpenDetail(item.id)}>
											<TableCell align="right" component="p">
												<Link
													title={'Ver detalle de pasajero'}
													href={`${routes.passengers.detail.replace(':id', item.id)}`}
													target="_blank"
													rel="noopener noreferrer"
												>
													{item.id}
												</Link>
											</TableCell>
											<TableCell align="right" component="p">
												{item.name}
											</TableCell>
											<TableCell align="right" component="p">
												{item.lastName}
											</TableCell>
											<TableCell align="right" component="p">
												{item.countryName}
											</TableCell>
											<TableCell align="right" component="p">
												{item.invoiceable ? 'Sí' : 'No'}
											</TableCell>
											<TableCell align="right" component="p">
												{item.isPhysicalPerson ? 'Persona física' : 'Persona jurídica'}
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
				<Box
					component="main"
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					sx={{ width: '100%', maxWidth: 'xl' }}
				>
					<Select value={currentSize} onChange={handleRowsPerPageChange}>
						{pageSizeOptions.map((value) => (
							<MenuItem key={value} value={value}>
								{value}
							</MenuItem>
						))}
					</Select>
					<Pagination count={pageTotal} page={currentPage} onChange={handlePageChange} color="primary" />
				</Box>
			</Grid>

			<Snackbar open={alert.show} autoHideDuration={2000} onClose={handleCloseAlert}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
