import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { IIssueRequestDetail } from '../../../../../features/salesOrder/types';
import { formatDateTimeToShowUser, getDetailOfCaseInCRM } from '../../../../../features/common/helpers';

interface Props {
	issueRequestDetail?: IIssueRequestDetail; // Puede ser opcional
}

const IssueDetailButton: React.FC<Props> = (props: Props) => {
	const { issueRequestDetail } = props;
	if (!issueRequestDetail) return null;

	const caseUrlDetail = getDetailOfCaseInCRM(issueRequestDetail.caseId);

	const handleButtonClick = () => window.open(caseUrlDetail, '_blank');

	const tooltipTitle = `Emisión solicitada el ${formatDateTimeToShowUser(issueRequestDetail.created).substr(
		0,
		16,
	)}  por: ${issueRequestDetail.createdBy}. Click para abrir el detalle del caso`;

	return (
		<Tooltip title={tooltipTitle} arrow>
			<Button variant="outlined" color="primary" endIcon={<OpenInNew />} onClick={handleButtonClick}>
				Emisión solicitada (Caso #{issueRequestDetail.caseId})
			</Button>
		</Tooltip>
	);
};

export default IssueDetailButton;
