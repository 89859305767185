import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
	Alert,
	AlertColor,
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	MenuItem,
	Select,
	Snackbar,
	Typography,
} from '@mui/material';
import {
	IPaymentTitularWompi,
	ITransactionInGatewayWompiResponse,
	IWompiFormData,
	PaymentTitularFormFieldLength,
} from '../../types';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import { gatewayDistinctToDLocalDocumentTypeList } from 'features/transactions/types';
import { cancelBancardTransaction, createWompiTransaction } from 'features/payment/services';
import { FormFieldsMaxLength } from 'features/common/types';
import { countries, emailPattern, requiredWithMessage } from 'features/common/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { WompiModalForm } from '../WompiModalForm';
import { ControlledTextField } from 'features/common/components/ControlledTextField';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: theme.palette.common.white,
	},
	helperTextDanger: {
		color: theme.palette.error.dark,
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const defaultTransactionValues = {
	orderId: '',
	firstName: '',
	lastName: '',
	document: '',
	documentType: '',
	email: '',
	phoneNumber: '',
	phoneNumberPrefix: '',
};
export const WompiClientTransaction = (): JSX.Element | null => {
	const { payment } = useSelector((state: RootState) => state);
	const classes = useStyles();
	const methods = useForm({
		mode: 'all',
		defaultValues: { ...defaultTransactionValues, orderId: payment?.orderDataToClient?.orderId },
	});
	const {
		control,
		formState: { isValid },
		handleSubmit,
		reset,
	} = methods;
	const [loading, setLoading] = useState(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [transactionResponse, setTransactionResponse] = useState<ITransactionInGatewayWompiResponse | null>(null);
	const handleCancelBancardTransaction = async () => {
		try {
			setLoading(true);
			transactionResponse && (await cancelBancardTransaction(transactionResponse.shop_process_id));
			setTransactionResponse(null);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Se ha cancelado con éxito la transacción',
			});
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cancelar la transacción'),
			});
			setLoading(false);
		}
	};

	const onSubmit = async (data: any) => {
		try {
			setLoading(true);
			const titularInfoFromForm: IPaymentTitularWompi = (({
				firstName,
				lastName,
				email,
				phoneNumber = '',
				phoneNumberPrefix = '',
				documentType,
				document,
			}) => ({ firstName, lastName, email, phoneNumber, phoneNumberPrefix, documentType, document }))(data);
			const dataToSend: IWompiFormData = {
				paymentTitularWompi: titularInfoFromForm,
				orderId: payment.orderDataToClient?.orderId || '',
			};
			const wompiFormResponse = (await createWompiTransaction(dataToSend)).data;
			if (wompiFormResponse) {
				setTransactionResponse(wompiFormResponse);
			} else {
				setAlert({
					show: true,
					severity: 'error',
					message: 'No se pudo iniciar la transacción con la pasarela de pago',
				});
			}
			setLoading(false);
			reset();
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al iniciar la transacción con la pasarela de pago'),
			});
			setLoading(false);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Typography variant="h6">Orden de pago por:</Typography>
			<Typography variant="h6">
				{payment.orderDataToClient?.coinCode} {payment.orderDataToClient?.amount}
			</Typography>
			<Typography variant="h6" paddingBottom={2}>
				Datos del Titular de Pago
			</Typography>
			<FormProvider {...methods}>
				<form noValidate onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={12} md={6}>
							<Typography color={'Grey'} mb={1}>
								Nombre
							</Typography>
							<ControlledTextField
								name="firstName"
								rules={{
									required: requiredWithMessage,
									maxLength: {
										value: PaymentTitularFormFieldLength.FIRSTNAME,
										message:
											'El nombre excede la cantidad de caracteres permitida de ' +
											PaymentTitularFormFieldLength.FIRSTNAME,
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography color={'Grey'} mb={1}>
								Apellido
							</Typography>
							<ControlledTextField
								name="lastName"
								rules={{
									required: requiredWithMessage,
									maxLength: {
										value: PaymentTitularFormFieldLength.LASTNAME,
										message:
											'El apellido excede la cantidad de caracteres permitida de ' +
											PaymentTitularFormFieldLength.LASTNAME,
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography color={'Grey'} mb={1}>
								Email
							</Typography>
							<ControlledTextField
								name="email"
								rules={{
									pattern: {
										value: emailPattern,
										message: 'Ingrese un email válido',
									},
									maxLength: {
										value: FormFieldsMaxLength.EMAIL,
										message:
											'El email excede el largo máximo permitido de: ' + FormFieldsMaxLength.EMAIL + ' caracteres',
									},
								}}
							/>
						</Grid>
						<Grid item xs={6} md={5}>
							<Controller
								name="phoneNumberPrefix"
								control={control}
								rules={{
									required: true,
								}}
								render={({ field }) => (
									<FormControl required sx={{ width: '100%' }}>
										<Typography color={'Grey'} mb={1}>
											Codigo de area
										</Typography>
										<Select {...field}>
											{countries?.map((document) => {
												return (
													<MenuItem key={document.coinId} value={document.code}>
														{document.name} +{document.phoneCode}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography color={'Grey'} mb={1}>
								Telefono
							</Typography>
							<ControlledTextField
								name="phoneNumber"
								rules={{
									maxLength: {
										value: FormFieldsMaxLength.PHONE,
										message: 'El teléfono excede la cantidad de caracteres permitida de ' + FormFieldsMaxLength.PHONE,
									},
								}}
							/>
						</Grid>
						<Grid item xs={6} md={6}>
							<Controller
								name="documentType"
								control={control}
								rules={{
									required: requiredWithMessage,
								}}
								render={({ field }) => (
									<FormControl sx={{ width: '100%' }}>
										<Typography color={'Grey'} mb={1}>
											Tipo de Documento
										</Typography>
										<Select label="Tipo de Documento" {...field}>
											{gatewayDistinctToDLocalDocumentTypeList?.map((document) => {
												return (
													<MenuItem key={document.id} value={document.id}>
														{document.name}
													</MenuItem>
												);
											})}
											;
										</Select>
									</FormControl>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography color={'Grey'} mb={1}>
								Documento
							</Typography>
							<ControlledTextField
								name="document"
								rules={{
									required: requiredWithMessage,
									maxLength: {
										value: PaymentTitularFormFieldLength.DOCUMENT,
										message:
											'El documento excede la cantidad de caracteres permitida de ' +
											PaymentTitularFormFieldLength.DOCUMENT,
									},
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant="contained"
								sx={{ height: '100%', width: '100%' }}
								type="submit"
								disabled={!isValid}
								color="success"
							>
								Procesar
							</Button>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
			{transactionResponse && (
				<WompiModalForm
					open={transactionResponse !== null}
					reference={transactionResponse.reference}
					publicKey={transactionResponse.publicKey}
					amountInCents={transactionResponse.amountInCents}
					currency={transactionResponse.currency}
					integritySignature={transactionResponse.integritySignature}
					customerData={transactionResponse.customerData}
					onCancel={handleCancelBancardTransaction}
				/>
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
