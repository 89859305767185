import React from 'react';
import { Layout } from 'features/common/components/Layout';
import { Card, CardContent, CardMedia, Typography, Grid, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { isOperatorOrHigher, isAdminOrSuperAdmin } from '../../features/auth/helpers';
import { CountryCodeEnum } from '../../features/common/types';

const cardData = [
	{
		id: 1,
		icon: '🏨',
		title: 'Cotizar Alojamiento',
		description:
			'Debe utilizarse cuando se necesita solicitar la cotización de un alojamiento específico que no ha podido ser cotizado por los medios habituales.',
		link: 'https://crm.nimbux.tech/support/oper-cotizar-alojamiento',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 2,
		icon: '🔎',
		title: 'Consulta sobre Politicas de Reserva',
		description:
			'Debe utilizarse cuando se necesita verificar las condiciones de modificación, cancelación o penalidades aplicables a una reserva.',
		link: 'https://crm.nimbux.tech/support/oper-consulta-sobre-politicas-de-reserva',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 3,
		icon: '🔄',
		title: 'Aviso llegada tardía',
		description:
			'Utilizar cuando se requiera informar sobre una llegada tardía de un pasajero a uno o más servicios reservados.',
		link: 'https://crm.nimbux.tech/support/oper-aviso-llegada-tard-a',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 4,
		icon: '📥',
		title: 'Soporte Servicios',
		description:
			'Completar para resolver problemas, gestionar una queja, o enviar información adicional al prestador de servicios.',
		link: 'https://crm.nimbux.tech/support/oper-soporte',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 5,
		icon: '🔄',
		title: 'Modificación de Reserva',
		description:
			'Aplica en casos donde ya se encuentren generados gastos asociados a la reserva, cuando no se pueda cotizar un nuevo servicio o en reservas sin acceso.',
		link: 'https://crm.nimbux.tech/support/oper-modificaci-n-de-reserva',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 6,
		icon: '🚌',
		title: 'Cotizar traslados',
		description:
			'Debe completarse cuando no se disponga de las herramientas para cotizar un traslado y se necesite obtener una cotización detallada.',
		link: 'https://crm.nimbux.tech/support/oper-cotizar-traslados',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 7,
		icon: '📝',
		title: 'Cotizar Servicio (Genérico)',
		description:
			'Debe completarse cuando se necesite obtener una cotización para un servicio genérico que no se encuentre especificado bajo las categorías de traslados o alojamiento.',
		link: 'https://crm.nimbux.tech/support/oper-cotizar-servicio',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 8,
		icon: '❌',
		title: 'Cancelar Reserva',
		description:
			'Utilizar cuando se requiere cancelar una reserva de algún servicio que no puede ser cancelada por el vendedor o que requiere soporte especial.',
		link: 'https://crm.nimbux.tech/support/oper-cancelar-reserva',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
];

const ServicesOperations = (): JSX.Element => {
	const { auth } = useSelector((state: RootState) => state);

	const filteredCards = cardData.filter(
		(card) =>
			card.allowedCountries.includes(auth.country) &&
			(card.visibleTo === 'all' ||
				(card.visibleTo === 'operator' && isOperatorOrHigher(auth.user)) ||
				(card.visibleTo === 'admin' && isAdminOrSuperAdmin(auth.user))),
	);
	return (
		<Layout>
			<>
				<Box sx={{ flexGrow: 1, padding: 2, margin: '0 20%' }}>
					<RouterLink to="/home" style={{ textDecoration: 'none' }}>
						<Typography mb={2}>Click para volver al HOME</Typography>
					</RouterLink>
					<Grid container spacing={2} justifyContent="center">
						{filteredCards.map((card) => (
							<Grid item xs={12} sm={6} md={4} lg={3} key={card.id}>
								{card.isExternal ? (
									<a href={card.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
										<Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxWidth: '240' }}>
											<CardMedia
												component="div"
												sx={{ padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
											>
												<Typography variant="h4">{card.icon}</Typography>
											</CardMedia>
											<CardContent sx={{ flexGrow: 1 }}>
												<Typography variant="subtitle1" component="div">
													{card.title}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{card.description}
												</Typography>
											</CardContent>
										</Card>
									</a>
								) : (
									<RouterLink to={card.link} style={{ textDecoration: 'none' }}>
										<Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxWidth: '240' }}>
											<CardMedia
												component="div"
												sx={{ padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
											>
												<Typography variant="h4">{card.icon}</Typography>
											</CardMedia>
											<CardContent sx={{ flexGrow: 1 }}>
												<Typography variant="subtitle1" component="div">
													{card.title}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{card.description}
												</Typography>
											</CardContent>
										</Card>
									</RouterLink>
								)}
							</Grid>
						))}
					</Grid>
				</Box>
			</>
		</Layout>
	);
};

export default ServicesOperations;
