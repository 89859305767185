import React from 'react';
import { Box, Button, Card, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GetPAXTotalsResponse } from '../../helpers';
import { HotelCardDesktop } from 'features/hotels/components/HotelCardDesktop';
import { DestinationSimple, HotelOptionsSimple } from '../../types';
import useBreakpoints from '../../../common/hooks/useBreakpoints';
import { HotelCardMobile } from '../../../hotels/components/HotelCardMobile';
import { HotelOptionsPricePreviewDesktop } from './HotelOptionsPricePreviewDesktop';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { countryWAPMapping } from 'constants/constants';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	table: {
		minWidth: 650,
	},
	formControl: {
		marginTop: -3,
		width: '100%',
	},
	formGroup: {
		marginTop: 14,
	},
	optionTableHolder: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	utility: {
		color: theme.palette.secondary.main,
		fontWeight: 'bold',
	},
	sign: {
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
}));
interface Props {
	destinations: DestinationSimple[];
	paxTotals: GetPAXTotalsResponse;
	showUtility: boolean;
	markup: number | null;
	totalFlights: number;
	flightIndexSelected: number | null;
	exchangeRate: number;
	localCurrency: string;
	showLocalCurrency?: boolean;
	quotationId: number;
}

export const QuotationFinalPricePreview = (props: Props): JSX.Element => {
	const classes = useStyles();
	const isMediumDown = useBreakpoints().mediumDown;

	const { auth } = useSelector((state: RootState) => state);
	const countryWAP = countryWAPMapping[auth.country] || '59897683318';

	const { destinations, paxTotals, totalFlights, flightIndexSelected, quotationId } = props;
	const hotelOptionsToShow = (hotelOptions: HotelOptionsSimple[]): HotelOptionsSimple[] => {
		if (totalFlights > 1 && flightIndexSelected != null) {
			return hotelOptions.filter((x) => x.flightIndex === flightIndexSelected);
		} else {
			return hotelOptions;
		}
	};

	return (
		<>
			{destinations.map((destinationItem) => {
				return hotelOptionsToShow(destinationItem.hotelOptions).map((option, index) => {
					const optionMessage = `Hola, quiero avanzar con la opción ${
						index + 1
					} de la cotización n* ${quotationId} con el hotel ${option?.details?.name}.`;
					const encodeOptionMessage = encodeURIComponent(optionMessage);
					const moveForwardLink = `https://wa.me/${countryWAP}?text=${encodeOptionMessage}`;

					return (
						<div key={index} className={classes.optionTableHolder}>
							{!isMediumDown && (
								<Typography component="h1" variant="subtitle1" fontWeight="bold">
									Opción {index + 1}:
								</Typography>
							)}
							{isMediumDown && (
								<Typography
									component="h1"
									variant="h6"
									sx={{
										marginBottom: '8px',
										width: '100%',
										backgroundColor: '#ED6602',
										paddingLeft: '16px',
										paddingRight: '16px',
										paddingTop: '2px',
										paddingBottom: '2px',
										borderRadius: '4px',
										fontSize: '1rem',
										color: 'white',
									}}
								>
									Opción {index + 1}:
								</Typography>
							)}
							{!isMediumDown && (
								<>
									{option.details && (
										<HotelCardDesktop
											title={option.details.name}
											address={option.details.address}
											category={option.details.accommodationCategory}
											rating={option.details.rating}
											ratingText={option.details.ratingText}
											commentsCount={option.details.amountReviews}
											mainImage={option.details.mainImage}
											link={option.details.link}
											pool={option.details.pool}
											airConditioning={option.details.airConditioning}
											wifi={option.details.wifi}
											gym={option.details.gym}
											parking={option.details.parking}
										/>
									)}
									<HotelOptionsPricePreviewDesktop
										exchangeRate={props.exchangeRate}
										localCurrency={props.localCurrency}
										showLocalCurrency={props.showLocalCurrency}
										hotelOption={option}
										markup={props.markup}
										showUtility={props.showUtility}
										paxTotals={paxTotals}
									/>
								</>
							)}

							{option.details && isMediumDown && (
								<HotelCardMobile
									exchangeRate={props.exchangeRate}
									localCurrency={props.localCurrency}
									showLocalCurrency={props.showLocalCurrency}
									hotelOption={option}
									markup={props.markup}
									showUtility={props.showUtility}
									paxTotals={paxTotals}
								/>
							)}
							{!props.showUtility && (
								<Card sx={{ display: 'flex', marginTop: '8px' }}>
									<Box sx={{ display: 'flex', flexDirection: 'column', padding: '8px', width: '100%' }}>
										<Typography variant="body2" fontSize={12}>
											* El valor indicado incluye todos los servicios mencionados en la seccion inicial{' '}
											<strong>incluye</strong>.
										</Typography>
										<Typography variant="body2" fontSize={12}>
											{' '}
											{paxTotals.child > 0
												? '* Son considerados niños aquellos pasajeros que tengan entre 2 y 11 años al momento de culminar el viaje'
												: ''}{' '}
										</Typography>
										<Typography variant="body2" fontSize={12}>
											{' '}
											{paxTotals.infants > 0
												? '* Son considerados bebés aquellos pasajeros que tengan entre 0 y 1 año al momento de culminar el viaje'
												: ''}{' '}
										</Typography>
										<Button
											rel="noopener noreferrer"
											target="_blank"
											component="a"
											href={moveForwardLink}
											sx={{ float: 'inline-end', margin: '8px', width: 'fit-content', alignSelf: 'self-end' }}
											size="small"
											variant="contained"
											color="success"
										>
											Solicitar reserva opción {index + 1}
										</Button>
									</Box>
								</Card>
							)}
						</div>
					);
				});
			})}
		</>
	);
};
