import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
	Alert,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	Pagination,
	Paper,
	Select,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { pageSizeOptions } from 'constants/constants';

import { extractErrorMessage } from 'features/quotation/helpers';
import { FilterGrid } from 'features/common/components/FilterGrid/FilterGrid';
import { PageNoResult } from 'features/common/components/PageNoResults';
import { ExportToExcelButton } from 'features/common/components/ExportToExcelButton';
import {
	convertFiltersToRecord,
	createSortHandler,
	ExportExcelOptions,
	exportToExcel,
	getSortableFields,
} from 'features/common/helpers';
import { FilterModel, ISort, ShowAlertState } from 'features/common/types';

import { getChannels, getSalesOrderList, getSalesOrderListToExport } from '../../services';
import { IChannel, ISaleOrderListItem } from '../../types';
import { formatDateToLocal, transformGrossProfit, transformValue } from './helper';
import { balanceStyle, ColoredLinearProgress, saleOrderStyles } from './styles';
import { getSaleOrderFilters, headerCells } from './constants';
import SyncProcessNetsuiteOptionsMenu from '../SyncProcessNetsuiteOptionsMenu';
import routes from 'constants/routes';
import { isOperatorOrHigher } from '../../../../features/auth/helpers';
import DealOrCaseOpenDetail from '../../../common/components/DealOrCaseOpenDetail';

const ColoredLinearProgressWithLabel = ({ value }: { value: number }) => (
	<Box position="relative" display="inline-flex" width="100%" alignItems="center">
		<ColoredLinearProgress variant="determinate" value={value} sx={{ width: '100%' }} />
		<Box
			position="absolute"
			left="50%"
			top="50%"
			sx={{
				transform: 'translate(-50%, -50%)',
				color: 'text.primary',
			}}
		>
			<Typography variant="subtitle2" component="div" color="textSecondary">
				{`${Math.round(value)}%`}
			</Typography>
		</Box>
	</Box>
);

const formatDate = (value: string) => {
	const arr = value.split('-');
	return `${arr[2]}/${arr[1]}/${arr[0]}`;
};

const calculateDaysToTravel = (travelDate?: string | number | Date) => {
	if (!travelDate) return '-';
	const today = new Date();
	const departureDate = new Date(travelDate);
	if (isNaN(departureDate.getTime())) {
		return '-';
	}
	const diffTime = departureDate.getTime() - today.getTime();
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	return diffDays > 0 ? diffDays : 0;
};

function processText(text: string | any[] | undefined, limit: number) {
	if (!text) {
		return '';
	}

	let fullText = '';
	if (Array.isArray(text)) {
		fullText = text.join(', ');
	} else {
		fullText = text;
	}
	const capitalized = fullText.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
	if (capitalized.length > limit) {
		return capitalized.substring(0, limit) + '...';
	}
	return capitalized;
}

const serviceTypeToEmoji: Record<string, { emoji: string; name: string }> = {
	Accommodation: { emoji: '🛏️', name: 'Alojamiento' },
	Flight: { emoji: '✈️', name: 'Vuelo' },
	FlightAncillary: { emoji: '🧳', name: 'Adicionales de vuelo' },
	Other: { emoji: '🧩', name: 'Otros' },
	Transfer: { emoji: '🚌', name: 'Traslados' },
	Fee: { emoji: '💵', name: 'Fee' },
};

export const SalesOrderList = (): JSX.Element => {
	const classes = saleOrderStyles();
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [sortFields, setSortFields] = useState<ISort[]>([{ field: 'created', order: 'desc' }]);
	const [currentSize, setCurrentSize] = useState<number>(10);
	const [pageTotal, setPageTotal] = useState<number>(0);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>([]);
	const [salesOrderList, setSalesOrderList] = useState<ISaleOrderListItem[] | null>([]);
	const colSpan = headerCells.length;
	const [searchValue, setSearchValue] = useState<string>('');
	const [channels, setChannels] = useState<IChannel[]>([]);

	const handleApplyFilters = (filters: FilterModel[]) => {
		localStorage.setItem('salesOrderFilters', JSON.stringify(filters));
		setFilterApplied(filters);
	};

	useEffect(() => {
		const storedFilters = localStorage.getItem('salesOrderFilters');
		const initialFilters = storedFilters ? JSON.parse(storedFilters) : [];
		setFilterApplied(initialFilters);
	}, []);

	useEffect(() => {
		loadSalesOrderList();
	}, [filterApplied, sortFields, currentPage, currentSize]);

	const loadChannels = async () => {
		setLoading(true);
		try {
			const response = await getChannels();
			setChannels(response.data);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al obtener los canales'),
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadChannels();
	}, []);

	const loadSalesOrderList = useCallback(async () => {
		try {
			setLoading(true);
			const results = await getSalesOrderList(
				currentPage,
				currentSize,
				sortFields,
				convertFiltersToRecord(filterApplied),
				searchValue,
			);
			console.log('searchValue', searchValue);
			console.log('results', results.data.elements);
			setSalesOrderList(results.data.elements);
			setPageTotal(Math.ceil(results.data.total / currentSize));
			setTotalCount(results.data.total);
			setLoading(false);
		} catch (error) {
			console.error('Failed to load data:', error);
			setAlert({ show: true, severity: 'error', message: 'Error al cargar los datos.' });
			setLoading(false);
		}
	}, [currentPage, currentSize, sortFields, filterApplied, searchValue]);

	const handleVerMasClick = (id: string, matchSearchValueWithServiceId?: boolean) => () => {
		let newUrl = routes.salesOrder.edit.replace(':id', id);
		if (matchSearchValueWithServiceId) {
			newUrl = newUrl + `?serviceId=${searchValue}`;
		}
		window.open(newUrl, '_blank');
	};

	const sortableFields = getSortableFields(headerCells);

	const handleExportToExcel = async () => {
		try {
			setLoading(true);
			const title = 'Listado de ordenes de ventas';
			const envelopedTransactionList = (
				await getSalesOrderListToExport(sortFields, convertFiltersToRecord(filterApplied ?? []))
			).data;
			const listTransactions = envelopedTransactionList.elements;
			const optionsToExport = {
				title: title,
				headers: headerCells,
				widthColumns: [30, 20, 15, 20, 20, 20, 20, 15, 15, 20, 20],
				filename: title,
			} as ExportExcelOptions;
			exportToExcel(listTransactions, optionsToExport, transformValue);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al exportar el listado'),
			});
			setLoading(false);
		}
	};

	const handleSearch = () => {
		setCurrentPage(1);
		loadSalesOrderList();
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};

	const handleCloseAlert = () => {
		setAlert(defaultAlertState);
	};

	const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
		setCurrentSize(+event.target.value);
		setCurrentPage(1);
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		loadSalesOrderList();
	}, [filterApplied, sortFields, currentPage, currentSize, loadSalesOrderList]);

	const handleGoNewSalesOrder = () => {
		history.push('/salesorder/new');
	};

	const saleOrderFilters = getSaleOrderFilters(channels);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const { auth } = useSelector((state: RootState) => state);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid
				container
				spacing={1}
				sx={{
					justifyContent: 'center',
				}}
			>
				<Grid container spacing={2} sx={{ maxWidth: '90vw' }}>
					<Grid item xs={12} md={2}>
						<Button variant="contained" onClick={handleGoNewSalesOrder}>
							{' '}
							+ Nueva Orden{' '}
						</Button>
					</Grid>
					<Grid item xs={12} md={4} className={classes.containerButtons} gap={1}>
						<Tooltip title="Buscá por Deal, Cliente o Pasajero Principal, id de sale order o id de servicio">
							<TextField
								label="Buscar"
								variant="outlined"
								size="small"
								value={searchValue}
								onChange={handleChange}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={handleSearch}>
												<SearchIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Tooltip>
					</Grid>
					<Grid item xs={12} md={4}>
						<FilterGrid
							filters={saleOrderFilters}
							preAppliedFilters={filterApplied}
							handleAppliedFilters={handleApplyFilters}
						/>
					</Grid>
					{isOperatorOrHigher(auth.user) && (
						<Grid item xs={12} md={2} spacing={0} paddingLeft={2}>
							<ExportToExcelButton disabled={salesOrderList?.length == 0} handleExportToExcel={handleExportToExcel} />
							<SyncProcessNetsuiteOptionsMenu setLoading={setLoading} onSetAlert={setAlert} />
						</Grid>
					)}
				</Grid>
				<Grid item xs={12} sx={{ m: 5, justifyContent: 'flex-start', ml: 19 }}>
					<Typography variant="subtitle1">
						En pantalla: {salesOrderList?.length} resultados de {totalCount}
					</Typography>
				</Grid>
				<TableContainer component={Paper} sx={{ maxWidth: '90vw' }} className={classes.tableContainer}>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								{headerCells.map((cell, index) => {
									const isSticky = !isSmallScreen
										? index === 0
											? classes.stickyFirst
											: index === 1
											? classes.stickySecond
											: index === 2
											? classes.stickyThird
											: index === 3
											? classes.stickyFour
											: ''
										: '';
									const sortDirection = sortFields.find((x) => x.field === cell.field)
										? sortFields.find((x) => x.field === cell.field)?.order
										: false;

									return (
										<TableCell
											className={`${classes.cellNoWrap} ${isSticky}`}
											key={cell.field}
											align="center"
											sortDirection={sortDirection}
										>
											{cell.sortable ? (
												<TableSortLabel
													active={sortFields.some((x) => x.field === cell.field)}
													direction={sortDirection || 'asc'}
													onClick={createSortHandler(cell.field, sortFields, sortableFields, setSortFields)}
												>
													{cell.headerName}
												</TableSortLabel>
											) : (
												cell.headerName
											)}
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{salesOrderList?.length === 0 ? (
								<TableRow>
									<TableCell colSpan={colSpan}>
										<PageNoResult />
									</TableCell>
								</TableRow>
							) : (
								salesOrderList?.map((salesOrderItem) => (
									<React.Fragment key={salesOrderItem.id}>
										<TableRow
											key={salesOrderItem.id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											onDoubleClick={handleVerMasClick(salesOrderItem.id, salesOrderItem.matchSearchValueWithServiceId)}
											className={classes.hoverRow}
										>
											<TableCell
												align="center"
												className={`${classes.cellNoWrap} ${!isSmallScreen ? classes.stickyFirst : ''} ${
													classes.clickableCell
												}`}
												onClick={handleVerMasClick(salesOrderItem.id, salesOrderItem.matchSearchValueWithServiceId)}
											>
												<Tooltip title="Hacé clic para ver detalles en una nueva pestaña">
													<div>{transformValue('created', salesOrderItem.created, salesOrderItem)} </div>
												</Tooltip>
											</TableCell>
											<TableCell
												align="center"
												className={`${classes.cellNoWrap} ${!isSmallScreen ? classes.stickySecond : ''} ${
													classes.clickableCell
												}`}
												onClick={handleVerMasClick(salesOrderItem.id, salesOrderItem.matchSearchValueWithServiceId)}
											>
												<Tooltip title="Hacé clic para ver detalles en una nueva pestaña">
													<div>{salesOrderItem.createdBy}</div>
												</Tooltip>
											</TableCell>
											<TableCell align="center" className={!isSmallScreen ? classes.stickyThird : ''}>
												<DealOrCaseOpenDetail
													dealId={salesOrderItem.dealId}
													isBitrixDeal={salesOrderItem.isBitrixDeal}
												/>
											</TableCell>
											<TableCell align="center" className={!isSmallScreen ? classes.stickyFour : ''}>
												{transformValue('isBitrixDeal', salesOrderItem.isBitrixDeal, salesOrderItem)}
											</TableCell>
											<TableCell align="center" className={classes.cellNoWrap}>
												{processText(salesOrderItem.customersCompleteName, 30) || '🧐 Cliente NO definido'}
											</TableCell>

											<TableCell align="center">
												<ColoredLinearProgressWithLabel
													value={
														(salesOrderItem.chargedAmount ?? 0) + (salesOrderItem.pendingPayAmount ?? 0) > 0
															? ((salesOrderItem.chargedAmount ?? 0) /
																	((salesOrderItem.chargedAmount ?? 0) + (salesOrderItem.pendingPayAmount ?? 0))) *
															  100
															: 0
													}
												/>
											</TableCell>
											<TableCell align="center">{salesOrderItem.currency}</TableCell>
											<TableCell align="center">
												{transformValue('salesAmount', salesOrderItem.saleAmount, salesOrderItem)}
											</TableCell>
											<TableCell align="center">
												{transformValue('totalCost', salesOrderItem.totalCost, salesOrderItem)}
											</TableCell>
											<TableCell align="center">{transformGrossProfit(salesOrderItem.grossProfit)}</TableCell>
											<TableCell align="center">
												{transformValue('chargedAmount', salesOrderItem.chargedAmount, salesOrderItem)}
											</TableCell>
											<TableCell
												align="center"
												style={balanceStyle(salesOrderItem.pendingPayAmount, salesOrderItem.travelDepartureDate)}
											>
												{transformValue('pendingPayAmount', salesOrderItem.pendingPayAmount, salesOrderItem)}{' '}
											</TableCell>
											<TableCell align="center" className={classes.cellNoWrap}>
												{processText(salesOrderItem.mainPassengerCompleteName, 30) || '🧐 Pax principal NO definido'}
											</TableCell>
											<TableCell align="center" className={classes.cellNoWrap}>
												{processText(salesOrderItem.destinations, 30)}
											</TableCell>
											<TableCell align="center" className={classes.cellNoWrap}>
												{salesOrderItem.travelDepartureDate ? formatDate(salesOrderItem.travelDepartureDate) : '-'}
											</TableCell>
											<TableCell align="center" className={classes.cellNoWrap}>
												{calculateDaysToTravel(salesOrderItem.travelDepartureDate)}
											</TableCell>
											<TableCell align="center" className={classes.cellNoWrap}>
												{salesOrderItem.automaticSyncWithNetsuite === true ? (
													'activo'
												) : (
													<Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
														inactivo
													</Typography>
												)}
											</TableCell>
											<TableCell align="center" className={classes.cellNoWrap}>
												{salesOrderItem.lastSuccessSyncAt ? formatDateToLocal(salesOrderItem.lastSuccessSyncAt) : '-'}
											</TableCell>
											<TableCell align="center" className={classes.cellNoWrap}>
												{salesOrderItem.segmentType}
											</TableCell>
											<TableCell align="center" className={classes.cellNoWrap}>
												{salesOrderItem.channel?.name}
											</TableCell>
											<TableCell align="center" className={classes.cellNoWrap}>
												{salesOrderItem.clientReference}
											</TableCell>
											<TableCell align="center" className={classes.cellNoWrap}>
												{salesOrderItem.servicesTypes?.map((type) => {
													const service = serviceTypeToEmoji[type];
													return (
														<Tooltip title={service ? service.name : type} key={type}>
															<span style={{ marginRight: 4, cursor: 'pointer' }}>
																{service ? service.emoji : type}
															</span>
														</Tooltip>
													);
												})}
											</TableCell>
										</TableRow>
									</React.Fragment>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<Grid item xs={12}>
					<Grid container justifyContent="center" flexDirection="row" padding={2}>
						<Grid item xs={12} md={1} textAlign="center">
							<Select value={currentSize} onChange={handleRowsPerPageChange}>
								{pageSizeOptions.map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={6} marginTop={1}>
							<Pagination count={pageTotal} page={currentPage} onChange={handlePageChange} color="primary" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Snackbar open={alert.show} autoHideDuration={2000} onClose={handleCloseAlert}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
