import { OrderTypeEnum } from 'features/common/types';
import { ISiemprePagoToken } from './components/SiemprePagoForm';
import { nanoidGenerator } from '../common/helpers';

export enum SimplePaymentOrderEnum {
	NAME = 255,
	CARD = 19,
	CARDCODE = 4,
	DOCUMENT = 15,
}

export enum OrderFormFieldsMaxLength {
	PHONE = 25,
	EMAIL = 500,
	NAME = 500,
}

export interface ICoin {
	id: number;
	code: string;
}
export interface BillingDocumentTypeWithName {
	id: number;
	name: string;
}

export interface OrderDataToClient {
	orderId: string;
	orderType: OrderTypeEnum;
	crmTicket: number;
	amount: number;
	coinCode: string;
	feeAllow?: number[];
	wasTransacted: boolean;
	plCountryCode: string;
	expired?: boolean;
	billingDocumentTypeToShow: BillingDocumentTypeWithName[];
}

export interface PaymentState {
	orderDataToClient: OrderDataToClient | null;
	wireTransferCheckResult: IInfimiaWireTransferTransactionItem[] | null;
}

export interface IFilledOrderForm {
	orderType: number;
	crmTicket: number;
	isBitrixDeal: boolean;
	name: string;
	email: string;
	phone: string;
	file?: string;
	fee?: number[];
	coinId: number;
	amount: number;
	plCountryCode: string;
}

export interface IClientTransactionForm {
	orderId: string;
	cardholder: string;
	document: string;
	cardStamp: string;
	cardNumber: string;
	cardExpiration: string;
	cardSecurityCode: string;
	fee: number;
}

export class PaymentOrderForm {
	id: string;
	orderType: number;
	crmTicket: number;
	isBitrixDeal: boolean;
	name: string;
	file: string | null;
	fee: number[] | null;
	coinId: number;
	amount: number;
	plCountryCode: string;
	email?: string;
	phone?: string;

	constructor(filledOrder: IFilledOrderForm) {
		this.orderType = filledOrder.orderType;
		this.crmTicket = filledOrder.crmTicket;
		this.isBitrixDeal = filledOrder.isBitrixDeal;
		this.name = filledOrder.name;
		filledOrder.email !== '' && filledOrder.email ? (this.email = filledOrder.email) : delete this.email;
		filledOrder.phone !== '' && filledOrder.phone ? (this.phone = filledOrder.phone) : delete this.phone;
		this.file = filledOrder.file ? filledOrder.file : null;
		this.fee = filledOrder.fee ? filledOrder.fee : null;
		this.coinId = filledOrder.coinId;
		this.amount = filledOrder.amount;
		this.plCountryCode = filledOrder.plCountryCode;

		this.id = nanoidGenerator();
	}
}

export interface OrderCreatedResult {
	id: string;
	link: string;
	priceConverted?: {
		amount: string;
		coinId: number;
	};
}

export interface IPaymentStep {
	Step: string;
	Created: string;
	Status: string;
	ResponseCode: string;
	ResponseMessage: string;
	Error: string;
	AuthorizationCode: string;
	UniqueId: string;
}

export interface IPaymentResponse {
	TransactionID: number;
	Created: string;
	TransactionStatusId: string;
	Status: string;
	Description: string;
	ApprovalCode: string;
	Steps: IPaymentStep[];
}

export interface IPaymentError {
	ErrorCode: string;
	Created: string;
	Message: string;
	Detail: string;
}

export interface ITransaction {
	transaction?: IPaymentResponse;
	status: ResponseStatusSP;
	errors?: IPaymentError[];
	redirectUrl?: string;
}

export interface ITransactionInGatewaySPResponse {
	transactionInGatewayId: number;
	response: ITransaction;
}
export interface ITransactionInGatewayBancardResponse {
	status: string;
	process_id: string;
	shop_process_id: number;
}
export interface ITransactionInGatewayWompiResponse {
	publicKey: string;
	currency: string;
	integritySignature: string;
	reference: number;
	amountInCents: number;
	customerData: any;
	shop_process_id: number;
}

export interface IPaymentTitularWompi {
	firstName: string;
	lastName: string;
	document: string;
	documentType: string;
	email: string;
	phoneNumber: string;
	phoneNumberPrefix: string;
}
export interface IPaymentTitularSP {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	documentType: number;
	document: string;
	country: string;
	state: string;
	city: string;
	address: string;
}
export interface IPaymentTitularBancard {
	firstName: string;
	lastName: string;
	email?: string;
	phone?: string;
	documentType: number;
	document: string;
}

export interface ISiemprePagoFormData {
	customerInfoSP: IPaymentTitularSP;
	paymentToken: ISiemprePagoToken;
	fee: number;
	orderId: string;
}
export interface IBancardFormData {
	paymentTitularBancard: IPaymentTitularBancard;
	orderId: string;
}

export interface IWompiFormData {
	paymentTitularWompi: IPaymentTitularWompi;
	orderId: string;
}
export interface IPaymentTitularAtc {
	firstName: string;
	lastName: string;
	email: string;
	phone?: string;
	documentType: number;
	document: string;
	countryCode: string;
	city: string;
	address: string;
}

export interface ITransactionInGatewayAtcRequest {
	paymentTitularAtc: IPaymentTitularBancard;
	fee: number;
	orderId: string;
}
export interface IAtcFormField {
	name: string;
	value: string;
}

export interface ICheckOutAtcResponse {
	fieldsToSends: IAtcFormField[];
	reference: string;
}

export interface IPaymentTitularDLocal {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	documentType: number;
	document: string;
	state: string;
	city: string;
	address: string;
	zipCode: string;
}

export interface ITransactionInGatewayDLocalRequest {
	paymentTitularDLocal: IPaymentTitularDLocal;
	orderId: string;
}

export interface ICreatePaymentDLocalResponse {
	redirectUrl: string;
}

export interface ISendClientLinkToPayOrder {
	id: string;
	message: string;
}

export enum LinkToClientMaxLength {
	MESSAGE = 2000,
}

export interface IErrorCodeType {
	id: string;
	message: string;
}

export enum ResponseStatusSP {
	APPROVED = 1,
	PENDING = 2,
	PREAUTHORIZED = 3,
	REJECT = 4,
	ERROR = 99,
}

export enum PaymentTitularFormFieldLength {
	FIRSTNAME = 100,
	LASTNAME = 100,
	COUNTRY = 100,
	CITY = 100,
	STATE = 100,
	ADDRESS = 150,
	ZIPCODE = 20,
	DOCUMENT = 25,
}

export const ErrorCodeSP: IErrorCodeType[] = [
	{ id: 'TK001', message: 'El número de tarjeta ingresado es incorrecto' },
	{ id: 'TK002', message: 'El número de CVV ingresado es incorrecto' },
	{ id: 'TK003', message: 'La fecha de vencimiento de la tarjeta es incorrecta' },
	{
		id: 'TK004',
		message: 'Se envió un identificador de sesión inválido en una solicitud de token',
	},
	{ id: 'TK005', message: 'Se ingresó un email con formato incorrecto' },
	{ id: 'TK006', message: 'El token (de tipo One-Time) ya fue utilizado o está expirado' },
	{
		id: 'PR001',
		message: 'El token proporcionado es inválido, está vencido o no corresponde al comercio',
	},
	{ id: 'PR002', message: 'El número de orden es inválido' },
	{ id: 'PR003', message: 'El monto proporcionado es inválido' },
	{ id: 'PR004', message: 'La moneda es inválida' },
	{
		id: 'PR013',
		message: 'El plan de cuotas seleccionado no es válido para la tarjeta ingresada por el cliente',
	},
	{ id: 'TR001', message: 'Error de comunicación con el servicio del adquirente' },
	{
		id: 'TR002',
		message:
			'La transacción asociada a la compra se encuentra en un estado que no permite la ejecución de la operación actual',
	},
	{ id: 'TR003', message: 'Problemas con la cuenta de comercio en el Adquirente' },
	{ id: 'TR004', message: 'Error el enviar la transacción al Adquirente mediante Proxy' },
	{ id: 'TR005', message: 'Error interno del Adquirente' },
	{ id: 'TR006', message: 'Número de orden duplicada en el Adquirente' },
	{
		id: 'TR007',
		message: 'Error en los datos suministrados del medio de pago.Verifique sus datos e inténtelo nuevamente',
	},
	{
		id: 'TR008',
		message: 'El monto que se intenta confirmar es superior al autorizado previamente',
	},
	{ id: 'TR009', message: 'Error desconocido de Adquirente' },
];

export interface BancardPaymentCheckResponse {
	responseCode: BancardPaymentResponseCodesEnum;
	description: string;
	reserveInfo: {
		reserveCode: string;
		productInfo: string;
		titularFullName: string;
	};
}

export enum BancardPaymentResponseCodesEnum {
	APPROVED = '00',
	TX_INVALIDATED_BUSINESS = '02',
	TX_SPECIAL_CONDITION = '03',
	TARJ_HOLD_CARD = '04',
	DISABLED_CARD = '05',
	TX_SYSTEM_ERROR = '06',
	TX_REJECTION_FOR_SECURITY_CONTROL = '07',
	TX_REJECTED_FALLBACK_TRANSACTION = '08',
	INVALID_TRANSACTION = '12',
	TARJ_MONTO_INVALIDO = '13',
	TARJ_INEXISTENT_CARD = '14',
	INVALID_CARD = '15',
	TX_CANCELED_BY_THE_CUSTOMER = '17',
	TX_TRY_AGAIN = '19',
	TX_SUSPECT_OF_BAD_OPERATION = '22',
	TARJ_EXPIRED_CARD = '33',
	TX_POSSIBLE_FRAUD = '34',
	TX_CALL_PROCESSOR = '35',
	TX_CARD_BLOCKED_BY_THE_ENTITY = '36',
	TX_MONTH_BIRTH_INCORRECT_BLOCKED_CARD = '37',
	TX_WRONG_KEYS = '38',
	TX_NO_CREDIT_CARD_ACCOUNT = '39',
	TX_TRANSACTION_TYPE_NOT_SUPPORTED = '40',
	TARJ_LOST_CARD_HOLD_CARD = '41',
	TX_NO_UNIVERSAL_ACCOUNT_EXISTS = '42',
	TARJ_STOLEN_CARD_HOLD_CARD = '43',
	TX_ACCOUNT_DOES_NOT_EXIST = '45',
	TX_BANK_NOT_RESPONDED_IN_TIME = '46',
	TX_OPERATION_NOT_ACCEPTED_IN_FEES = '49',
	INSUFFICIENT_FUNDS = '51',
	TARJ_EXPIRED_CARD_2 = '54',
	TX_INVALID_KEY = '55',
	TARJ_INAPPROPRIATE_CARD = '57',
	TX_NOT_ENABLED_FOR_THIS_TERMINAL = '58',
	TX_NOT_APPROVED_POSSIBLE_FRAUD = '59',
	TX_CHECK_PROCESSOR_PURCHASE = '60',
	TX_EXCEED_LIMIT_AMOUNT = '61',
	TX_NOT_APPROVED_RESTRICTED_CARD = '62',
	TX_SECURITY_BREACH = '63',
	TX_EXCEED_AMOUNT_OF_OPERATIONS = '65',
	TX_CALL_PROCESSOR_SAFETY_PURCHASE = '66',
	TX_BUSINESS_DISABLED_BY_LACK_OF_PAYMENT = '70',
	TX_EXTERNAL_OPERATION = '71',
	TX_DATE_INVALIDATED = '72',
	TX_SECURITY_CODE_INVALIDATED = '73',
	TX_DISCONNECTED_ISSUER_ONLINE_PROBLEMS = '92',
	TX_DUPLICATED_TRANSACTION_NOT_APPROVED = '94',

	PENDING_CONFIRMATION_PAYMENT = '-1',
}

export interface BancardResponseCode {
	id: BancardPaymentResponseCodesEnum;
	message: string;
}

export const BancardResponseCodes: BancardResponseCode[] = [
	{
		id: BancardPaymentResponseCodesEnum.APPROVED,
		message: 'APROBADA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_SPECIAL_CONDITION,
		message: 'CONSULTE SU EMISOR - CONDICION ESPECIAL',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_INVALIDATED_BUSINESS,
		message: 'NEGOCIO INVALIDO',
	},
	{
		id: BancardPaymentResponseCodesEnum.TARJ_HOLD_CARD,
		message: 'TARJETA RETENIDA',
	},
	{
		id: BancardPaymentResponseCodesEnum.DISABLED_CARD,
		message: 'TARJETA DESHABILITADA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_SYSTEM_ERROR,
		message: 'ERROR DE SISTEMA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_REJECTION_FOR_SECURITY_CONTROL,
		message: 'RECHAZADO POR CONTROL DE SEGURIDAD',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_REJECTED_FALLBACK_TRANSACTION,
		message: 'TRANSACCIÓN FALLBACK RECHAZADA',
	},
	{
		id: BancardPaymentResponseCodesEnum.INVALID_TRANSACTION,
		message: 'TRANSACCIÓN INVALIDA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TARJ_MONTO_INVALIDO,
		message: 'MONTO INVALIDO',
	},
	{
		id: BancardPaymentResponseCodesEnum.TARJ_INEXISTENT_CARD,
		message: 'TARJETA INEXISTENTE',
	},
	{
		id: BancardPaymentResponseCodesEnum.INVALID_CARD,
		message: 'TARJETA INVÁLIDA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_CANCELED_BY_THE_CUSTOMER,
		message: 'CANCELADA POR CLIENTE',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_TRY_AGAIN,
		message: 'INTENTE NUEVAMENTE',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_SUSPECT_OF_BAD_OPERATION,
		message: 'SOSPECHOSO DE MALA OPERACION',
	},
	{
		id: BancardPaymentResponseCodesEnum.TARJ_EXPIRED_CARD,
		message: 'TARJETA EXPIRADA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_POSSIBLE_FRAUD,
		message: 'POSIBLE FRAUDE',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_CALL_PROCESSOR,
		message: 'LLAME AL PROCESADOR',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_CARD_BLOCKED_BY_THE_ENTITY,
		message: 'TARJETA BLOQUEADA POR LA ENTIDAD',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_MONTH_BIRTH_INCORRECT_BLOCKED_CARD,
		message: 'TARJETA BLOQUEADA POR FECHA DE NACIMIENTO INCORRECTA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_WRONG_KEYS,
		message: 'LLAVES INCORRECTAS',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_NO_CREDIT_CARD_ACCOUNT,
		message: 'NO CUENTA DE CRÉDITO',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_TRANSACTION_TYPE_NOT_SUPPORTED,
		message: 'TIPO DE TRANSACCIÓN NO SOPORTADA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TARJ_LOST_CARD_HOLD_CARD,
		message: 'TARJETA PERDIDA O RETENIDA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_NO_UNIVERSAL_ACCOUNT_EXISTS,
		message: 'NO EXISTE CUENTA UNIVERSAL',
	},
	{
		id: BancardPaymentResponseCodesEnum.TARJ_STOLEN_CARD_HOLD_CARD,
		message: 'TARJETA ROBADA O RETENIDA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_ACCOUNT_DOES_NOT_EXIST,
		message: 'NO EXISTE LA CUENTA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_BANK_NOT_RESPONDED_IN_TIME,
		message: 'EL BANCO NO CONTESTÓ A TIEMPO',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_OPERATION_NOT_ACCEPTED_IN_FEES,
		message: 'OPERACION EN CUOTAS NO SOPORTADA',
	},
	{
		id: BancardPaymentResponseCodesEnum.INSUFFICIENT_FUNDS,
		message: 'FONDOS INSUFICIENTES',
	},
	{
		id: BancardPaymentResponseCodesEnum.TARJ_EXPIRED_CARD_2,
		message: 'TARJETA VENCIDA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_INVALID_KEY,
		message: 'CLAVE INVÁLIDA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TARJ_INAPPROPRIATE_CARD,
		message: 'TARJETA INAPROPIADA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_NOT_ENABLED_FOR_THIS_TERMINAL,
		message: 'TRANSACCIÓN NO HABILITADA PARA ESTA TERMINAL',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_NOT_APPROVED_POSSIBLE_FRAUD,
		message: 'TRANSACCIÓN NO NO APROBADA POR POSIBLE FRAUDE',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_CHECK_PROCESSOR_PURCHASE,
		message: 'REVISE COMPRA POR PROCESADOR',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_EXCEED_LIMIT_AMOUNT,
		message: 'TRANSACCIÓN EXCEDE MONTO LÍMITE',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_NOT_APPROVED_RESTRICTED_CARD,
		message: 'TRANSACCIÓN NO APROBADA POR TARJETA RESTRINJIDA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_SECURITY_BREACH,
		message: 'VIOLACIÓN DE SEGURIDAD',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_EXCEED_AMOUNT_OF_OPERATIONS,
		message: 'SE EXCEDIÓ EL LIMITE DE OPERACIONES',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_CALL_PROCESSOR_SAFETY_PURCHASE,
		message: 'LLAME A PROCESADOR PARA TRANSACCIÓN SEGURA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_BUSINESS_DISABLED_BY_LACK_OF_PAYMENT,
		message: 'NEGOCIO DESHABILITADO POR FALTA DE PAGO',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_EXTERNAL_OPERATION,
		message: 'OPERACIÓN EXTERNA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_DATE_INVALIDATED,
		message: 'FECHA INVALIDADA',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_SECURITY_CODE_INVALIDATED,
		message: 'CÓDIGO DE SEGURIDAD INVALIDADO',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_DISCONNECTED_ISSUER_ONLINE_PROBLEMS,
		message: 'PROVEEDOR DESCONECTADO POR PROBLEMAS DE RED',
	},
	{
		id: BancardPaymentResponseCodesEnum.TX_DUPLICATED_TRANSACTION_NOT_APPROVED,
		message: 'TRANSACCION CANCELADA POR SER DUPLICADA',
	},
	{
		id: BancardPaymentResponseCodesEnum.PENDING_CONFIRMATION_PAYMENT,
		message: 'TRANSACCION PENDIENTE DE CONFIRMACIÓN',
	},
];

export interface WompiCheckPaymentResponse {
	paymentStatus: WompiPaymentStatus;
	description: string;
}
export enum WompiPaymentStatus {
	APPROVED = 'APPROVED',
	PENDING = 'PENDING',
	VOIDED = 'VOIDED',
	DECLINED = 'DECLINED',
	ERROR = 'ERROR',
}

export interface DLocalCheckPaymentResponse {
	status: DLocalPaymentStatus;
}
export enum DLocalPaymentStatus {
	PENDING = 'PENDING',
	PAID = 'PAID',
	REJECTED = 'REJECTED',
	CANCELLED = 'CANCELLED',
	EXPIRED = 'EXPIRED',
}

export const PaymentQuotas: number[] = Array.from({ length: 24 }, (_, i) => i + 1);

export enum BankAccountCountry {
	URUGUAY = 'URUGUAY',
	PARAGUAY = 'PARAGUAY',
	COLOMBIA = 'COLOMBIA',
	BOLIVIA = 'BOLIVIA',
	US = 'US',
}

export const BankAccountCountryCode = {
	[BankAccountCountry.URUGUAY]: 'UY',
	[BankAccountCountry.PARAGUAY]: 'PY',
	[BankAccountCountry.COLOMBIA]: 'CO',
	[BankAccountCountry.BOLIVIA]: 'BO',
	[BankAccountCountry.US]: 'US',
};

export interface IBanksByCountry {
	country: BankAccountCountry;
	banks: string[];
}

export interface IWireTransferCheckFilter {
	country: BankAccountCountry;
	currency: string;
	amount: number;
	dateFrom: string;
	bank: string | null;
}

export interface ICurrenciesByCountry {
	country: BankAccountCountry;
	currencies: string[];
}

export interface IInfimiaWireTransferTransactionItem {
	account: string;
	bank: string;
	bankDate: string;
	bankReference: string;
	companyReference: string | null;
	country: BankAccountCountry;
	credit: string;
	currency: string;
	debit: string;
	id: number;
}

export interface IAddReferenceToWireTransferInInfimia {
	id: number;
	dealId: number;
	isBitrixDeal: boolean;
	comment: string;
	country: BankAccountCountry;
	bank: string;
	account: string;
	bankReference: string;
	bankDate: string;
	currency: string;
	amount: number;
}

export enum WireTransferCommentMaxLength {
	MESSAGE = 250,
}

export enum AtcPaymentResponseCodes {
	PENDING = 0,
	APPROVED = 100,
	INVALID_DATA = 102,
	REPEAT_TRANSACTION = 104,
	PARTIAL_PAYMENT = 110,
	GENERAL_ERROR = 150,
	TIMEOUT_ERROR_SERVER = 151,
	TIMEOUT_ERROR_SERVICE = 152,
	APPROVED_BUT_REJECT_IN_CIBER = 200,
	QUESTION_BY_BANK = 201,
	EXPIRED_CARD = 202,
	REJECTED_CARD = 203,
	INSUFFICIENT_FUNDS = 204,
	STOLEN_CARD = 205,
	ISSUING_BANK_NOT_AVAILABLE = 207,
	INACTIVE_CARD = 208,
	CREDIT_LIMIT_REACHED = 210,
	INVALID_CVN = 211,
	INPUT_FILE_NEGATIVE = 221,
	ACCOUNT_FROZEN = 222,
	APPROVED_BUT_REJECT_CVN_IN_CIBER = 230,
	INVALID_ACCOUNT = 231,
	NOT_ACCEPTED_CARD = 232,
	GENERAL_DECLINE = 233,
	PROBLEM_CIBER_ACCOUNT = 234,
	PROCESSOR_FAILURE = 236,
	INVALID_CARD = 240,
	CARDHOLDER_IS_ENROLLED = 475,
	PAYER_AUTHENTICATION_FAILED = 476,
	TRANSACTION_DECLINED = 481,
	APPROVED_BUT_REJECT_BY_CONFIG_IN_CIBER = 520,
}

export interface IAtcCheckPaymentResponse {
	responseCode: AtcPaymentResponseCodes;
	description: string;
}

export interface ICurrency {
	id: number;
	code: string;
}

export interface IBranchOfficeSimple {
	id: number;
	name: string;
}

export interface ICreateOrderCash {
	branchOfficeId: number;
	crmTicket: number;
	isBitrixDeal: boolean;
	coinId: number;
	amount: number;
	observations?: string;
}
export interface IFilledOrderCashForm {
	branchOfficeId: string;
	crmTicket: number;
	coinId: string;
	amount: number;
	observations: string;
}
export interface IFilledOrderFormVN {
	crmTicket: number;
	isBitrixDeal: boolean;
	name: string;
	email: string;
	phone: string;
	file?: string;
	coinId: number;
	amount: number;
	description: string;
}

export interface OrderCreatedResultVN {
	id: string;
	link: string;
	priceConverted: {
		amount: number;
		coinId: number;
	};
}

export class PaymentOrderFormVN {
	id: string;
	crmTicket: number;
	isBitrixDeal: boolean;
	name: string;
	file: string | null;
	coinId: number;
	amount: number;
	email?: string;
	phone?: string;
	description: string;
	constructor(filledOrderVN: IFilledOrderFormVN) {
		this.crmTicket = filledOrderVN.crmTicket;
		this.isBitrixDeal = filledOrderVN.isBitrixDeal;
		this.name = filledOrderVN.name;
		filledOrderVN.email !== '' && filledOrderVN.email ? (this.email = filledOrderVN.email) : delete this.email;
		filledOrderVN.phone !== '' && filledOrderVN.phone ? (this.phone = filledOrderVN.phone) : delete this.phone;
		this.file = filledOrderVN.file ? filledOrderVN.file : null;
		this.coinId = filledOrderVN.coinId;
		this.amount = filledOrderVN.amount;
		this.description = filledOrderVN.description;

		this.id = nanoidGenerator();
	}
}

export enum OrderFormFieldsMaxLengthVN {
	PHONE = 25,
	EMAIL = 255,
	NAME = 500,
}
