import { AlertColor } from '@mui/material';
import {
	AtcPaymentResponseCodes,
	BancardPaymentResponseCodesEnum,
	BancardResponseCodes,
	DLocalPaymentStatus,
	ErrorCodeSP,
	IAtcCheckPaymentResponse,
	IPaymentError,
	ITransactionInGatewaySPResponse,
	ResponseStatusSP,
	WompiCheckPaymentResponse,
	WompiPaymentStatus,
} from './types';

export function getSiemprePagoErrorMessage(errors: IPaymentError[]): string {
	const maybeError = ErrorCodeSP.find((element) => element.id === errors[0].ErrorCode);
	return maybeError ? maybeError.message : errors[0].Message;
}

export function getBancardErrorMessage(errorCode: BancardPaymentResponseCodesEnum): string {
	const maybeError = BancardResponseCodes.find((element) => element.id === errorCode);
	return maybeError ? maybeError.message : '';
}

export const manageSPTransactionResponse = (
	response: ITransactionInGatewaySPResponse,
): [alertType: AlertColor, alertMessage: string] => {
	switch (response.response.status) {
		case ResponseStatusSP.PENDING:
			return ['warning', 'La transacción está pendiente'];
		case ResponseStatusSP.PREAUTHORIZED:
			return ['warning', 'La transacción está en proceso de autorización'];
		case ResponseStatusSP.ERROR:
			if (Array.isArray(response.response.errors) && response.response.errors?.length > 0) {
				return ['error', getSiemprePagoErrorMessage(response.response.errors)];
			}
			return ['error', 'Ocurrió un error al procesar la transacción'];
		case ResponseStatusSP.APPROVED:
			return ['success', 'La transacción fue aprobada con exito'];
		default:
			return ['error', 'Se obtuvo un error desconocido'];
	}
};
export const manageBancardResponseCode = (
	responseCode: BancardPaymentResponseCodesEnum,
): [alertType: AlertColor, alertMessage: string] => {
	switch (responseCode) {
		case BancardPaymentResponseCodesEnum.APPROVED:
			return ['success', 'El pago fue aprobado'];
		case BancardPaymentResponseCodesEnum.PENDING_CONFIRMATION_PAYMENT:
			return ['warning', 'El pago está en proceso de confirmación'];
		default:
			return ['error', ` ${getBancardErrorMessage(responseCode)}`];
	}
};

export const manageAtcResponse = (
	response: IAtcCheckPaymentResponse,
): [alertType: AlertColor, alertMessage: string] => {
	switch (response.responseCode) {
		case AtcPaymentResponseCodes.APPROVED:
			return ['success', response.description];
		case AtcPaymentResponseCodes.PENDING:
			return ['warning', 'El pago está en proceso de confirmación'];
		default:
			return ['error', response.description];
	}
};

export const manageWompiResponse = (
	response: WompiCheckPaymentResponse,
): [alertType: AlertColor, alertMessage: string] => {
	switch (response.paymentStatus) {
		case WompiPaymentStatus.APPROVED:
			return ['success', 'El pago fue aprobado'];
		case WompiPaymentStatus.PENDING:
			return ['warning', 'El pago está en proceso de confirmación'];
		default:
			return ['error', response.description];
	}
};

export const manageDLocalResponseCode = (
	responseCode: DLocalPaymentStatus,
): [alertType: AlertColor, alertMessage: string] => {
	switch (responseCode) {
		case DLocalPaymentStatus.PAID:
			return ['success', 'El pago fue aprobado'];
		case DLocalPaymentStatus.PENDING:
			return ['warning', 'El pago está en proceso de confirmación'];
		case DLocalPaymentStatus.REJECTED:
			return ['error', 'El pago fue rechazado'];
		case DLocalPaymentStatus.EXPIRED:
			return [
				'error',
				'El tiempo para realizar el pago ha expirado, por favor solicite un nuevo link de pago al vendedor.',
			];
		case DLocalPaymentStatus.CANCELLED:
			return ['warning', 'El pago fue cancelado por el usuario. Pago no procesado.'];
		default:
			return ['error', 'Error desconocido'];
	}
};
