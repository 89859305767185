import { CountryCodeEnum } from '../common/types';

export const getLabelNameForProvince = (countryCode: string): string => {
	switch (countryCode) {
		case CountryCodeEnum.PARAGUAY:
			return 'Distrito';
		case CountryCodeEnum.BOLIVIA:
			return 'Provincia';
		case CountryCodeEnum.URUGUAY:
		case CountryCodeEnum.COLOMBIA:
			return 'Ciudad';
		default:
			return 'Provincia';
	}
};

export const getLabelNameForMunicipality = (countryCode: string): string => {
	switch (countryCode) {
		case CountryCodeEnum.PARAGUAY:
			return 'Ciudad';
		case CountryCodeEnum.BOLIVIA:
			return 'Comuna';
		default:
			return 'Municipio';
	}
};
