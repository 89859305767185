import {
	AuthorizationStateEnum,
	authorizationStateList,
	bancardPaymentState,
	BancardPaymentStateEnum,
	GatewayDocumentTypeEnum,
	gatewayDistinctToDLocalDocumentTypeList,
	GatewayStatusEnum,
	gatewayStatusList,
	itauPointsPaymentState,
	ItauPointsPaymentStateEnum,
	lineTypeVN,
	PersonTypeEnumVN,
	personTypeVN,
	VNTransactionLineTypeEnum,
} from './types';

export function getAuthorizationStateName(state: AuthorizationStateEnum): string {
	const maybeElement = authorizationStateList.find((element) => element.id === state);
	return maybeElement ? maybeElement.name : '';
}
export function getTNGatewayDocumentTypeName(state: GatewayDocumentTypeEnum): string {
	const maybeElement = gatewayDistinctToDLocalDocumentTypeList.find((element) => element.id === state);
	return maybeElement ? maybeElement.name : '';
}
export function getStatusNameTransactionInGateway(state: GatewayStatusEnum): string {
	const maybeElement = gatewayStatusList.find((element) => element.id === state);
	return maybeElement ? maybeElement.name : '';
}

export function getTransactionLineTypeVN(transactionLineType: VNTransactionLineTypeEnum): string {
	const maybeElement = lineTypeVN.find((element) => element.id === transactionLineType);
	return maybeElement ? maybeElement.name : '';
}

export function getPersonTypeVN(personType: PersonTypeEnumVN): string {
	const maybeElement = personTypeVN.find((element) => element.id === personType);
	return maybeElement ? maybeElement.name : '';
}

export function getBancardPaymentStateVN(bancardPayment: BancardPaymentStateEnum): string {
	const maybeElement = bancardPaymentState.find((element) => element.id === bancardPayment);
	return maybeElement ? maybeElement.name : '';
}

export function getItauPaymentStateVN(itauPayment: ItauPointsPaymentStateEnum): string {
	const maybeElement = itauPointsPaymentState.find((element) => element.id === itauPayment);
	return maybeElement ? maybeElement.name : '';
}
