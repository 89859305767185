import React, { useState, useCallback, useRef, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useFormContext, Controller } from 'react-hook-form';
import { getProviderAutocomplete, getProviderId } from 'features/salesOrder/services';

interface Props {
	name: string;
	rules?: Record<string, any>;
	allowNotApplicable?: boolean;
}

const notApplicable = {
	id: 'n/a',
	fantasyName: 'Desconocido',
	businessName: 'No Aplica',
	stateName: 'No Aplica',
};

export const AutocompleteProviders = ({ name, rules, allowNotApplicable }: Props): JSX.Element => {
	const {
		control,
		watch,
		formState: { errors },
	} = useFormContext();
	const [initialValue, setInitialValue] = useState<{
		id: string;
		fantasyName: string;
		businessName: string;
		stateName: string;
	}>(notApplicable);
	// @ts-ignore
	const formValue = watch(name) as string;
	const [inputValue, setInputValue] = useState(allowNotApplicable === true ? notApplicable.id : '');
	const [options, setOptions] = useState<any[]>(
		allowNotApplicable === true ? [{ ...initialValue, ...notApplicable }] : [{ ...initialValue }],
	);

	const autocompleteDebouncer = useRef<number | null>(null);

	const fetchOptions = useCallback(
		async (valueToSearch): Promise<{ id: number; fantasyName: string; businessName: string; stateName: string }[]> => {
			try {
				const response = await getProviderAutocomplete(valueToSearch);
				const selectOption = response.data.map((option) => ({
					id: option.id,
					fantasyName: option.fantasyName,
					businessName: option.businessName,
					stateName: option.stateName,
				}));
				return selectOption;
			} catch (error) {
				// @ts-ignore
				return allowNotApplicable === true ? [notApplicable] : [];
			}
		},
		[options],
	);

	useEffect(() => {
		if (formValue) {
			const fetchProviderById = async (providerId: string) => {
				try {
					const result = await getProviderId(providerId);

					if (result.data) {
						const { id, fantasyName, businessName, stateName } = result.data;
						const newInitialValue = {
							id,
							fantasyName,
							businessName,
							stateName,
						};
						// @ts-ignore
						setInitialValue(newInitialValue);
					}
				} catch (_e) {
					console.log('Error in get Provider', _e);
				}
			};
			if (formValue !== 'n/a') {
				fetchProviderById(formValue);
			} else {
				setInitialValue({ ...notApplicable });
			}
		}
	}, [formValue]);

	const inputChangeHandler = (value: string) => {
		setInputValue(value);
		if (autocompleteDebouncer.current !== null) {
			clearTimeout(autocompleteDebouncer.current);
		}
		if (value.length >= 4) {
			autocompleteDebouncer.current = setTimeout(async () => {
				try {
					fetchOptions(inputValue).then((results) => {
						if (allowNotApplicable === true) {
							setOptions([{ ...notApplicable }, ...results]);
						} else {
							setOptions(results);
						}
					});
				} catch (error) {
					return allowNotApplicable === true ? [{ ...notApplicable }] : [];
				}
				300;
			});
		} else {
			setOptions(allowNotApplicable === true ? [{ ...notApplicable }] : []);
		}
	};

	return (
		<Controller
			// @ts-ignore
			name={name}
			control={control}
			rules={rules}
			render={({ field }) => (
				<FormControl sx={{ width: '100%' }}>
					<Autocomplete
						{...field}
						options={options}
						getOptionLabel={(option) =>
							typeof option === 'string' ? option : `${option.fantasyName} (${option.businessName}) `
						}
						autoComplete
						includeInputInList
						filterSelectedOptions
						value={
							initialValue
								? `${initialValue.fantasyName} (${initialValue.businessName})`
								: allowNotApplicable
								? `${notApplicable.fantasyName} (${notApplicable.businessName})`
								: ''
						}
						onChange={(_, newValue) => {
							const selectedOption = options.find((opt) => (newValue ? opt.id === newValue.id : false));

							field.onChange(selectedOption ? selectedOption.id : '');
							if (newValue && newValue.fantasyName) {
								inputChangeHandler(`${newValue.fantasyName} (${newValue.businessName})`);
							}
						}}
						onInputChange={(_, newValue) => {
							inputChangeHandler(newValue);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								error={Boolean(errors[name])}
								variant="outlined"
								value={inputValue}
								fullWidth
								size="small"
								InputLabelProps={{ shrink: true }}
								helperText="Este campo es requerido"
								style={{ backgroundColor: 'white' }}
							/>
						)}
					/>
				</FormControl>
			)}
		></Controller>
	);
};
