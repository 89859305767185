import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';

import { checkShowErrorMessage } from 'features/common/helpers';
import {
	typeUnit,
	ICodeName,
	IServiceCostVariationLogs,
	TypeServiceEnum,
	IFeeService,
} from 'features/salesOrder/types';
import { ControlledSelect } from 'features/common/components/ControlledSelect';
import { ControlledAutoComplete } from 'features/common/components/ControlledAutoComplete';
import { ControlledTextField } from 'features/common/components/ControlledTextField';
import {
	excludeTypeUnits,
	filterServiceCostVariationLogs,
	calcularPrecioFinal,
	calculatMarkupPercentaje,
} from 'features/salesOrder/components/SalesOrderServiceForm/helpers';
import { AutocompleteProviders } from 'features/common/components/AutocompleteProviders';
import { ServiceCostVariationLogs } from 'features/salesOrder/components/SalesOrderServiceForm/components/ServiceCostVariationLogs';
import { getCostVariationLogsByService, putServiceForm } from 'features/salesOrder/services';
import { useParams } from 'react-router-dom';
import { ShowAlertState } from 'features/common/types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getRates } from 'features/stats/services';

import { config } from './config';

interface Props {
	index: number;
	passengers: { keyId: string; name: string; lastName: string }[];
	service: IFeeService;
	onUpdateService: () => void;
	currencyesList: ICodeName[];
	onSetAlert: (values: ShowAlertState) => void;
	onSetLoading: (value: boolean) => void;
	isExistingService?: boolean;
}
const iconMapping = {
	Persona: <PersonIcon />,
	Grupo: <GroupIcon />,
};

export const FeeService = ({
	index,
	passengers,
	service,
	currencyesList,
	onUpdateService,
	onSetAlert,
	onSetLoading,
	isExistingService,
}: Props): JSX.Element => {
	const methods = useForm();
	const {
		formState: { errors },
		setValue,
		getValues,
		watch,
	} = methods;

	const idService = service.keyId;

	const { id: saleOrderId } = useParams<{ id: string }>();
	const [serviceCostVariationLogs, setServiceCostVariationLogs] = useState<IServiceCostVariationLogs[]>([]);
	const filteredArray = filterServiceCostVariationLogs(serviceCostVariationLogs, idService);

	const { auth } = useSelector((state: RootState) => state);
	const theCountry = auth.country;

	const loadCostLog = useCallback(async () => {
		const result = await getCostVariationLogsByService(saleOrderId, idService);
		setServiceCostVariationLogs(result.data);
	}, [setServiceCostVariationLogs]);

	useEffect(() => {
		loadCostLog();
	}, [loadCostLog, service]);

	const handleCostChange = (newCost: number) => {
		// @ts-ignore
		setValue('cost', newCost, { shouldDirty: true });
		const saleAmount = getValues('saleAmount' as `${string}.${string | number}`) || 0;
		const newMarkup = calculatMarkupPercentaje(newCost, saleAmount) as string;
		// @ts-ignore
		setValue('markupPercentage', newMarkup, { shouldDirty: true });
		calculateTotalCost();
	};

	const handleSaleAmountChange = (newValue: number) => {
		if (theCountry === 'CO') {
			const rateValue = getValues('currencyRate' as `${string}.${string | number}`) || 1;
			const valorLocalCurrency = newValue * rateValue;
			setValue('saleAmountInLocalCurrency', valorLocalCurrency, { shouldDirty: true });
		}
		// @ts-ignore
		setValue('saleAmount', newValue, { shouldDirty: true });
		const cost = getValues('cost' as `${string}.${string | number}`) || 0;
		if (newValue === 0) {
			setValue('markupPercentage', 0);
		} else {
			const newMarkup = calculatMarkupPercentaje(cost, newValue) as string;

			// @ts-ignore
			setValue('markupPercentage', newMarkup, { shouldDirty: true });
		}

		calculateTotalCost();
	};

	const handleSaleAmountChangeLocalCurrency = (valorEnCop: number) => {
		setValue('saleAmountInLocalCurrency', valorEnCop, { shouldDirty: true });
		const rateValue = getValues('currencyRate' as `${string}.${string | number}`) || 1;
		const valorEnDolares = valorEnCop / rateValue;
		// @ts-ignore
		setValue('saleAmount', valorEnDolares, { shouldDirty: true });
		const cost = getValues('cost' as `${string}.${string | number}`) || 0;
		if (valorEnDolares === 0) {
			setValue('markupPercentage', 0);
		} else {
			const newMarkup = calculatMarkupPercentaje(cost, valorEnDolares) as string;

			// @ts-ignore
			setValue('markupPercentage', newMarkup, { shouldDirty: true });
		}

		calculateTotalCost();
	};

	const handleMarkupPercentageChange = (newValue: number) => {
		// @ts-ignore
		setValue('markupPercentage', newValue, { shouldDirty: true });
		const cost = getValues('cost' as `${string}.${string | number}`) || 0;
		const newSaleAmount = calcularPrecioFinal(cost, newValue) as string;
		if (theCountry === 'CO') {
			const rateValue = getValues('currencyRate' as `${string}.${string | number}`) || 1;
			const valorLocalCurrency = Number(newSaleAmount) * rateValue;
			setValue('saleAmountInLocalCurrency', valorLocalCurrency, { shouldDirty: true });
		}
		// @ts-ignore
		setValue('saleAmount', newSaleAmount, { shouldDirty: true });
		calculateTotalCost();
	};

	const passengersSelected = watch('passengerIds' as `${string}.${string | number}`) || [];
	const unit = watch('unit' as `${string}.${string | number}`);
	const watchFeeServiceType = watch('feeServiceType' as `${string}.${string | number}`);

	const calculateTotalCost = useCallback(() => {
		let cost = getValues('cost' as `${string}.${string | number}`) || 0;
		let saleAmount = getValues('saleAmount' as `${string}.${string | number}`) || 0;
		let saleAmountInLocalCurrency = getValues('saleAmountInLocalCurrency' as `${string}.${string | number}`) || 0;

		if (unit === 'Person') {
			cost = cost * passengersSelected.length;
			saleAmount =
				saleAmount * passengersSelected.length || (saleAmount ? saleAmount * passengersSelected.length : null);
			saleAmountInLocalCurrency =
				saleAmountInLocalCurrency * passengersSelected.length ||
				(saleAmountInLocalCurrency ? saleAmountInLocalCurrency * passengersSelected.length : null);
		}

		setValue('totalCost' as `${string}.${string | number}`, cost);
		setValue('totalSaleAmount' as `${string}.${string | number}`, saleAmount);
		if (theCountry === 'CO') {
			const totalEnColombianos = saleAmountInLocalCurrency;
			setValue('totalSaleAmountInLocalCurrency', Number(totalEnColombianos.toFixed(2)));
		}
	}, [getValues, passengersSelected, setValue, unit, auth]);

	const handleUpdateService = async (data: IFeeService) => {
		try {
			// @ts-ignore
			if (data.tariffProviderId === 'n/a') {
				onSetAlert({
					show: true,
					severity: 'warning',
					message: 'Elije un proveedor de Tarifa',
				});
				// @ts-ignore
				delete data.tariffProviderId;
			}
			if (data.totalSaleAmount === null) {
				// @ts-ignore
				data.totalSaleAmount = 0;
			}
			if (theCountry !== 'CO') {
				// @ts-ignore
				delete data.currencyRate;
				// @ts-ignore
				delete data.totalSaleAmountInLocalCurrency;
			}
			data.serviceType = TypeServiceEnum.Fee;
			await putServiceForm({ saleOrderId, serviceId: data.keyId, data });
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Fee guardado con exito!',
			});
			onUpdateService();
		} catch (e) {
			// @ts-ignore
			alert(e.response.data.message);
		} finally {
			onSetLoading(false);
		}
	};

	useEffect(() => {
		const typeOptions = config[auth.country]?.typeOptions || [];
		const myTypeOption = typeOptions.find((option) => option.value === watchFeeServiceType);

		setValue(
			'feeServiceType' as `${string}.${string | number}`,
			service.feeServiceType || myTypeOption?.defaultValues?.feeServiceType,
		);
		let effectTariffProviderId = service.tariffProvider;
		if (Boolean(service.tariffProvider) === false && Boolean(myTypeOption?.defaultValues?.providerId)) {
			// @ts-ignore
			effectTariffProviderId = myTypeOption?.defaultValues.providerId;
		} else if (service.tariffProvider && service.tariffProvider.id) {
			// @ts-ignore
			effectTariffProviderId = service.tariffProvider.id;
		} else {
			// @ts-ignore
			effectTariffProviderId = 'n/a';
		}
		setValue('tariffProviderId' as `${string}.${string | number}`, effectTariffProviderId);
		const serviceUnit = service.unit.length === 0 ? myTypeOption?.defaultValues?.serviceUnit : service.unit;
		setValue('unit' as `${string}.${string | number}`, serviceUnit, { shouldDirty: true });
		setValue('cost' as `${string}.${string | number}`, service.cost || myTypeOption?.defaultValues?.cost);
		setValue('keyId' as `${string}.${string | number}`, service.keyId);
		setValue('saleAmount' as `${string}.${string | number}`, service.saleAmount || myTypeOption?.defaultValues?.sale);
		setValue('currency' as `${string}.${string | number}`, service.currency || 'USD');
		setValue(
			'markupPercentage' as `${string}.${string | number}`,
			service.markupPercentage || myTypeOption?.defaultValues?.markup,
		);
		if (service.passengerIds && service.passengerIds.length > 0) {
			setValue('passengerIds' as `${string}.${string | number}`, service.passengerIds);
		} else if (myTypeOption?.defaultValues?.setAllPax === true) {
			const allPassengerIds = passengers.map((p) => p.keyId);
			setValue('passengerIds', allPassengerIds);
		} else {
			setValue('passengerIds' as `${string}.${string | number}`, '');
		}
		service.cost && handleCostChange(Number(service.cost || 0));
		service.saleAmount && handleSaleAmountChange(Number(service.saleAmount || 0));
		if (auth.country === 'CO') {
			if (!isExistingService) {
				const fetchRates = async () => {
					const result = await getRates();
					setValue('currencyRate', result.data.ratesList[0].values[0].fee);
				};
				fetchRates();
			} else {
				setValue('currencyRate', service.currencyRate || 1);
				setValue(
					'saleAmountInLocalCurrency' as `${string}.${string | number}`,
					service.saleAmountInLocalCurrency || myTypeOption?.defaultValues?.sale,
				);
			}
			setValue('totalSaleAmountInLocalCurrency', service.totalSaleAmountInLocalCurrency || 1);
		}
	}, [setValue, index, service, isExistingService, auth, watchFeeServiceType]);

	useEffect(() => {
		setValue('currency' as `${string}.${string | number}`, 'USD');
	}, [setValue]);

	calculateTotalCost();

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit((data) => {
					handleUpdateService(data as IFeeService);
				})}
			>
				<div>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Typography color={'Grey'}>Tipo</Typography>
								<Tooltip
									title={
										<React.Fragment>
											<Typography variant="subtitle2" color="inherit">
												Tipos de Fee
											</Typography>
											<Typography variant="body2" fontSize={12} color="inherit">
												Al asignar un tipo de Fee, se prepopulan valores por defecto como por ejemplo el proveedor, los
												pasajeros, unidad, costo y venta
											</Typography>
										</React.Fragment>
									}
									arrow
								>
									<IconButton sx={{ padding: 0, paddingLeft: 1 }}>
										<HelpIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							</div>
							<ControlledSelect
								name="feeServiceType"
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={config[auth.country].typeOptions.map((item) => ({ id: item.value, name: item.name }))}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color={'Grey'}>Proveedor de tarifas</Typography>
							<AutocompleteProviders
								name={'tariffProviderId'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'} mb={1}>
								Pasajeros
							</Typography>
							<ControlledAutoComplete
								name={'passengerIds'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={passengers}
								isMultiple={true}
								helperText={checkShowErrorMessage(Boolean(errors.passengerIds), errors.passengerIds?.message)}
							/>
						</Grid>

						<Grid item xs={12} sm={2}>
							<Typography color={'Grey'}>Unidad por</Typography>
							<ControlledSelect
								name={'unit'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={excludeTypeUnits(typeUnit, ['Habitacion', 'Vehiculo', 'Camarote'])}
								iconTypeTrip={iconMapping}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Typography color={'Grey'}>Moneda</Typography>
							<ControlledSelect
								name={'currency'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={currencyesList.map((item) => ({ id: item.code.toString(), name: item.code }))}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Typography color={'Grey'}>Costo</Typography>
							<ControlledTextField
								name={'cost'}
								isNumber
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								onChange={(value) => handleCostChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.cost), errors.cost?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Typography color={'Grey'}>Markup %</Typography>
							<ControlledTextField
								name={'markupPercentage'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								isNumber
								onChange={(value) => handleMarkupPercentageChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.markupPercentage), errors.markupPercentage?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Typography color={'Grey'}>Venta</Typography>
							<ControlledTextField
								isNumber
								name={'saleAmount'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								onChange={(value) => handleSaleAmountChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.saleAmount), errors.saleAmount?.message)}
							/>
						</Grid>

						{theCountry === 'CO' && (
							<>
								<Grid item xs={12} sm={3} />
								<Grid item xs={12} sm={3} />
								<Grid item xs={12} sm={3} />
								<Grid item xs={6} sm={3}>
									<Typography color={'Grey'}>Venta COP</Typography>
									<ControlledTextField
										isNumber
										name={'saleAmountInLocalCurrency'}
										onChange={(value) => handleSaleAmountChangeLocalCurrency(parseFloat(value))}
										helperText={checkShowErrorMessage(Boolean(errors.saleAmount), errors.saleAmount?.message)}
									/>
								</Grid>
							</>
						)}
						<Grid item xs={12} sm={4} />
						<Grid item xs={6} sm={4}>
							<Typography color={'Grey'}>Costo Total</Typography>
							<ControlledTextField name={'totalCost'} disabled />
						</Grid>
						<Grid item xs={6} sm={4}>
							<Typography color={'Grey'}>Venta Total</Typography>
							<ControlledTextField name={'totalSaleAmount'} disabled />
						</Grid>
						{theCountry === 'CO' && (
							<>
								<Grid item xs={12} sm={4} />
								<Grid item xs={12} sm={4}>
									<Typography color={'Grey'}>Tipo de Cambio</Typography>
									<ControlledTextField
										name={'currencyRate'}
										disabled={isExistingService}
										onChange={calculateTotalCost}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography color={'Grey'}>Total en COP</Typography>
									<ControlledTextField name={'totalSaleAmountInLocalCurrency'} disabled />
								</Grid>
							</>
						)}
						<ServiceCostVariationLogs filteredArray={filteredArray} />
					</Grid>
				</div>
				{(service.enable || service.enable === undefined) && (
					<Box display="flex" flexDirection="row-reverse" mt={2}>
						<Button type="submit" variant="contained" color="success">
							Guardar
						</Button>
					</Box>
				)}
			</form>
		</FormProvider>
	);
};
