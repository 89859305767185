import React from 'react';
import { Layout } from 'features/common/components/Layout';
import { Card, CardContent, CardMedia, Typography, Grid, Box, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

// Reemplazamos los "example" que eran string por React.ReactNode (JSX).
// De esa forma, podemos meter <strong>, <span style={{ color: 'red' }}> etc.
const cardData = [
	{
		id: 1,
		icon: '🛠️',
		title: 'Devoluciones por Errores Internos',
		example: (
			<>
				Las devoluciones por errores internos ocurren cuando un colaborador de Mevuelo omite o se equivoca en la emisión
				de un servicio al pasajero.
				<strong style={{ color: 'orangered' }}>
					{' '}
					Estos casos, al ser completamente ajenos a la voluntad del cliente, deben ser solucionados con la mayor
					prioridad posible.
				</strong>{' '}
				Para esto, se realizará de manera expedita la transferencia o reembolso al cliente, independientemente de los
				posteriores análisis o correcciones por parte del equipo de operaciones, ventas o administración.
			</>
		),
		needs: [
			'Deal/Caso',
			'Comprobante de pago del cliente',
			'Monto pago por el cliente',
			'Cuenta bancaria para realizar el reembolso',
			<>
				Demora aprox: <strong>72 hrs</strong>
			</>,
		],
		link: 'https://crm.nimbux.tech/support/admin-devoluciones-errores-internos',
		isExternal: true,
		allowedCountries: ['UY', 'PY', 'BO', 'CO', 'TN'],
	},
	{
		id: 2,
		icon: '🔄',
		title: 'Devoluciones por Errores de Terceros',
		example: (
			<>
				Las devoluciones por errores de terceros se procesan cuando el motivo que la origina es responsabilidad del
				proveedor o prestadores.
				<strong style={{ color: 'orangered' }}>
					{' '}
					Mevuelo debe dar una ágil solución al cliente, manteniéndolo informado de las distintas etapas del proceso y
					fechas de procesamiento.
				</strong>{' '}
				Se realiza la devolución al cliente en el plazo de <strong>72 horas</strong>, mientras que el proceso completo
				dura aproximadamente <strong>30 días</strong> ya que se debe gestionar y esperar solución por parte del
				proveedor.
			</>
		),
		needs: [
			'Deal/Caso',
			'Comprobante de pago del cliente',
			'Monto pago por el cliente',
			'Cuenta bancaria para realizar el reembolso',
			<>
				Demora aprox:<strong> 30 días</strong>
			</>,
		],
		link: 'https://crm.nimbux.tech/support/admin-devoluciones-errores-terceros',
		isExternal: true,
		allowedCountries: ['UY', 'PY', 'BO', 'CO', 'TN'],
	},
	{
		id: 3,
		icon: '✈️',
		title: 'Devoluciones de Proveedores Aéreos',
		example: (
			<>
				Las devoluciones de vuelos se procesan generalmente a solicitud del cliente y se rigen por las políticas de
				devolución de las compañías aéreas.
				<strong style={{ color: 'orangered' }}>
					{' '}
					Como regla general no se realiza devolución al cliente hasta que Mevuelo no reciba la nota de crédito o
					reembolso por parte del proveedor.
				</strong>{' '}
				Una vez recibida la devolución e informado por parte de administración, el vendedor debe confirmar el monto a
				devolver al cliente, reteniendo al menos el 15% por concepto de gastos administrativos.
			</>
		),
		needs: [
			'Deal/Caso',
			'El/los números de tickets a solicitar devolución',
			'Cuenta bancaria para realizar el reembolso',
			<>
				Demora aprox:<strong> 45 días</strong>
			</>,
		],
		link: 'https://crm.nimbux.tech/support/admin-devoluciones-aereos',
		isExternal: true,
		allowedCountries: ['UY', 'PY', 'BO', 'CO', 'TN'],
	},
	{
		id: 4,
		icon: '🏨',
		title: 'Devoluciones de Alojamiento',
		example: (
			<>
				Las devoluciones de alojamiento generalmente son solicitadas por parte del cliente, ya sea por cancelación o
				cambio de su itinerario.
				<strong style={{ color: 'orangered' }}>
					{' '}
					Para estos casos, aplica una política de pagos hacia el mismo que depende del plazo de devolución del
					proveedor.
				</strong>
			</>
		),
		needs: [
			'Deal/Caso',
			'Localizador de la reserva',
			'Cuenta bancaria para realizar el reembolso',
			<>
				Demora aprox:<strong> 30 días</strong>
			</>,
		],
		link: 'https://crm.nimbux.tech/support/admin-devoluciones-alojamiento',
		isExternal: true,
		allowedCountries: ['UY', 'PY', 'BO', 'CO', 'TN'],
	},
	{
		id: 5,
		icon: '📋',
		title: 'Devoluciones de Otros Servicios',
		example: (
			<>
				Las devoluciones de este tipo se refieren a servicios que no son ni aéreos ni de alojamiento y se solicitan
				cuando el cliente pide cancelar, sea por un cambio parcial o total del viaje.
				<br />
				<strong style={{ color: 'orangered' }}>
					En estos casos, aplica una política de pagos que depende del plazo de devolución del proveedor.
				</strong>
			</>
		),
		needs: [
			'Deal/Caso',
			'Localizador de la reserva',
			'Cuenta bancaria para realizar el reembolso',
			<>
				Demora aprox:<strong> 30 días</strong>
			</>,
		],
		link: 'https://crm.nimbux.tech/support/admin-devoluciones-otros-servicios',
		isExternal: true,
		allowedCountries: ['UY', 'PY', 'BO', 'CO', 'TN'],
	},
];

const Refund = (): JSX.Element => {
	const { auth } = useSelector((state: RootState) => state);

	// Filtramos según allowedCountries
	const filteredCards = cardData.filter((card) => card.allowedCountries.includes(auth.country));

	return (
		<Layout>
			<>
				<Box sx={{ flexGrow: 1, padding: 2, margin: '0 15%' }}>
					<RouterLink to="/home" style={{ textDecoration: 'none' }}>
						<Typography mb={2}>Click para volver al HOME</Typography>
					</RouterLink>
					<Typography variant="h6">Devoluciones</Typography>
					<Typography variant="subtitle2">
						Hola!👋 antes de avanzar con la solicitud de devolución para el cliente, recordá verificar cada una de las
						opciones disponibles, es muy importante comenzar el proceso correcto para evitar demoras.
						<br />
						Las devoluciones son un proceso crítico 🚨 dentro de la empresa, especialmente por el impacto que pueden
						tener en nuestros clientes, además de su procesamiento contable o financiero. Es por esto que hemos agrupado
						las mismas según su naturaleza y casos más frecuentes, priorizando la comunicación con el cliente y con los
						proveedores.
						<br />
						Lee detenidamente el manual en caso de duda antes de procesar las distintas acciones. 📑
						<br />
						Puedes encontrar más información en 🎓
						<a
							href="https://wiki.mevuelo.com/es/administracion/procesos-devoluciones"
							target="_blank"
							rel="noopener noreferrer"
							style={{ marginLeft: 4 }}
						>
							nuestra wiki
						</a>
						<br />
						Si te quedan dudas, consulta con tu líder!
					</Typography>

					{/*
            Hacemos que las cards sean más anchas cambiando los breakpoints.
            Por ejemplo: xs=12 (toma todo el ancho en pantallas chicas), sm=6 (dos columnas en pantallas medianas),
            md=6 (dos columnas en pantallas medianas y grandes), etc.
          */}
					<Grid container paddingTop={4} spacing={2} justifyContent="flex-start">
						{filteredCards.map((card) => (
							<Grid item xs={12} sm={6} md={6} lg={4} key={card.id}>
								{card.isExternal ? (
									<a href={card.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
										<Card
											sx={{
												display: 'flex',
												flexDirection: 'column',
												height: '100%',
												// maxWidth más amplio; si quieres fijo, podrías usar e.g. 400 o 500
												maxWidth: 400,
												minWidth: 300,
												margin: '0 auto',
											}}
										>
											<CardMedia
												component="div"
												sx={{
													padding: 1,
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Typography variant="h4">{card.icon}</Typography>
											</CardMedia>
											<CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
												<Typography variant="subtitle1" component="div" paddingBottom={2}>
													{card.title}
												</Typography>
												<div
													style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
												>
													<div style={{ flex: 1 }}>
														<Typography variant="body2" color="text.secondary">
															<strong>Cuando se utiliza:</strong>
															<br />
															{card.example}
														</Typography>
													</div>
													<div style={{ flex: 1, marginTop: 8 }}>
														<Typography variant="body2" color="text.secondary">
															<strong>Info necesaria:</strong>
															<br />
															{card.needs.map((need, idx) => (
																<span key={idx}>
																	👉 {need}
																	<br />
																</span>
															))}
														</Typography>
													</div>
												</div>
												<Button sx={{ mt: 2 }} variant="outlined">
													INICIAR
												</Button>
											</CardContent>
										</Card>
									</a>
								) : (
									<RouterLink to={card.link} style={{ textDecoration: 'none' }}>
										<Card
											sx={{
												display: 'flex',
												flexDirection: 'column',
												height: '100%',
												maxWidth: 400,
												minWidth: 300,
												margin: '0 auto',
											}}
										>
											<CardMedia
												component="div"
												sx={{
													padding: 1,
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Typography variant="h4">{card.icon}</Typography>
											</CardMedia>
											<CardContent sx={{ flexGrow: 1 }}>
												<Typography variant="subtitle1" component="div">
													{card.title}
												</Typography>
												<Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
													{card.example}
												</Typography>
											</CardContent>
										</Card>
									</RouterLink>
								)}
							</Grid>
						))}
					</Grid>
				</Box>

				<Box sx={{ flexGrow: 1, padding: 2, margin: '0 15%' }}>
					<Card raised sx={{ display: 'flex', flexDirection: 'column', height: '100%', padding: 2 }}>
						<Typography variant="h6" color="error">
							IMPORTANTE
						</Typography>
						<Typography fontWeight="bold" variant="subtitle1">
							Sobre la cuenta bancaria del cliente
						</Typography>
						<Typography variant="body2">
							La informacion debe contener todos los datos citados a continuacion, de lo contrario podríamos
							experimentar errores o demoras:
						</Typography>
						<Typography variant="body2">- Banco</Typography>
						<Typography variant="body2">- Sucursal</Typography>
						<Typography variant="body2">- Titular de la cuenta</Typography>
						<Typography variant="body2">- Número de cuenta</Typography>
						<Typography variant="body2">- Tipo de cuenta (caja de ahorros, cuenta corriente)</Typography>
						<Typography variant="body2">- Moneda</Typography>
					</Card>
				</Box>
			</>
		</Layout>
	);
};

export default Refund;
