import {
	Alert,
	AlertColor,
	Backdrop,
	CircularProgress,
	Grid,
	MenuItem,
	Pagination,
	Select,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { pageSizeOptions } from 'constants/constants';
import { FilterGrid } from 'features/common/components/FilterGrid/FilterGrid';
import {
	convertFiltersToRecord,
	createSortHandler,
	ExportExcelOptions,
	exportToExcel,
	formatDateTimeToShowUser,
	getEnvelopedListPageTotal,
	getSortableFields,
} from 'features/common/helpers';
import {
	DateToFromFilterModel,
	FilterModel,
	FilterType,
	IHeadCell,
	ISort,
	OperatorFilterEnum,
	SelectFilterModelSimple,
} from 'features/common/types';
import { extractErrorMessage } from 'features/quotation/helpers';
import {
	getTNGatewayDocumentTypeName,
	getTransactionLineTypeVN,
	getPersonTypeVN,
	getBancardPaymentStateVN,
} from 'features/transactions/helpers';
import { getTransactionBancardListVN, getTransactionBancardListExportVN } from 'features/transactions/services';
import {
	gatewayDistinctToDLocalDocumentTypeList,
	IVNBancardTransactionList,
	bancardPaymentState,
	lineTypeVN,
	personTypeVN,
} from 'features/transactions/types';
import React, { useState, useEffect } from 'react';
import { ExportToExcelButton } from '../../../common/components/ExportToExcelButton';
import { PageNoResult } from '../../../common/components/PageNoResults';
const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const headerCells: IHeadCell[] = [
	{
		field: 'orderId',
		sortable: true,
		headerName: 'Orden',
	},
	{
		field: 'relationId',
		sortable: true,
		headerName: 'relationId',
	},
	{
		field: 'lineType',
		sortable: true,
		headerName: 'Tipo de Línea',
	},
	{
		field: 'crmTicket',
		sortable: true,
		headerName: 'Deal o Caso',
	},
	{
		field: 'isBitrixDeal',
		sortable: true,
		headerName: 'Es un Deal',
	},
	{
		field: 'file',
		sortable: true,
		headerName: 'file',
	},
	{
		field: 'reserveCode',
		sortable: true,
		headerName: 'Código de reserva',
	},
	{
		field: 'invoiceNumber',
		sortable: true,
		headerName: 'Número de Factura',
	},
	{
		field: 'amount',
		sortable: true,
		headerName: 'Monto',
	},
	{
		field: 'personType',
		sortable: true,
		headerName: 'Tipo de persona',
	},
	{
		field: 'firstName',
		sortable: true,
		headerName: 'Nombre',
	},
	{
		field: 'lastName',
		sortable: true,
		headerName: 'Apellidos',
	},
	{
		field: 'email',
		sortable: true,
		headerName: 'Email',
	},
	{
		field: 'document',
		sortable: true,
		headerName: 'Documento',
	},
	{
		field: 'documentType',
		sortable: true,
		headerName: 'Tipo de documento',
	},
	{
		field: 'phone',
		sortable: true,
		headerName: 'Teléfono',
	},
	{
		field: 'bancardPaymentState',
		sortable: true,
		headerName: 'Estado',
	},
	{
		field: 'approvalCode',
		sortable: true,
		headerName: 'Código de aprobación',
	},
	{
		field: 'createdAt',
		sortable: true,
		headerName: 'Fecha de Creación',
	},
];

export const BancardListOrangeAgency = (): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const [currentSize, setCurrentSize] = useState<number>(10);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageTotal, setPageTotal] = useState<number>(0);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [transactionsList, setTransactionsList] = useState<IVNBancardTransactionList[] | null>(null);
	const [sortFields, setSortFields] = useState<ISort[]>([{ field: 'createdAt', order: 'desc' }]);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>();

	const filters = [
		{ label: 'Orden', type: FilterType.STRING, key: 'orderId' },
		{ label: 'relationId', type: FilterType.STRING, key: 'relationId' },
		new SelectFilterModelSimple('Tipo de Línea', 'lineType', lineTypeVN),
		{
			label: 'Deal o Caso',
			type: FilterType.NUMERIC,
			key: 'crmTicket',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Es un Deal',
			type: FilterType.BOOLEAN,
			key: 'isBitrixDeal',
			operator: OperatorFilterEnum.EQUALS,
		},
		{ label: 'File', type: FilterType.STRING, key: 'file', operator: OperatorFilterEnum.EQUALS },
		{ label: 'Codigo de reserva', type: FilterType.NUMERIC, key: 'reserveCode' },
		{ label: 'Numero de factura', type: FilterType.NUMERIC, key: 'invoiceNumber' },
		{ label: 'Monto', type: FilterType.NUMERIC, key: 'amount' },
		{ label: 'Monto desde', type: FilterType.NUMERIC, key: 'amountFrom' },
		{ label: 'Monto hasta', type: FilterType.NUMERIC, key: 'amountTo' },
		new SelectFilterModelSimple('Tipo de persona', 'personType', personTypeVN),
		{
			label: 'Nombre',
			type: FilterType.STRING,
			key: 'firstName',
			operator: OperatorFilterEnum.CONTAINS,
		},
		{
			label: 'Apellido',
			type: FilterType.STRING,
			key: 'lastName',
			operator: OperatorFilterEnum.CONTAINS,
		},
		{
			label: 'E-Mail',
			type: FilterType.STRING,
			key: 'email',
			operator: OperatorFilterEnum.CONTAINS,
		},
		new SelectFilterModelSimple('Tipo de Documento', 'documentType', gatewayDistinctToDLocalDocumentTypeList),
		{
			label: 'Teléfono',
			type: FilterType.STRING,
			key: 'phone',
			operator: OperatorFilterEnum.CONTAINS,
		},
		new SelectFilterModelSimple('Estado', 'bancardPaymentState', bancardPaymentState),
		{ label: 'Codigo de aprobación', type: FilterType.NUMERIC, key: 'approvalCode' },
		{ label: 'Fecha de Creación', type: FilterType.DATE, key: 'createdAt' },
		new DateToFromFilterModel('La Fecha de Creación', 'createdAtFrom', 'createdAtTo'),
	];

	const handleApplyFilters = (filters: FilterModel[]) => setFilterApplied([...filters]);

	const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
		setCurrentSize(+event.target.value);
		setCurrentPage(1);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	const sortableFields = getSortableFields(headerCells);

	const transformValue = (key: string, value: any, row: IVNBancardTransactionList) => {
		switch (key) {
			case 'file':
				return row?.file ? row.file : '-';
			case 'createdAt':
				return formatDateTimeToShowUser(value);
			case 'crmTicket':
				return row.crmTicket ? row.crmTicket : '-';
			case 'isBitrixDeal':
				return row.isBitrixDeal === true ? 'Si' : row.isBitrixDeal === false ? 'No' : '-';
			case 'reserveCode':
				return row.reserveCode ? row.reserveCode : '-';
			case 'orderId':
				return row.orderId ? row.orderId : '-';
			case 'approvalCode':
				return row.approvalCode ? row.approvalCode : '-';
			case 'lineType':
				return getTransactionLineTypeVN(row.lineType);
			case 'personType':
				return getPersonTypeVN(row.personType);
			case 'documentType':
				return getTNGatewayDocumentTypeName(row.documentType);
			case 'bancardPaymentState':
				return getBancardPaymentStateVN(row.bancardPaymentState);
			case 'amount':
				return `PYG ${value}`;
			default:
				return value;
		}
	};

	const loadTransactionList = async () => {
		try {
			setLoading(true);
			const envelopedTransactionList = (
				await getTransactionBancardListVN(
					currentPage - 1,
					currentSize,
					sortFields,
					convertFiltersToRecord(filterApplied ?? []),
				)
			).data;
			setPageTotal(getEnvelopedListPageTotal(envelopedTransactionList));
			setTransactionsList([...envelopedTransactionList.data]);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir el listado de transacciones'),
			});
			setLoading(false);
		}
	};

	const handleExportToExcel = async () => {
		try {
			setLoading(true);
			const title = 'Listado de Transacciones en Bancard de Viaje Naranja';
			const envelopedTransactionList = (
				await getTransactionBancardListExportVN(sortFields, convertFiltersToRecord(filterApplied ?? []))
			).data;
			const listTransactions = envelopedTransactionList.data;
			const optionsToExport = {
				title: title,
				headers: headerCells,
				widthColumns: [10, 10, 20, 20, 20, 20, 20, 15, 15, 20, 20, 30, 20, 20, 20, 20, 20, 20, 20],
				filename: title,
			} as ExportExcelOptions;
			exportToExcel(listTransactions, optionsToExport, transformValue);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al exportar el listado de transacciones'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		loadTransactionList();
	}, [filterApplied, sortFields, currentPage, currentSize]);

	const colSpan = headerCells.length;

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container gap={1}>
				<Grid container spacing={2}>
					<Grid item xs={11}>
						<FilterGrid filters={filters} handleAppliedFilters={handleApplyFilters} preAppliedFilters={filterApplied} />
					</Grid>
					<Grid item xs={1}>
						<ExportToExcelButton disabled={transactionsList?.length == 0} handleExportToExcel={handleExportToExcel} />
					</Grid>
				</Grid>
				<TableContainer component="main" sx={{ maxWidth: 'xl' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{headerCells.map((cell) => {
										return cell.sortable ? (
											<TableCell
												key={cell.field}
												align="center"
												className={classes.tableHeader}
												sortDirection={
													sortFields.find((x) => x.field === cell.field)
														? sortFields.find((x) => x.field === cell.field)?.order
														: false
												}
											>
												<TableSortLabel
													active={cell.sortable && sortFields.find((x) => x.field === cell.field) !== undefined}
													sx={{
														color: 'white !important',
														'&:hover': {
															color: 'white !important',
														},
													}}
													direction={
														sortFields.find((x) => x.field === cell.field)
															? sortFields.find((x) => x.field === cell.field)?.order
															: 'asc'
													}
													onClick={createSortHandler(cell.field, sortFields, sortableFields, setSortFields)}
												>
													{cell.headerName}
												</TableSortLabel>
											</TableCell>
										) : (
											<TableCell className={classes.tableHeader} key={cell.field} align="center">
												{cell.headerName}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{!loading && transactionsList?.length === 0 ? (
									<TableRow>
										<TableCell colSpan={colSpan}>
											<PageNoResult />
										</TableCell>
									</TableRow>
								) : (
									transactionsList?.map((transaction) => (
										<TableRow
											key={transaction.relationId}
											hover
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell align="right" component="p">
												{transformValue('orderId', transaction?.orderId, transaction)}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.relationId}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('lineType', transaction.lineType, transaction)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('crmTicket', transaction?.crmTicket, transaction)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('isBitrixDeal', transaction?.isBitrixDeal, transaction)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('file', transaction?.file, transaction)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('reserveCode', transaction?.reserveCode, transaction)}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.invoiceNumber}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('amount', transaction.amount, transaction)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('personType', transaction.personType, transaction)}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.firstName}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.lastName}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.email}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.document}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('documentType', transaction.documentType, transaction)}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.phone}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('bancardPaymentState', transaction.bancardPaymentState, transaction)}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.approvalCode ? transaction.approvalCode : '-'}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('createdAt', transaction.createdAt, transaction)}
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
				<Grid item xs={12}>
					<Grid container justifyContent="center" flexDirection="row" padding={2}>
						<Grid item xs={12} md={1} textAlign="center">
							<Select value={currentSize} onChange={handleRowsPerPageChange}>
								{pageSizeOptions.map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={4} textAlign="center" marginTop={1}>
							<Pagination count={pageTotal} page={currentPage} onChange={handlePageChange} color="primary" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
