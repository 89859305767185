import React from 'react';
import { IPackageFEList } from '../../types';
import makeStyles from '@mui/styles/makeStyles';
import NumberFormat from 'react-number-format';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { getBasePriceString } from '../../helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	iconsContainer: {
		padding: '0px 16px',
		position: 'absolute',
		bottom: 0,
		left: 0,
		borderTopRightRadius: '14px',
		height: '28px',
		paddingTop: '5px',
	},
	title: {
		fontWeight: '500',
		fontSize: '18px',
		color: theme.palette.primary.main,
		padding: '16px',
	},
	tagHolder: {
		position: 'absolute',
		height: '28px',
		top: '12px',
		right: '0px',
		display: 'flex',
		alignItems: 'center',
		padding: '0px 16px',
		borderTopLeftRadius: '14px',
		borderBottomLeftRadius: '14px',
		backgroundColor: theme.palette.secondary.main,
	},
	tagItem: {
		fontStretch: 'normal',
		fontSize: '14px',
		marginBottom: '0px',
		textAlign: 'left',
		fontWeight: '600',
		fontStyle: 'normal',
		color: 'white',
	},
	descriptionHolder: {
		display: 'flex',
		padding: '0px 16px 16px',
	},
	descriptionHolderItem: {
		flex: '1 1 0%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},
	itemDescription: {
		fontSize: '12px',
		color: 'rgb(123, 123, 123)',
		fontFamily: 'Montserrat, serif',
		fontWeight: 'normal',
		fontStyle: 'normal',
		fontStretch: 'normal',
		marginBottom: '0px',
		textAlign: 'left',
	},
	priceHolder: {
		flex: '1 1 0%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
	},
	priceItem: {
		display: 'flex',
		alignItems: 'stretch',
	},
	fromAndCurrencyContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	fromItem: {
		fontSize: '8px',
		color: theme.palette.secondary.main,
		fontFamily: 'Montserrat, serif',
		fontWeight: 600,
		lineHeight: '10px',
	},
	currencyItem: {
		fontSize: '14px',
		color: theme.palette.secondary.main,
		fontFamily: 'Montserrat, serif',
		fontWeight: 600,
		lineHeight: '17px',
	},
	priceValueItem: {
		fontSize: '30px',
		color: theme.palette.secondary.main,
		fontFamily: 'Montserrat, serif',
		fontWeight: 500,
		lineHeight: '37px',
	},
	normalItemText: {
		fontSize: '12px',
		color: theme.palette.secondary.main,
		fontFamily: 'Montserrat, serif',
		fontWeight: 'normal',
		fontStyle: 'normal',
		textAlign: 'right',
	},
	link: {
		textDecoration: 'none',
	},
}));

export const PackageListCard = ({ packageItem }: { packageItem: IPackageFEList }): JSX.Element => {
	const classes = useStyles();
	return (
		<Link to={`/stats/packages?packageId=${packageItem.id}`} className={classes.link}>
			<Card elevation={5}>
				<CardMedia sx={{ height: 259, position: 'relative' }} image={packageItem.image} title={packageItem.title}>
					{packageItem.rating && (
						<div className={classes.tagHolder}>
							<Typography className={classes.tagItem} component="span">
								{packageItem.rating.name}
							</Typography>
						</div>
					)}
					<div
						className={classes.iconsContainer}
						style={{
							backgroundImage: `linear-gradient(to right,${packageItem.shadowColor}, ${packageItem.shadowColor})`,
						}}
					>
						{packageItem.compositeIcons.map((icon, index) => (
							<span
								key={index}
								style={{ color: 'white', fontSize: '20px', marginLeft: '10px' }}
								className={`icon icon-${icon.icon}`}
							></span>
						))}
					</div>
				</CardMedia>

				<CardContent>
					<Typography className={classes.title}>{packageItem.title}</Typography>
					<div className={classes.descriptionHolder}>
						<div className={classes.descriptionHolderItem}>
							<Typography className={classes.itemDescription} component="p">
								{packageItem.travelOn}
							</Typography>
							<Typography className={classes.itemDescription} component="p">
								{packageItem.duration}
							</Typography>
							<Typography className={classes.itemDescription} component="p" style={{ fontWeight: 600 }}>
								Incluye:
							</Typography>
							{packageItem.compositeIcons.map((icon, index) => (
								<Typography key={`icon-name-${index}`} className={classes.itemDescription} component="p">
									*{icon.name}
								</Typography>
							))}
						</div>

						<div className={classes.priceHolder}>
							<div className={classes.priceItem}>
								<div className={classes.fromAndCurrencyContainer}>
									<Typography className={classes.fromItem} component="span">
										DESDE
									</Typography>
									<Typography className={classes.currencyItem} component="span">
										{packageItem.lowerPrice.currency}
									</Typography>
								</div>
								<Typography className={classes.priceValueItem} component="span">
									{packageItem.lowerPrice.amount}
								</Typography>
							</div>
							{packageItem.lowerLocalPrice && (
								<Typography className={classes.normalItemText} component="p">
									<NumberFormat
										value={packageItem.lowerLocalPrice.amount}
										displayType="text"
										decimalSeparator=","
										thousandSeparator="."
										prefix={`${packageItem.lowerLocalPrice.currency} `}
									/>
								</Typography>
							)}
							<Typography className={classes.normalItemText} component="span">
								Precio por persona
							</Typography>
							<Typography className={classes.normalItemText} component="span" style={{ fontWeight: 'bold' }}>
								{getBasePriceString(packageItem.basePrice)}
							</Typography>
						</div>
					</div>
				</CardContent>
			</Card>
		</Link>
	);
};
