import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField, FormHelperText } from '@mui/material';
import { useStyles } from 'features/salesOrder/components/SalesOrderServiceForm/styles';

interface Props {
	name: string;
	rules?: Record<string, any>;
	error?: boolean;
	helperText?: string;
	inputProps?: Record<string, any>;
	size?: 'small' | 'medium';
	variant?: 'outlined' | 'standard';
	onChange?: (value: string) => void;
	isNumber?: boolean;
	disabled?: boolean;
}

export const ControlledTextField = ({
	name,
	rules,
	helperText,
	size = 'small',
	variant = 'outlined',
	onChange,
	disabled = false,
	isNumber,
	...inputProps
}: Props): JSX.Element => {
	const classes = useStyles();
	const { control, formState } = useFormContext();
	const error = formState.errors[name]?.message;

	return (
		<Controller
			// @ts-ignore
			name={name}
			control={control}
			rules={rules}
			render={({ field }) => (
				<>
					<TextField
						variant={variant}
						onWheel={(event) => {
							const element = event.target as HTMLInputElement;
							element.blur();
						}}
						fullWidth
						size={size}
						className={classes.select}
						error={error}
						{...field}
						onChange={(e) => {
							field.onChange(e);
							if (onChange) {
								onChange(e.target.value);
							}
						}}
						disabled={disabled}
						{...inputProps}
						type={isNumber ? 'number' : 'text'}
					/>
					{helperText && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}
				</>
			)}
		/>
	);
};
