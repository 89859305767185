import React, { useCallback, useEffect, useState } from 'react';
import { IFlightServicePassengersByPassengerType, PassengerTypeEnum, ServiceUnitEnum } from 'features/salesOrder/types';
import { Box, Button, Collapse, Divider, Grid, IconButton, Typography } from '@mui/material';
import { ControlledAutoComplete } from '../../../../../../features/common/components/ControlledAutoComplete';
import { checkShowErrorMessage } from '../../../../../../features/common/helpers';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
	calcularPrecioFinal,
	calculatMarkupPercentaje,
	returnPaxName,
} from '../../../../../../features/salesOrder/components/SalesOrderServiceForm/helpers';
import { ControlledTextField } from '../../../../../../features/common/components/ControlledTextField';
import { Delete, ExpandLess, Add, ExpandMore } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface FlightServicePassengerGroupProps {
	unit: ServiceUnitEnum;
	value: IFlightServicePassengersByPassengerType;
	allPassengers: { keyId: string; name: string; lastName: string }[];
	index: number;
	isLast: boolean;
	handleRemovePassengerGroup: (index: number) => void;
	existBGroup: boolean;
	existCGroup: boolean;
	existDGroup: boolean;
	handleAddPassengerGroup: (passengerType: PassengerTypeEnum) => void;
}

export const FlightServicePassengerGroup = ({
	unit,
	allPassengers,
	value,
	index,
	isLast,
	handleRemovePassengerGroup,
	existBGroup,
	existCGroup,
	existDGroup,
	handleAddPassengerGroup,
}: FlightServicePassengerGroupProps): JSX.Element => {
	const { auth } = useSelector((state: RootState) => state);
	const theCountry = auth.country;
	const {
		control,
		formState: { errors },
		setValue,
		getValues,
		watch,
		register,
	} = useFormContext();

	// @ts-ignore
	const {
		fields: paxTickets,
		append,
		remove,
	} = useFieldArray({
		control,
		// @ts-ignore
		name: `passengersGroupByPassengerType.${index}.passengersTickets`,
	});

	const [open, setOpen] = useState(true);
	const title = `Pasajeros con grupo tarifario tipo ${value.passengerType}:`;

	const handleToggle = () => setOpen((prev) => !prev);

	const passengersSelected =
		watch(`passengersGroupByPassengerType.${index}.passengerIds` as `${string}.${string | number}`) || [];

	useEffect(() => {
		const cost = getValues(`passengersGroupByPassengerType.${index}.cost` as `${string}.${string | number}`);
		const saleAmount = getValues(
			`passengersGroupByPassengerType.${index}.saleAmount` as `${string}.${string | number}`,
		);
		if (theCountry === 'CO') {
			const saleAmountInLocalCurrency = getValues(
				`passengersGroupByPassengerType.${index}.saleAmountInLocalCurrency` as `${string}.${string | number}`,
			);
			calculateTotalCost(cost, saleAmount, saleAmountInLocalCurrency);
		} else {
			calculateTotalCost(cost, saleAmount);
		}
	}, [unit, passengersSelected]);

	useEffect(() => {
		setValue(
			`passengersGroupByPassengerType.${index}.passengerType` as `${string}.${string | number}`,
			value.passengerType,
		);
		setValue(
			`passengersGroupByPassengerType.${index}.passengerIds` as `${string}.${string | number}`,
			value.passengerIds,
			{ shouldValidate: true },
		);
		setValue(`passengersGroupByPassengerType.${index}.cost` as `${string}.${string | number}`, value.cost);
		setValue(
			`passengersGroupByPassengerType.${index}.markupPercentage` as `${string}.${string | number}`,
			value.markupPercentage || 0,
		);
		setValue(`passengersGroupByPassengerType.${index}.saleAmount` as `${string}.${string | number}`, value.saleAmount);
		setValue(`passengersGroupByPassengerType.${index}.totalCost` as `${string}.${string | number}`, value.totalCost);
		setValue(
			`passengersGroupByPassengerType.${index}.totalSaleAmount` as `${string}.${string | number}`,
			value.totalSaleAmount,
		);
		setValue(
			`passengersGroupByPassengerType.${index}.passengersTickets` as `${string}.${string | number}`,
			value.passengersTickets,
		);
	}, [value, index, setValue]);

	const calculateTotalCost = useCallback(
		(cost, saleAmount, saleAmountInLocalCurrency?: number) => {
			if (unit === ServiceUnitEnum.Person) {
				const totalPaxInGroup = passengersSelected.length;
				const totalCost = cost * totalPaxInGroup;
				const totalSaleAmount = saleAmount * totalPaxInGroup;

				setValue(`passengersGroupByPassengerType.${index}.totalCost` as `${string}.${string | number}`, totalCost);
				setValue(
					`passengersGroupByPassengerType.${index}.totalSaleAmount` as `${string}.${string | number}`,
					totalSaleAmount,
				);

				if (saleAmountInLocalCurrency) {
					const totalSaleAmountInLocalCurrency = saleAmountInLocalCurrency * totalPaxInGroup;
					setValue(
						`passengersGroupByPassengerType.${index}.totalSaleAmountInLocalCurrency` as `${string}.${string | number}`,
						totalSaleAmountInLocalCurrency,
					);
				}
			} else {
				setValue(`passengersGroupByPassengerType.${index}.totalCost` as `${string}.${string | number}`, cost);
				setValue(
					`passengersGroupByPassengerType.${index}.totalSaleAmount` as `${string}.${string | number}`,
					saleAmount,
				);
				if (saleAmountInLocalCurrency) {
					setValue(
						`passengersGroupByPassengerType.${index}.totalSaleAmountInLocalCurrency` as `${string}.${string | number}`,
						saleAmountInLocalCurrency,
					);
				}
			}
		},
		[unit, getValues, passengersSelected],
	);

	useEffect(() => {
		const paxTicketsInForm =
			getValues(`passengersGroupByPassengerType.${index}.passengersTickets` as `${string}.${string | number}`) || [];
		const diff = paxTicketsInForm.length - passengersSelected.length;

		if (diff < 0) {
			for (let i = 0; i < passengersSelected.length; i++) {
				if (Boolean(paxTicketsInForm[i]) === false) {
					append({ passengerId: passengersSelected[i], ticket: '-' });
				}
			}
		} else if (diff > 0) {
			for (let i = 0; i < paxTicketsInForm.length; i++) {
				// @ts-ignore
				if (passengersSelected.includes(paxTicketsInForm[i].passengerId) === false) {
					remove(i);
				}
			}
		}
	}, [passengersSelected]);

	const handleCostChange = (newCost: number) => {
		setValue(`passengersGroupByPassengerType.${index}.cost` as `${string}.${string | number}`, newCost);
		const saleAmount =
			getValues(`passengersGroupByPassengerType.${index}.saleAmount` as `${string}.${string | number}`) || 0;
		const newMarkup = parseFloat(calculatMarkupPercentaje(newCost, saleAmount) as string);
		setValue(`passengersGroupByPassengerType.${index}.markupPercentage` as `${string}.${string | number}`, newMarkup);
		if (theCountry === 'CO') {
			const saleAmountInLocalCurrency = getValues(
				`passengersGroupByPassengerType.${index}.saleAmountInLocalCurrency` as `${string}.${string | number}`,
			);
			calculateTotalCost(newCost, saleAmount, saleAmountInLocalCurrency);
		} else {
			calculateTotalCost(newCost, saleAmount);
		}
	};

	const handleMarkupPercentageChange = (newValue: number) => {
		setValue(`passengersGroupByPassengerType.${index}.markupPercentage` as `${string}.${string | number}`, newValue);
		const cost = getValues(`passengersGroupByPassengerType.${index}.cost` as `${string}.${string | number}`) || 0;
		const newSaleAmount = parseFloat(calcularPrecioFinal(cost, newValue) as string);
		setValue(`passengersGroupByPassengerType.${index}.saleAmount` as `${string}.${string | number}`, newSaleAmount);

		if (theCountry === 'CO') {
			const rateValue = getValues('currencyRate' as `${string}.${string | number}`) || 1;
			const valorLocalCurrency = Number(newSaleAmount) * rateValue;
			setValue(
				`passengersGroupByPassengerType.${index}.saleAmountInLocalCurrency` as `${string}.${string | number}`,
				valorLocalCurrency,
			);
		}

		if (theCountry === 'CO') {
			const saleAmountInLocalCurrency = getValues(
				`passengersGroupByPassengerType.${index}.saleAmountInLocalCurrency` as `${string}.${string | number}`,
			);
			calculateTotalCost(cost, newSaleAmount, saleAmountInLocalCurrency);
		} else {
			calculateTotalCost(cost, newSaleAmount);
		}
	};

	const handleSaleAmountChange = (newValue: number) => {
		setValue(`passengersGroupByPassengerType.${index}.saleAmount` as `${string}.${string | number}`, newValue);
		const cost = getValues(`passengersGroupByPassengerType.${index}.cost` as `${string}.${string | number}`) || 0;

		if (newValue === 0) {
			setValue(`passengersGroupByPassengerType.${index}.markupPercentage` as `${string}.${string | number}`, 0);
		} else {
			const newMarkup = calculatMarkupPercentaje(cost, newValue) as string;
			setValue(`passengersGroupByPassengerType.${index}.markupPercentage` as `${string}.${string | number}`, newMarkup);
		}

		if (theCountry === 'CO') {
			const rateValue = getValues('currencyRate' as `${string}.${string | number}`) || 1;
			const saleAmountInLocalCurrency = newValue * rateValue;
			setValue(
				`passengersGroupByPassengerType.${index}.saleAmountInLocalCurrency` as `${string}.${string | number}`,
				saleAmountInLocalCurrency,
			);
			calculateTotalCost(cost, newValue, saleAmountInLocalCurrency);
		} else {
			calculateTotalCost(cost, newValue);
		}
	};

	const handleSaleAmountChangeCOP = (valorEnCop: number) => {
		setValue(
			`passengersGroupByPassengerType.${index}.saleAmountInLocalCurrency` as `${string}.${string | number}`,
			valorEnCop,
		);
		const rateValue = getValues('currencyRate' as `${string}.${string | number}`) || 1;
		const valorEnDolares = valorEnCop / rateValue;
		setValue(`passengersGroupByPassengerType.${index}.saleAmount` as `${string}.${string | number}`, valorEnDolares);
		const cost = getValues(`passengersGroupByPassengerType.${index}.cost` as `${string}.${string | number}`) || 0;

		if (valorEnDolares === 0) {
			setValue(`passengersGroupByPassengerType.${index}.markupPercentage` as `${string}.${string | number}`, 0);
		} else {
			const newMarkup = calculatMarkupPercentaje(cost, valorEnDolares) as string;
			setValue(`passengersGroupByPassengerType.${index}.markupPercentage` as `${string}.${string | number}`, newMarkup);
		}
		if (theCountry === 'CO') {
			calculateTotalCost(cost, valorEnDolares, valorEnCop);
		} else {
			calculateTotalCost(cost, valorEnDolares);
		}
	};

	return (
		<Box mb={2}>
			<Divider sx={{ marginBottom: 2 }} />
			<Grid container alignItems="center" spacing={1} style={{ cursor: 'pointer' }}>
				<Grid item onClick={handleToggle}>
					<Typography variant="h6">{title}</Typography>
				</Grid>

				<Grid item onClick={handleToggle}>
					<IconButton size="small">{open ? <ExpandLess /> : <ExpandMore />}</IconButton>
				</Grid>

				{value.passengerType != PassengerTypeEnum.A && (
					<Grid item>
						<IconButton
							size="small"
							title="Eliminar este grupo"
							onClick={(e) => {
								e.stopPropagation(); // Evita que el clic en eliminar afecte el colapso
								handleRemovePassengerGroup(index);
							}}
						>
							<Delete />
						</IconButton>
					</Grid>
				)}
			</Grid>

			<Collapse in={open} timeout="auto" unmountOnExit>
				<Grid container spacing={2} style={{ marginTop: 5, marginBottom: 5 }}>
					<Grid item sx={{ display: 'none' }}>
						<ControlledTextField name={`passengersGroupByPassengerType.${index}.passengerType`} />
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography color={'Grey'} mb={1}>
							Pasajeros
						</Typography>
						<ControlledAutoComplete
							name={`passengersGroupByPassengerType.${index}.passengerIds`}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
							}}
							options={allPassengers}
							isMultiple={true}
							helperText={checkShowErrorMessage(
								Boolean(errors.passengersGroupByPassengerType?.[index]?.passengerIds),
								errors.passengersGroupByPassengerType?.[index]?.passengerIds?.message,
							)}
						/>
					</Grid>

					<Grid item xs={12}>
						<Typography color={'Grey'} mb={1}>
							Ticket x Pasajero {value.passengerType}
						</Typography>
						{paxTickets.map((field, indexTicket) => (
							<Box display="flex" key={field.id} alignItems="end" gap={2} mb={2}>
								<Typography color={'Grey'} width={300}>
									{returnPaxName(
										allPassengers,
										getValues(
											`passengersGroupByPassengerType.${index}.passengersTickets.${indexTicket}.passengerId` as `${string}.${
												| string
												| number}`,
										) as string,
									)}
								</Typography>
								<input
									type="hidden"
									// @ts-ignore
									{...register(`passengersGroupByPassengerType.${index}.passengersTickets.${indexTicket}.passengerId`)}
								/>
								<ControlledTextField
									name={`passengersGroupByPassengerType.${index}.passengersTickets.${indexTicket}.ticket`}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
									}}
									helperText={checkShowErrorMessage(
										Boolean(errors.passengersGroupByPassengerType?.[index]?.passengersTickets?.[indexTicket]?.ticket),
										errors.passengersGroupByPassengerType?.[index]?.passengersTickets?.[indexTicket]?.ticket?.message,
									)}
								/>
							</Box>
						))}
					</Grid>

					<Grid item xs={12} sm={4}>
						<Typography color={'Grey'}>Costo</Typography>
						<ControlledTextField
							name={`passengersGroupByPassengerType.${index}.cost`}
							isNumber
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
							}}
							onChange={(value) => handleCostChange(parseFloat(value))}
							helperText={checkShowErrorMessage(
								Boolean(errors.passengersGroupByPassengerType?.[index]?.cost),
								errors.passengersGroupByPassengerType?.[index]?.cost?.message,
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color={'Grey'}>Markup %</Typography>
						<ControlledTextField
							isNumber
							name={`passengersGroupByPassengerType.${index}.markupPercentage`}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
							}}
							onChange={(value) => handleMarkupPercentageChange(parseFloat(value))}
							helperText={checkShowErrorMessage(
								Boolean(errors.passengersGroupByPassengerType?.[index]?.markupPercentage),
								errors.passengersGroupByPassengerType?.[index]?.cost?.markupPercentage,
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color={'Grey'}>Venta</Typography>
						<ControlledTextField
							isNumber
							name={`passengersGroupByPassengerType.${index}.saleAmount`}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
							}}
							onChange={(value) => handleSaleAmountChange(parseFloat(value))}
							helperText={checkShowErrorMessage(
								Boolean(errors.passengersGroupByPassengerType?.[index]?.saleAmount),
								errors.passengersGroupByPassengerType?.[index]?.cost?.saleAmount,
							)}
						/>
					</Grid>
					{theCountry === 'CO' && (
						<>
							<Grid item xs={12} sm={4} />
							<Grid item xs={12} sm={4} />
							<Grid item xs={12} sm={4}>
								<Typography color={'Grey'}>Venta en COP</Typography>
								<ControlledTextField
									isNumber
									name={`passengersGroupByPassengerType.${index}.saleAmountInLocalCurrency`}
									onChange={(value) => handleSaleAmountChangeCOP(parseFloat(value))}
									helperText={checkShowErrorMessage(
										Boolean(errors.passengersGroupByPassengerType?.[index]?.saleAmount),
										errors.passengersGroupByPassengerType?.[index]?.cost?.saleAmount,
									)}
								/>
							</Grid>
						</>
					)}
					<>
						<Grid item xs={12} sm={4} />
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Costo Total del grupo</Typography>
							<ControlledTextField name={`passengersGroupByPassengerType.${index}.totalCost`} disabled />
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Venta Total del grupo</Typography>
							<ControlledTextField name={`passengersGroupByPassengerType.${index}.totalSaleAmount`} disabled />
						</Grid>
					</>
				</Grid>
			</Collapse>
			<Grid container spacing={2} style={{ marginTop: 1, marginBottom: 1 }}>
				{!existBGroup && isLast && (
					<Grid item>
						<Button
							size="small"
							startIcon={<Add />}
							title={'Agregar grupo de pasajeros Niños'}
							onClick={(e) => {
								e.stopPropagation();
								handleAddPassengerGroup(PassengerTypeEnum.B);
							}}
						>
							Grupo tarifario tipo ({PassengerTypeEnum.B})
						</Button>
					</Grid>
				)}
				{!existCGroup && isLast && (
					<Grid item>
						<Button
							size="small"
							startIcon={<Add />}
							title={'Agregar grupo de pasajeros Infantes'}
							onClick={(e) => {
								e.stopPropagation();
								handleAddPassengerGroup(PassengerTypeEnum.C);
							}}
						>
							Grupo tarifario tipo ({PassengerTypeEnum.C})
						</Button>
					</Grid>
				)}
				{!existDGroup && isLast && (
					<Grid item>
						<Button
							size="small"
							startIcon={<Add />}
							title={'Agregar grupo de pasajeros Infantes'}
							onClick={(e) => {
								e.stopPropagation();
								handleAddPassengerGroup(PassengerTypeEnum.D);
							}}
						>
							Grupo tarifario tipo ({PassengerTypeEnum.D})
						</Button>
					</Grid>
				)}
			</Grid>
			<Divider />
		</Box>
	);
};
