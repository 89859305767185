import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	Alert,
	AlertColor,
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import { IPaymentTitularSP, ISiemprePagoFormData, PaymentTitularFormFieldLength } from '../../types';
import { checkShowErrorMessage } from '../../../common/helpers';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import { gatewayDistinctToDLocalDocumentTypeList } from 'features/transactions/types';
import { ISiemprePagoToken, SiemprePagoForm } from '../SiemprePagoForm';
import { createSPTransaction } from 'features/payment/services';
import { manageSPTransactionResponse } from 'features/payment/helpers';
import { FormFieldsMaxLength, ILocalize } from 'features/common/types';
import { emailPattern } from 'features/common/constants';
import routes from '../../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export interface SimplePaymentOrderProps {
	clientLocation: ILocalize | null;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export const SiemprePagoPaymentTransaction = ({ clientLocation }: SimplePaymentOrderProps): JSX.Element | null => {
	const { payment } = useSelector((state: RootState) => state);

	if (payment.orderDataToClient == null) {
		return null;
	}
	const defaultTransactionValues = {
		orderId: payment.orderDataToClient.orderId,
		firstName: '',
		lastName: '',
		document: '',
		documentType: 2,
		email: '',
		phone: '',
		address: '',
		country: clientLocation?.countryName || '',
		paymentToken: {
			token: '',
			targetType: '',
		},
		state: clientLocation?.city || '',
		city: clientLocation?.city || '',
		fee: 1,
	};
	const classes = useStyles();
	const history = useHistory();
	const {
		control,
		formState: { errors, isValid },
		getValues,
		setValue,
		reset,
	} = useForm({
		mode: 'all',
		defaultValues: { ...defaultTransactionValues, orderId: payment.orderDataToClient.orderId },
	});
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [siemprePagoPaymentModal, setSiemprePagoPaymentModal] = useState<boolean>(false);

	useEffect(() => reset(defaultTransactionValues), [clientLocation]);

	const handleToken = async (token: ISiemprePagoToken) => {
		setValue('paymentToken', token);
		setSiemprePagoPaymentModal(false);
		await onSubmit(getValues());
	};

	const handleCloseSPFormModal = () => setSiemprePagoPaymentModal(false);

	const onSubmit = async (data: any) => {
		try {
			setLoading(true);
			const customerInfoFromForm: IPaymentTitularSP = (({
				firstName,
				lastName,
				email,
				phone,
				documentType,
				document,
				country,
				state,
				city,
				address,
			}) => ({
				firstName,
				lastName,
				email,
				phone,
				documentType,
				document,
				country,
				state,
				city,
				address,
			}))(data);
			const dataToSend: ISiemprePagoFormData = {
				customerInfoSP: customerInfoFromForm,
				paymentToken: data.paymentToken,
				fee: data.fee,
				orderId: payment.orderDataToClient?.orderId || '',
			};
			const [alertType, alertMessage] = manageSPTransactionResponse((await createSPTransaction(dataToSend)).data);
			if (alertType !== 'success') {
				setAlert({ show: true, severity: alertType, message: alertMessage });
				setLoading(false);
			} else {
				history.push(routes.payment.thankYouTransaction);
			}
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al realizar la transacción'),
			});
			setLoading(false);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Typography variant="h6">Orden de pago por:</Typography>
			<Typography variant="h6">
				{payment.orderDataToClient?.coinCode} {payment.orderDataToClient?.amount}
			</Typography>
			<Typography variant="h6" paddingBottom={2}>
				Datos del Titular de Pago
			</Typography>
			<form noValidate>
				<Grid container spacing={2} justifyContent="center">
					<Grid item xs={12} md={6}>
						<Controller
							name="firstName"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Este campo es requerido',
								},
								maxLength: {
									value: PaymentTitularFormFieldLength.FIRSTNAME,
									message:
										'El nombre excede la cantidad de caracteres permitida de ' +
										PaymentTitularFormFieldLength.FIRSTNAME,
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Nombre"
									helperText={checkShowErrorMessage(Boolean(errors.firstName), errors.firstName?.message)}
									error={Boolean(errors.firstName)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							name="lastName"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Este campo es requerido',
								},
								maxLength: {
									value: PaymentTitularFormFieldLength.LASTNAME,
									message:
										'El apellido excede la cantidad de caracteres permitida de ' +
										PaymentTitularFormFieldLength.LASTNAME,
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Apellido"
									helperText={checkShowErrorMessage(Boolean(errors.lastName), errors.lastName?.message)}
									error={Boolean(errors.lastName)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							name="email"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Este campo es requerido',
								},
								pattern: {
									value: emailPattern,
									message: 'Ingrese un email válido',
								},
								maxLength: {
									value: FormFieldsMaxLength.EMAIL,
									message: 'El email excede el largo máximo permitido de: ' + FormFieldsMaxLength.EMAIL + ' caracteres',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="E-Mail"
									helperText={checkShowErrorMessage(Boolean(errors.email), errors.email?.message)}
									error={Boolean(errors.email)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							name="phone"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Este campo es requerido',
								},
								maxLength: {
									value: FormFieldsMaxLength.PHONE,
									message: 'El teléfono excede la cantidad de caracteres permitida de ' + FormFieldsMaxLength.PHONE,
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									type="tel"
									label="Teléfono"
									helperText={checkShowErrorMessage(Boolean(errors.phone), errors.phone?.message)}
									error={Boolean(errors.phone)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="address"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								maxLength: {
									value: PaymentTitularFormFieldLength.ADDRESS,
									message:
										'La Dirección excede el largo máximo permitido de: ' +
										PaymentTitularFormFieldLength.ADDRESS +
										' caracteres',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Direccion"
									helperText={checkShowErrorMessage(Boolean(errors.address), errors.address?.message)}
									error={Boolean(errors.address)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Controller
							name="country"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								maxLength: {
									value: PaymentTitularFormFieldLength.COUNTRY,
									message:
										'El Nombre del Pais excede el largo máximo permitido de: ' +
										PaymentTitularFormFieldLength.COUNTRY +
										' caracteres',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Pais"
									helperText={checkShowErrorMessage(Boolean(errors.country), errors.country?.message)}
									error={Boolean(errors.country)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Controller
							name="city"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								maxLength: {
									value: PaymentTitularFormFieldLength.CITY,
									message:
										'El Nombre de la Ciudad excede el largo máximo permitido de: ' +
										PaymentTitularFormFieldLength.CITY +
										' caracteres',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Ciudad"
									helperText={checkShowErrorMessage(Boolean(errors.city), errors.city?.message)}
									error={Boolean(errors.city)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Controller
							name="state"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								maxLength: {
									value: PaymentTitularFormFieldLength.STATE,
									message:
										'El Nombre del Estado excede el largo máximo permitido de: ' +
										PaymentTitularFormFieldLength.STATE +
										' caracteres',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Estado"
									helperText={checkShowErrorMessage(Boolean(errors.state), errors.state?.message)}
									error={Boolean(errors.state)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={6} md={2}>
						<Controller
							name="fee"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Este campo es requerido',
								},
							}}
							render={({ field }) => (
								<FormControl sx={{ width: '100%' }}>
									<InputLabel id="fee-label">Cuotas</InputLabel>
									<Select label="Cuotas" {...field}>
										{payment.orderDataToClient?.feeAllow?.map((fee) => {
											return (
												<MenuItem key={fee} value={fee}>
													{fee}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item xs={6} md={5}>
						<Controller
							name="documentType"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Este campo es requerido',
								},
							}}
							render={({ field }) => (
								<FormControl sx={{ width: '100%' }}>
									<InputLabel id="document-type-label">Tipo de Documento</InputLabel>
									<Select label="Tipo de Documento" {...field}>
										{gatewayDistinctToDLocalDocumentTypeList?.map((document) => {
											return (
												<MenuItem key={document.id} value={document.id}>
													{document.name}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={5}>
						<Controller
							name="document"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Este campo es requerido',
								},
								maxLength: {
									value: PaymentTitularFormFieldLength.DOCUMENT,
									message:
										'El documento excede la cantidad de caracteres permitida de ' +
										PaymentTitularFormFieldLength.DOCUMENT,
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Documento de identidad"
									helperText={checkShowErrorMessage(Boolean(errors.document), errors.document?.message)}
									error={Boolean(errors.document)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							sx={{ height: '100%', width: '100%' }}
							onClick={() => setSiemprePagoPaymentModal(true)}
							disabled={!isValid}
							color="success"
						>
							Procesar
						</Button>
					</Grid>
				</Grid>
			</form>
			{siemprePagoPaymentModal && isValid && (
				<SiemprePagoForm
					handleClose={handleCloseSPFormModal}
					coinCode={payment.orderDataToClient.coinCode}
					email={getValues('email')}
					amount={payment.orderDataToClient.amount}
					handleCaptureToken={handleToken}
				/>
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
