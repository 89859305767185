import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	Alert,
	AlertColor,
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import { IPaymentTitularDLocal, ITransactionInGatewayDLocalRequest, PaymentTitularFormFieldLength } from '../../types';
import { checkShowErrorMessage } from '../../../common/helpers';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import { createDLocalTransaction } from 'features/payment/services';
import { FormFieldsMaxLength, ILocalize } from 'features/common/types';
import { emailPattern } from 'features/common/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
export interface DLocalPaymentOrderProps {
	clientLocation: ILocalize | null;
	countryCode: string;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export const DLocalClientTransaction = ({
	clientLocation,
	countryCode,
}: DLocalPaymentOrderProps): JSX.Element | null => {
	const { payment } = useSelector((state: RootState) => state);

	if (payment.orderDataToClient == null) {
		return null;
	}
	const defaultTransactionValues = {
		orderId: payment.orderDataToClient.orderId,
		firstName: '',
		lastName: '',
		document: '',
		documentType: payment.orderDataToClient.billingDocumentTypeToShow[0]?.id || 0,
		email: '',
		phone: '',
		address: '',
		zipCode: '',
		state: clientLocation?.city || '',
		city: clientLocation?.city || '',
	};
	const classes = useStyles();
	const {
		control,
		formState: { errors, isValid },
		getValues,
		reset,
	} = useForm({
		mode: 'all',
		defaultValues: { ...defaultTransactionValues, orderId: payment.orderDataToClient.orderId },
	});
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);

	useEffect(() => reset(defaultTransactionValues), [clientLocation]);

	const onSubmit = async (data: any) => {
		try {
			setLoading(true);
			const customerInfoFromForm: IPaymentTitularDLocal = (({
				firstName,
				lastName,
				email,
				phone,
				documentType,
				document,
				state,
				city,
				address,
				zipCode,
			}) => ({
				firstName,
				lastName,
				email,
				phone,
				documentType,
				document,
				state,
				city,
				address,
				zipCode,
			}))(data);
			const dataToSend: ITransactionInGatewayDLocalRequest = {
				paymentTitularDLocal: customerInfoFromForm,
				orderId: payment.orderDataToClient?.orderId || '',
			};
			const result = await createDLocalTransaction(dataToSend);
			window.location.href = result.data.redirectUrl;
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al realizar la transacción'),
			});
			setLoading(false);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Typography variant="h6">Orden de pago por:</Typography>
			<Typography variant="h6">
				{payment.orderDataToClient?.coinCode} {payment.orderDataToClient?.amount}
			</Typography>
			<Typography variant="h6" paddingBottom={2}>
				Datos del Titular de Pago
			</Typography>
			<form noValidate>
				<Grid container spacing={2} justifyContent="center">
					<Grid item xs={12} md={6}>
						<Controller
							name="firstName"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Este campo es requerido',
								},
								maxLength: {
									value: PaymentTitularFormFieldLength.FIRSTNAME,
									message:
										'El nombre excede la cantidad de caracteres permitida de ' +
										PaymentTitularFormFieldLength.FIRSTNAME,
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Nombre"
									helperText={checkShowErrorMessage(Boolean(errors.firstName), errors.firstName?.message)}
									error={Boolean(errors.firstName)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							name="lastName"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Este campo es requerido',
								},
								maxLength: {
									value: PaymentTitularFormFieldLength.LASTNAME,
									message:
										'El apellido excede la cantidad de caracteres permitida de ' +
										PaymentTitularFormFieldLength.LASTNAME,
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Apellido"
									helperText={checkShowErrorMessage(Boolean(errors.lastName), errors.lastName?.message)}
									error={Boolean(errors.lastName)}
									{...field}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Controller
							name="documentType"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Este campo es requerido',
								},
							}}
							render={({ field }) => (
								<FormControl sx={{ width: '100%' }} required={true}>
									<InputLabel id="document-type-label">Tipo de Documento</InputLabel>
									<Select label="Tipo de Documento" {...field}>
										{payment?.orderDataToClient?.billingDocumentTypeToShow.map((document) => {
											return (
												<MenuItem key={document.id} value={document.id}>
													{document.name}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							name="document"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Este campo es requerido',
								},
								maxLength: {
									value: PaymentTitularFormFieldLength.DOCUMENT,
									message:
										'El documento excede la cantidad de caracteres permitida de ' +
										PaymentTitularFormFieldLength.DOCUMENT,
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Documento de identidad"
									helperText={checkShowErrorMessage(Boolean(errors.document), errors.document?.message)}
									error={Boolean(errors.document)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							name="email"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Este campo es requerido',
								},
								pattern: {
									value: emailPattern,
									message: 'Ingrese un email válido',
								},
								maxLength: {
									value: FormFieldsMaxLength.EMAIL,
									message: 'El email excede el largo máximo permitido de: ' + FormFieldsMaxLength.EMAIL + ' caracteres',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="E-Mail"
									helperText={checkShowErrorMessage(Boolean(errors.email), errors.email?.message)}
									error={Boolean(errors.email)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							name="phone"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Este campo es requerido',
								},
								maxLength: {
									value: FormFieldsMaxLength.PHONE,
									message: `El teléfono excede la cantidad de caracteres permitida de ${FormFieldsMaxLength.PHONE}`,
								},
							}}
							render={({ field }) => (
								<>
									<PhoneInput
										{...field}
										country={countryCode.toLowerCase()}
										enableSearch={true}
										inputProps={{
											required: true,
										}}
										specialLabel="Teléfono *"
										inputStyle={{
											width: '100%',
										}}
										onChange={(value) => field.onChange(value)}
									/>
									{errors.phone && <FormHelperText error>{errors.phone.message}</FormHelperText>}
								</>
							)}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Controller
							name="city"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								maxLength: {
									value: PaymentTitularFormFieldLength.CITY,
									message:
										'El Nombre de la Ciudad excede el largo máximo permitido de: ' +
										PaymentTitularFormFieldLength.CITY +
										' caracteres',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Ciudad"
									helperText={checkShowErrorMessage(Boolean(errors.city), errors.city?.message)}
									error={Boolean(errors.city)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							name="state"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								maxLength: {
									value: PaymentTitularFormFieldLength.STATE,
									message:
										'El Nombre del Estado excede el largo máximo permitido de: ' +
										PaymentTitularFormFieldLength.STATE +
										' caracteres',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Estado"
									helperText={checkShowErrorMessage(Boolean(errors.state), errors.state?.message)}
									error={Boolean(errors.state)}
									{...field}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12} md={9}>
						<Controller
							name="address"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								maxLength: {
									value: PaymentTitularFormFieldLength.ADDRESS,
									message:
										'La Dirección excede el largo máximo permitido de: ' +
										PaymentTitularFormFieldLength.ADDRESS +
										' caracteres',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Direccion"
									helperText={checkShowErrorMessage(Boolean(errors.address), errors.address?.message)}
									error={Boolean(errors.address)}
									{...field}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12} md={3}>
						<Controller
							name="zipCode"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								maxLength: {
									value: PaymentTitularFormFieldLength.ZIPCODE,
									message:
										'El código postal excede el largo máximo permitido de: ' +
										PaymentTitularFormFieldLength.ZIPCODE +
										' caracteres',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Código postal"
									helperText={checkShowErrorMessage(Boolean(errors.zipCode), errors.zipCode?.message)}
									error={Boolean(errors.zipCode)}
									{...field}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12}>
						<Button
							variant="contained"
							sx={{ height: '100%', width: '100%' }}
							onClick={() => onSubmit(getValues())}
							disabled={!isValid || loading}
							color="success"
						>
							Procesar
						</Button>
					</Grid>
				</Grid>
			</form>
			<Snackbar open={alert.show} autoHideDuration={2500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
