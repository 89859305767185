import React from 'react';
import { useParams } from 'react-router-dom';
import { DLocalCheckConfirmation as Component } from 'features/payment/components/DLocalCheckConfirmation';
import { Container } from '@mui/material';
import { Header } from '../../../features/quotation/components/Header';

export const DLocalCheckConfirmation = (): JSX.Element => {
	const { orderId } = useParams<{ orderId: string }>();

	return (
		<>
			<Header />
			<Container component="main">
				<Component orderId={orderId} />
			</Container>
		</>
	);
};
