import React from 'react';
import { Box, Card, Container, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import StoreIcon from '@mui/icons-material/Store';

import visaLogo from '../../../../assets/visaLogo.png';
import masterLogo from '../../../../assets/masterLogo.png';
import ocaLogo from '../../../../assets/ocaLogo.png';
import amexLogo from '../../../../assets/amexLogo.png';
import { cuotasUruguay } from '../../../../constants';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			paddingTop: theme.spacing(2),
			maxWidth: '100%',
		},
		cardsItem: {
			padding: '5px',
			display: 'inline-block',
			textAlign: 'center',
			verticalAlign: 'middle',
		},
		cardBoxHolder: {
			marginTop: 20,
		},
		cardBox: {
			width: 260,
		},
		consulta: {
			marginTop: 30,
		},
		background: {
			backgroundColor: theme.palette.primary.main,
			borderRadius: '8px',
		},
	}),
);

const MevueloUYPaymentMethods = (): JSX.Element => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Container component="main" maxWidth="md">
				<Typography
					component="h1"
					variant="h6"
					sx={{
						marginBottom: '8px',
						width: '100%',
						backgroundColor: '#31194B',
						paddingLeft: '16px',
						paddingRight: '16px',
						paddingTop: '8px',
						paddingBottom: '8px',
						borderRadius: '4px',
						fontSize: '1rem',
						color: 'white',
					}}
				>
					Nuestras formas de pago:
				</Typography>
				<Card sx={{ padding: '16px', marginBottom: '16px' }}>
					<Box display="flex" justifyContent="space-around" alignItems="center">
						<Box display="flex" flexDirection="column" alignItems="center">
							<AccountBalanceWalletIcon color="secondary" fontSize="large" />
							<Typography variant="subtitle2" align="center">
								Transferencia bancaria
							</Typography>
						</Box>
						<Box display="flex" flexDirection="column" alignItems="center">
							<CreditCardIcon color="secondary" fontSize="large" />
							<Typography variant="subtitle2" align="center">
								Tarjetas de crédito
							</Typography>
						</Box>
						<Box display="flex" flexDirection="column" alignItems="center">
							<StoreIcon color="secondary" fontSize="large" />
							<Typography variant="subtitle2" align="center">
								Pago en el local
							</Typography>
						</Box>
						<Box display="flex" flexDirection="column" alignItems="center">
							<AccountBalanceIcon color="secondary" fontSize="large" />
							<Typography variant="subtitle2" align="center">
								Redes de cobranza
							</Typography>
						</Box>
					</Box>
					<Box className={classes.cardBoxHolder} display="flex" justifyContent="center" alignItems="baseline">
						<Box display="flex" flexDirection="column" alignItems="center" className={classes.cardBox}>
							<Box className={classes.cardsItem}>
								<img src={visaLogo} alt="visa" />
							</Box>
							<Typography variant="subtitle2" align="center">
								Hasta {cuotasUruguay.visa} cuotas
							</Typography>
						</Box>

						<Box display="flex" flexDirection="column" alignItems="center" className={classes.cardBox}>
							<Box className={classes.cardsItem}>
								<img src={amexLogo} alt="american-express" />
							</Box>
							<Typography variant="subtitle2" align="center">
								Hasta {cuotasUruguay.amex} cuotas
							</Typography>
						</Box>

						<Box display="flex" flexDirection="column" alignItems="center" className={classes.cardBox}>
							<Box className={classes.cardsItem}>
								<img src={masterLogo} alt="master-card" />
							</Box>
							<Typography variant="subtitle2" align="center">
								Hasta {cuotasUruguay.master} cuotas
							</Typography>
						</Box>

						<Box display="flex" flexDirection="column" alignItems="center" className={classes.cardBox}>
							<Box className={classes.cardsItem}>
								<img src={ocaLogo} alt="oca-card" />
							</Box>
							<Typography variant="subtitle2" align="center">
								Hasta {cuotasUruguay.oca} cuotas
							</Typography>
						</Box>
					</Box>
					<Typography className={classes.consulta} variant="subtitle1" align="center">
						Consultá por otras tarjetas y cuotificación{' '}
					</Typography>
				</Card>
			</Container>
		</div>
	);
};

export default MevueloUYPaymentMethods;
