import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Layout } from 'features/common/components/Layout';
import { PackagePrices } from '../../../features/stats/components/PackageStats/PackagePrices';
import { PackageData } from '../../../features/stats/components/PackageStats/PackageData';
import { PackagesSearch } from '../../../features/stats/components/PackageStats/PackagesSearch';
import { PackageCompositeDetailByTravelDate } from '../../../features/stats/components/PackageStats/PackageCompositeDetailByTravelDate';
import { INomenclator, IPackagePriceByTravelDate } from '../../../features/stats/types';
import { useLocation } from 'react-router-dom';
import { retrievePackageDetail } from '../../../features/stats/services';

const useQuery = () => new URLSearchParams(useLocation().search);

// Función auxiliar para transformar la respuesta del servicio en un objeto INomenclator.
const fetchPackageById = async (id: number): Promise<INomenclator> => {
	const response = await retrievePackageDetail(id);
	const data = response.data;
	return {
		id,
		name: data.name,
		// Puedes agregar otros campos si lo requieres.
	};
};

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
}));

function PackageStats(): JSX.Element {
	const classes = useStyles();
	const [packageSelected, setPackageSelected] = useState<INomenclator | null>(null);
	const [packagePriceByTravelDate, setPackagePriceByPackagePriceByTravelDate] =
		useState<IPackagePriceByTravelDate | null>(null);
	const query = useQuery();

	const handleSelectPackage = (pkg: INomenclator | null) => setPackageSelected(pkg);

	// Al montar el componente, se busca en la URL el parámetro "packageId"
	useEffect(() => {
		const packageIdFromUrl = query.get('packageId');
		if (packageIdFromUrl && !packageSelected) {
			const id = Number(packageIdFromUrl);
			fetchPackageById(id)
				.then((pkg) => {
					setPackageSelected(pkg);
				})
				.catch((error) => {
					console.error('Error al obtener el paquete por id:', error);
				});
		}
	}, [query, packageSelected]);

	return (
		<Layout>
			<div>
				<Container component="main">
					<Typography variant="h5" className={classes.headerTitle}>
						Estadística de precios de paquetes
					</Typography>
					<PackagesSearch handleSelectPackage={handleSelectPackage} />
					{packageSelected != null && <PackageData packageId={packageSelected.id} />}
					{packageSelected != null && (
						<PackagePrices
							packageId={packageSelected.id}
							handleSelectTravelDate={setPackagePriceByPackagePriceByTravelDate}
						/>
					)}
					{packageSelected != null && packagePriceByTravelDate && (
						<PackageCompositeDetailByTravelDate
							packageSelected={packageSelected}
							packagePriceByTravelDate={packagePriceByTravelDate}
						/>
					)}
				</Container>
			</div>
		</Layout>
	);
}

export default PackageStats;
