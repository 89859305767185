import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	Alert,
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import {
	ICheckOutAtcResponse,
	IPaymentTitularAtc,
	ITransactionInGatewayAtcRequest,
	PaymentTitularFormFieldLength,
} from '../../types';
import { checkShowErrorMessage } from '../../../common/helpers';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import { gatewayDistinctToDLocalDocumentTypeList } from 'features/transactions/types';
import { createAtcTransaction } from 'features/payment/services';
import { FormFieldsMaxLength, ICountryFE, ShowAlertState } from 'features/common/types';
import { emailPattern, requiredWithMessage } from 'features/common/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getCountries } from '../../../common/services';
import { CIBERSOURCE_URL } from '../../../../constants/constants';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
}));

export const AtcClientTransaction = (): JSX.Element | null => {
	const { payment } = useSelector((state: RootState) => state);

	if (payment.orderDataToClient == null) {
		return null;
	}

	const defaultTransactionValues = {
		orderId: payment.orderDataToClient.orderId,
		firstName: '',
		lastName: '',
		document: '',
		documentType: '',
		email: '',
		phone: '',
		countryCode: '',
		city: '',
		address: '',
		fee: 0,
	};
	const classes = useStyles();
	const {
		control,
		formState: { errors, isValid },
		handleSubmit,
	} = useForm({
		mode: 'all',
		defaultValues: { ...defaultTransactionValues, orderId: payment.orderDataToClient.orderId },
	});
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [countries, setCountries] = useState<ICountryFE[]>([]);
	const [transactionResponse, setTransactionResponse] = useState<ICheckOutAtcResponse | null>(null);

	const formAtcRef = useCallback((form) => {
		if (form !== null && form.submit && typeof form.submit.tagName == 'string' && form.submit.click) {
			form.submit.click();
		} else {
			setAlert({
				show: true,
				severity: 'error',
				message: 'No se pudo iniciar el formulario de pago',
			});
		}
	}, []);

	const loadCountries = async () => {
		try {
			setLoading(true);
			const countries = (await getCountries()).data;
			setCountries([...countries]);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar los paises'),
			});
		}
	};

	const onSubmit = async (data: any) => {
		try {
			setLoading(true);
			const titularInfoFromForm: IPaymentTitularAtc = (({
				firstName,
				lastName,
				email,
				phone,
				documentType,
				document,
				countryCode,
				city,
				address,
			}) => ({
				firstName,
				lastName,
				email,
				phone,
				documentType,
				document,
				countryCode,
				city,
				address,
			}))(data);
			titularInfoFromForm.phone == '' && delete titularInfoFromForm.phone;
			const dataToSend: ITransactionInGatewayAtcRequest = {
				paymentTitularAtc: titularInfoFromForm,
				fee: data.fee,
				orderId: payment.orderDataToClient?.orderId || '',
			};
			const response = (await createAtcTransaction(dataToSend)).data;
			setTransactionResponse(response);
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al iniciar la transacción con la pasarela de pago'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		loadCountries();
	}, []);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{!transactionResponse && (
				<>
					<Typography variant="h6">Orden de pago por:</Typography>
					<Typography variant="h6">
						{payment.orderDataToClient?.coinCode} {payment.orderDataToClient?.amount}
					</Typography>
					<Typography variant="h6" paddingBottom={2}>
						Datos del Titular de Pago
					</Typography>
					<form noValidate onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={2} justifyContent="center">
							<Grid item xs={12} md={6}>
								<Controller
									name="firstName"
									control={control}
									rules={{
										required: requiredWithMessage,
										maxLength: {
											value: PaymentTitularFormFieldLength.FIRSTNAME,
											message:
												'El nombre excede la cantidad de caracteres permitida de ' +
												PaymentTitularFormFieldLength.FIRSTNAME,
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											label="Nombre"
											helperText={checkShowErrorMessage(Boolean(errors.firstName), errors.firstName?.message)}
											error={Boolean(errors.firstName)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Controller
									name="lastName"
									control={control}
									rules={{
										required: requiredWithMessage,
										maxLength: {
											value: PaymentTitularFormFieldLength.LASTNAME,
											message:
												'El apellido excede la cantidad de caracteres permitida de ' +
												PaymentTitularFormFieldLength.LASTNAME,
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											label="Apellido"
											helperText={checkShowErrorMessage(Boolean(errors.lastName), errors.lastName?.message)}
											error={Boolean(errors.lastName)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Controller
									name="email"
									control={control}
									rules={{
										required: requiredWithMessage,
										pattern: {
											value: emailPattern,
											message: 'Ingrese un email válido',
										},
										maxLength: {
											value: FormFieldsMaxLength.EMAIL,
											message:
												'El email excede el largo máximo permitido de: ' + FormFieldsMaxLength.EMAIL + ' caracteres',
										},
									}}
									render={({ field }) => (
										<TextField
											required
											variant="outlined"
											fullWidth
											label="E-Mail"
											helperText={checkShowErrorMessage(Boolean(errors.email), errors.email?.message)}
											error={Boolean(errors.email)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Controller
									name="phone"
									control={control}
									rules={{
										maxLength: {
											value: FormFieldsMaxLength.PHONE,
											message: 'El teléfono excede la cantidad de caracteres permitida de ' + FormFieldsMaxLength.PHONE,
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											fullWidth
											type="tel"
											label="Teléfono"
											helperText={checkShowErrorMessage(Boolean(errors.phone), errors.phone?.message)}
											error={Boolean(errors.phone)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6} md={2}>
								<Controller
									name="fee"
									control={control}
									rules={{
										required: requiredWithMessage,
									}}
									render={({ field }) => (
										<FormControl required sx={{ width: '100%' }}>
											<InputLabel id="fee-label">Cuotas</InputLabel>
											<Select label="Cuotas" {...field}>
												{payment.orderDataToClient?.feeAllow?.map((fee) => {
													return (
														<MenuItem key={fee} value={fee}>
															{fee}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									)}
								/>
							</Grid>
							<Grid item xs={6} md={5}>
								<Controller
									name="documentType"
									control={control}
									rules={{
										required: requiredWithMessage,
									}}
									render={({ field }) => (
										<FormControl required sx={{ width: '100%' }}>
											<InputLabel id="document-type-label">Tipo de Documento</InputLabel>
											<Select label="Tipo de Documento" {...field}>
												{gatewayDistinctToDLocalDocumentTypeList?.map((document) => {
													return (
														<MenuItem key={document.id} value={document.id}>
															{document.name}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={5}>
								<Controller
									name="document"
									control={control}
									rules={{
										required: requiredWithMessage,
										maxLength: {
											value: PaymentTitularFormFieldLength.DOCUMENT,
											message:
												'El documento excede la cantidad de caracteres permitida de ' +
												PaymentTitularFormFieldLength.DOCUMENT,
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											label="Documento de identidad"
											helperText={checkShowErrorMessage(Boolean(errors.document), errors.document?.message)}
											error={Boolean(errors.document)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={5}>
								<Controller
									control={control}
									name="countryCode"
									rules={{ required: true }}
									render={({ field: { onChange, value } }) => (
										<Autocomplete
											onChange={(event, item) => {
												onChange(item?.code || '');
											}}
											value={countries.find((x) => x.code === value)}
											options={countries}
											getOptionLabel={(item) => (item.name ? item.name : '')}
											renderInput={(params) => (
												<TextField
													{...params}
													label="País"
													variant="outlined"
													error={!!errors.countryCode}
													helperText={checkShowErrorMessage(Boolean(errors.countryCode), errors.countryCode?.message)}
													required
												/>
											)}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={7}>
								<Controller
									name="city"
									control={control}
									rules={{
										required: requiredWithMessage,
										maxLength: {
											value: PaymentTitularFormFieldLength.CITY,
											message:
												'El Nombre de la Ciudad excede el largo máximo permitido de: ' +
												PaymentTitularFormFieldLength.CITY +
												' caracteres',
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											label="Ciudad"
											helperText={checkShowErrorMessage(Boolean(errors.city), errors.city?.message)}
											error={Boolean(errors.city)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Controller
									name="address"
									control={control}
									rules={{
										required: requiredWithMessage,
										maxLength: {
											value: PaymentTitularFormFieldLength.CITY,
											message:
												'El Nombre de la Ciudad excede el largo máximo permitido de: ' +
												PaymentTitularFormFieldLength.CITY +
												' caracteres',
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											label="Dirección"
											helperText={checkShowErrorMessage(Boolean(errors.address), errors.address?.message)}
											error={Boolean(errors.address)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button
									variant="contained"
									sx={{ height: '100%', width: '100%' }}
									type="submit"
									disabled={!isValid}
									color="success"
								>
									Procesar
								</Button>
							</Grid>
						</Grid>
					</form>
				</>
			)}
			{transactionResponse && (
				<form ref={formAtcRef} noValidate id="payment_confirmation" action={CIBERSOURCE_URL} method="post">
					{transactionResponse.fieldsToSends.map((field) => {
						return <input type="hidden" key={field.name} id={field.name} name={field.name} value={field.value}></input>;
					})}
					<input type="submit" id="submit" value="Confirm" style={{ display: 'none' }} />
				</form>
			)}

			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
