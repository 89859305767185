import { ISort } from '../common/types';
import { AxiosResponse } from 'axios';
import { formatSortingFields } from '../common/helpers';
import { ApiSaleOrderService } from '../../services';
import { IPassengerDetail, IPassengerPage } from './types';

export const getPassengersList = (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
	search?: string,
): Promise<AxiosResponse<IPassengerPage>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiSaleOrderService.get('/passengers', {
		params: {
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			...(search && { search: search }),
			envelope: true,
		},
	});
};

export const getPassengerDetails = (id: string): Promise<AxiosResponse<IPassengerDetail>> =>
	ApiSaleOrderService.get(`passengers/detail/${id}`);
