import React from 'react';
import {
	Box,
	Divider,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';

import { IPassengerDetail, IPassengerDocument, IPassengerFrequentFlyerNumberToPreview } from '../../types';
import routes from '../../../../constants/routes';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import NetsuiteEntitySyncStatus from '../../../../pages/SalesOrder/Netsuite/components/NetsuiteEntitySyncStatus';
import { getLabelNameForMunicipality, getLabelNameForProvince } from '../../helpers';

interface IPassengerRelatedWithSaleOrderDetailToPreview {
	saleOrderId: string;
	invoiceable: boolean;
}

interface PassengerDetailProps {
	passengerDetail: IPassengerDetail;
}

const PassengerDetail: React.FC<PassengerDetailProps> = (props: PassengerDetailProps) => {
	const {
		keyId,
		isPhysicalPerson,
		name,
		lastName,
		birthDate,
		countryName,
		isSameCountryOfMarket,
		countryCodeOfMarket,
		state,
		province,
		municipality,
		address,
		postalCode,
		phone,
		email,
		taxPayerTypeName,
		taxRegimeName,
		taxResidenceTypeName,
		fiscalResponsibilityName,
		documentTypeName,
		documentNumber,
		documentVerificationNumber,
		seatPreference,
		documents,
		frequentFlyerNumbers,
		saleOrdersRelated,
		syncStatus,
	} = props.passengerDetail;

	// Lista de campos para la columna izquierda
	const infoList = [
		{ label: 'ID', value: keyId },
		{ label: 'Tipo de Persona', value: isPhysicalPerson ? 'Persona física' : 'Persona jurídica' },
		{ label: isPhysicalPerson ? 'Nombres' : 'Razón Social', value: name },
		{ label: isPhysicalPerson ? 'Apellidos' : 'Fantasía', value: lastName },
		{ label: 'Fecha de nacimiento', value: birthDate },
		{ label: 'País', value: countryName },
		{ label: 'Estado', value: isSameCountryOfMarket ? state : undefined },
		{ label: getLabelNameForProvince(countryCodeOfMarket), value: isSameCountryOfMarket ? province : undefined },
		{
			label: getLabelNameForMunicipality(countryCodeOfMarket),
			value: isSameCountryOfMarket ? municipality : undefined,
		},
		{ label: 'Dirección', value: address },
		{ label: 'Código Postal', value: postalCode },
		{ label: 'Teléfono', value: phone },
		{ label: 'Mail', value: email },
		{ label: 'Tipo de contribuyente', value: taxPayerTypeName },
		{ label: 'Régimen Fiscal', value: taxRegimeName },
		{ label: 'Tipo de Residencia Fiscal', value: taxResidenceTypeName },
		{ label: 'Responsabilidad Fiscal', value: fiscalResponsibilityName },
		{ label: 'Tipo de documento', value: documentTypeName },
		{ label: 'Número de documento', value: documentNumber },
		{ label: 'Verification Number', value: documentVerificationNumber },
		{ label: 'Preferencia de asiento', value: seatPreference },
	].filter((item) => item.value !== undefined && item.value !== null && item.value !== '');

	const handleOpenSaleOrder = (saleOrderId: string) => {
		const newUrl = routes.salesOrder.edit.replace(':id', saleOrderId);
		window.open(`${newUrl}?passengerId=${keyId}`, '_blank');
	};

	return (
		<Box p={2}>
			<Grid container spacing={2}>
				{/* Columna izquierda: labels y valores (auto-anchura) */}
				<Grid item xs={12} md="auto">
					{infoList.map((info, index) => (
						<Box key={index} mb={2}>
							<Typography variant="subtitle2">{info.label}</Typography>
							<Typography variant="body1">{info.value}</Typography>
						</Box>
					))}
				</Grid>

				{/* Divisor vertical entre columnas */}
				<Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

				{/* Columna derecha: secciones con tablas (ocupa el resto del espacio) */}
				<Grid item xs={12} md>
					{/* Documentos del viajero */}
					{isPhysicalPerson && (
						<Box mb={2}>
							<Typography variant="h6" gutterBottom>
								Documentos del viajero
							</Typography>
							{documents && documents.length > 0 ? (
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Tipo de documento</TableCell>
											<TableCell>Origen</TableCell>
											<TableCell>Número</TableCell>
											<TableCell>Expiración</TableCell>
											<TableCell>Imagen</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{documents.map((doc: IPassengerDocument) => (
											<TableRow key={doc.keyId}>
												<TableCell>{doc.documentType}</TableCell>
												<TableCell>{doc.country?.name}</TableCell>
												<TableCell>{doc.documentNumber}</TableCell>
												<TableCell>{doc.expirationDate}</TableCell>
												<TableCell>
													{doc.image ? (
														<a
															href={doc.image}
															target="_blank"
															rel="noopener noreferrer"
															title={'Click para ver la imagen en tamaño original'}
														>
															<img
																src={doc.image}
																alt="Documento"
																style={{
																	maxWidth: '80px',
																	maxHeight: '80px',
																	objectFit: 'cover',
																	cursor: 'pointer',
																}}
															/>
														</a>
													) : (
														'Sin imagen'
													)}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							) : (
								<>
									<Typography variant="body2">No existen documentos de viaje para mostrar.</Typography>
									<Divider sx={{ mt: 2 }} />
								</>
							)}
						</Box>
					)}

					{/* Viajero frecuente */}
					{isPhysicalPerson && (
						<Box mb={2}>
							<Typography variant="h6" gutterBottom>
								Viajero frecuente
							</Typography>
							{frequentFlyerNumbers && frequentFlyerNumbers.length > 0 ? (
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Aerolínea</TableCell>
											<TableCell>Número</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{frequentFlyerNumbers.map((ff: IPassengerFrequentFlyerNumberToPreview, idx: number) => (
											<TableRow key={idx}>
												<TableCell>{ff.airlineName}</TableCell>
												<TableCell>{ff.frequentFlyerNumber}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							) : (
								<>
									<Typography variant="body2">No existen datos de viajero frecuente para mostrar.</Typography>

									<Divider sx={{ mt: 2 }} />
								</>
							)}
						</Box>
					)}

					{/* Sale Orders */}
					<Box mb={2}>
						<Typography variant="h6" gutterBottom>
							Sale Orders donde se encuentra relacionado
						</Typography>
						{saleOrdersRelated && saleOrdersRelated.length > 0 ? (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Id de la Sale Order</TableCell>
										<TableCell>Facturable</TableCell>
										<TableCell>Acciones</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{saleOrdersRelated.map((so: IPassengerRelatedWithSaleOrderDetailToPreview, i: number) => (
										<TableRow key={i}>
											<TableCell>{so.saleOrderId}</TableCell>
											<TableCell>{so.invoiceable ? 'SI' : 'NO'}</TableCell>
											<TableCell>
												<IconButton
													onClick={() => handleOpenSaleOrder(so.saleOrderId)}
													title={'Ver Sale Order en una nueva ventana'}
												>
													<OpenInNewIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						) : (
							<Typography variant="body2">NO se encuentra en uso en ninguna sale order.</Typography>
						)}
					</Box>

					{/* Estado de sincronización */}
					<Box display="flex" alignItems="center" justifyContent="flex-end" gap={1} mt={4} width="100%">
						<Typography variant="body2">Estado de sincronización:</Typography>
						<NetsuiteEntitySyncStatus syncStatus={syncStatus} />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default PassengerDetail;
