import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, AlertColor, Backdrop, CircularProgress, Container, Snackbar } from '@mui/material';

import { ErrorType, PageClientError404 } from 'features/common/components/PageClientError404';
import { ClientPaymentTransaction as Component } from 'features/payment/components/ClientPaymentTransaction/index';
import makeStyles from '@mui/styles/makeStyles';
import { getOrderDataByOrderId } from 'features/payment/services';
import { extractErrorMessage, isNotFoundError } from 'features/quotation/helpers';
import { Header } from 'features/quotation/components/Header';
import { ILocalize, OrderTypeEnum } from 'features/common/types';
import { SiemprePagoPaymentTransaction } from 'features/payment/components/SiemprePagoClientTransaction';
import { getClientLocation } from 'features/common/services';
import { BancardClientTransaction } from 'features/payment/components/BancardClientTransaction';
import Footer from '../../../features/quotation/components/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderDataToClient } from 'features/payment/slice';
import { RootState } from '../../../store';
import { AtcClientTransaction } from '../../../features/payment/components/AtcClientTransaction';
import { WompiClientTransaction } from 'features/payment/components/WompiClientTransaction';
import { DLocalClientTransaction } from '../../../features/payment/components/DLocalClientTransaction';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
		marginBottom: theme.spacing(2),
	},
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

function ClientPaymentOrder(): JSX.Element {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [localizationData, setLocalizationData] = useState<ILocalize | null>(null);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [loading, setLoading] = useState<boolean>(false);
	const { payment } = useSelector((state: RootState) => state);
	const { orderId } = useParams<{ orderId: string }>();
	const [showError404, setShowError404] = useState<boolean>(false);

	const getOrderData = async () => {
		try {
			setLoading(true);
			const orderData = (await getOrderDataByOrderId(orderId)).data;
			if (orderData.wasTransacted) {
				dispatch(setOrderDataToClient(null));
				setAlert({
					show: true,
					severity: 'error',
					message: 'El link de pago ya fue usado, por favor solicite uno nuevo al vendedor.',
				});
			} else if (orderData.expired) {
				dispatch(setOrderDataToClient(null));
				setAlert({
					show: true,
					severity: 'error',
					message: 'El link de pago se encuentra expirado, por favor solicite uno nuevo al vendedor.',
				});
			} else {
				dispatch(setOrderDataToClient(orderData));
			}
			setLoading(false);
		} catch (error: any) {
			if (isNotFoundError(error)) {
				setLoading(false);
				setShowError404(true);
			} else {
				setLoading(false);
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al realizar la transacción'),
				});
			}
		}
	};

	const getLocalizationData = async () => {
		try {
			if (
				payment.orderDataToClient &&
				!payment.orderDataToClient.wasTransacted &&
				(payment.orderDataToClient?.orderType === OrderTypeEnum.SIEMPREPAGO ||
					payment.orderDataToClient?.orderType === OrderTypeEnum.ATCREDENLACE ||
					payment.orderDataToClient?.orderType === OrderTypeEnum.DLOCAL)
			) {
				setLoading(true);
				setLocalizationData((await getClientLocation()).data);
				setLoading(false);
			}
		} catch (error: any) {
			setLoading(false);
		}
	};

	useEffect(() => {
		getOrderData();
	}, [orderId]);

	useEffect(() => {
		getLocalizationData();
	}, [payment.orderDataToClient]);

	if (showError404) {
		return <PageClientError404 errorType={ErrorType.CLIENT_PAYMENT_LINK_NOT_FOUND} />;
	}

	return (
		<>
			<Header />
			<Container component="main" maxWidth="md" sx={{ textAlign: 'center' }}>
				<Backdrop className={classes.backdrop} open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<div className={classes.paper}>
					{payment.orderDataToClient?.orderType === OrderTypeEnum.SIMPLE ? (
						<Component />
					) : payment.orderDataToClient?.orderType === OrderTypeEnum.SIEMPREPAGO ? (
						<SiemprePagoPaymentTransaction clientLocation={localizationData} />
					) : payment.orderDataToClient?.orderType === OrderTypeEnum.BANCARD ? (
						<BancardClientTransaction />
					) : payment.orderDataToClient?.orderType === OrderTypeEnum.ATCREDENLACE ? (
						<AtcClientTransaction />
					) : payment.orderDataToClient?.orderType === OrderTypeEnum.WOMPI ? (
						<WompiClientTransaction />
					) : payment.orderDataToClient?.orderType === OrderTypeEnum.DLOCAL ? (
						<DLocalClientTransaction
							clientLocation={localizationData}
							countryCode={payment.orderDataToClient.plCountryCode}
						/>
					) : (
						<></>
					)}
				</div>
				<Snackbar open={alert.show} autoHideDuration={2000} onClose={() => setAlert(defaultAlertState)}>
					<Alert variant="filled" severity={alert.severity}>
						{alert.message}
					</Alert>
				</Snackbar>
			</Container>
			{payment.orderDataToClient && <Footer country={payment.orderDataToClient.plCountryCode} />}
		</>
	);
}

export default ClientPaymentOrder;
