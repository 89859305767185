import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import {
	Autocomplete,
	Box,
	Button,
	FormHelperText,
	Grid,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
} from '@mui/material';

// import PersonIcon from '@mui/icons-material/Person';
// import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';

import airportsOptions from './airports_options.json';

import {
	BaggageEnum,
	ICodeName,
	IFlightService,
	IFlightServiceLeg,
	IFlightServicePassengersByPassengerType,
	ISelectOption,
	IServiceCostVariationLogs,
	PassengerTypeEnum,
	ServiceUnitEnum,
	TypeServiceEnum,
} from 'features/salesOrder/types';
import { ControlledTextField } from 'features/common/components/ControlledTextField';
import { checkShowErrorMessage, nanoidGenerator } from 'features/common/helpers';
import { ControlledSelect } from 'features/common/components/ControlledSelect';
// import { ControlledCheckBox } from 'features/common/components/ControlledCheckBox';
import {
	getCostVariationLogsByService,
	getOIDIssues,
	parseGDSAirRawDataSaleOrder,
	putFlightLeg,
	putServiceForm,
	sendFlightServiceIssueRequest,
} from 'features/salesOrder/services';
import { IValidatingCarrier } from 'features/transactions/types';

import { FlightsTable } from './FlightsTable';
import { AutocompleteProviders } from 'features/common/components/AutocompleteProviders';
import { gdsOptions, noAplicaGenerico } from 'features/salesOrder/constants';
import { ControlledDateTimePicker } from 'features/common/components/ControlledDateTimePicker';
import { useStyles } from 'features/salesOrder/components/SalesOrderServiceForm/styles';
import {
	calculatMarkupPercentaje,
	createNewPassengerGroup,
	filterServiceCostVariationLogs,
} from 'features/salesOrder/components/SalesOrderServiceForm/helpers';
import { ServiceCostVariationLogs } from 'features/salesOrder/components/SalesOrderServiceForm/components/ServiceCostVariationLogs';
import { useParams } from 'react-router-dom';
import { ShowAlertState } from 'features/common/types';
import { ControlledCheckBox } from '../../../../../../features/common/components/ControlledCheckBox';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getRates } from 'features/stats/services';
import { FlightServicePassengerGroup } from './FlightServicePassengerGroup';
import IssueRequestButton from '../../IssueRequestButton';
import IssueDetailButton from '../../IssueDetailButton';
import { extractErrorMessage } from '../../../../../../features/quotation/helpers';

interface OpcionAirport {
	label: string;
	id: string;
}

interface Props {
	index: number;
	passengers: { keyId: string; name: string; lastName: string }[];
	service: IFlightService;
	currencyesList: ICodeName[];
	validatingCarriers: IValidatingCarrier[];
	onUpdateService: () => void;
	onSetAlert: (values: ShowAlertState) => void;
	onSetLoading: (value: boolean) => void;
	isExistingService?: boolean;
}
// const iconMapping = {
// 	Persona: <PersonIcon />,
// 	Grupo: <GroupIcon />,
// };

interface DestinationDetail {
	airportCode: string | null;
	date: string; // Puede ser más preciso con un tipo Date si lo prefieres
	hour: string;
}
export interface FlightLeg {
	flightNumber: string;
	airlineCode: string;
	departure: DestinationDetail;
	arrival: DestinationDetail;
}

const initialFlightLeg = {
	flightNumber: '',
	airlineCode: 'noselection',
	departure: { airportCode: 'noselection', date: '', hour: '' },
	arrival: { airportCode: 'noselection', date: '', hour: '' },
};

const isArrivalBeforeDeparture = (
	departureDate: string,
	departureTime: string,
	arrivalDate: string,
	arrivalTime: string,
): boolean => {
	// Combinar las fechas y horas en un solo string
	const departureDateTimeString = `${departureDate}T${departureTime}:00`;
	const arrivalDateTimeString = `${arrivalDate}T${arrivalTime}:00`;

	// Crear objetos Date a partir de los strings combinados
	const departureDateTime = new Date(departureDateTimeString);
	const arrivalDateTime = new Date(arrivalDateTimeString);

	// Comparar las dos fechas
	return departureDateTime > arrivalDateTime;
};

export const FlightService = ({
	index,
	passengers,
	service,
	// currencyesList,
	validatingCarriers,
	onUpdateService,
	onSetAlert,
	onSetLoading,
	isExistingService,
}: Props): JSX.Element => {
	const classes: any = useStyles();
	const methods = useForm();
	// 	{
	// 	defaultValues: {
	// 		//@ts-ignore
	// 		passengersTickets: [],
	// 	},
	// }
	const {
		control,
		formState: { errors },
		setValue,
		//getValues,
		reset,
		resetField,
		watch,
	} = methods;

	const {
		fields: passengersGroupByPassengerType,
		append,
		replace,
		remove,
	} = useFieldArray<IFlightServicePassengersByPassengerType>({
		// @ts-ignore
		control,
		// @ts-ignore
		name: 'passengersGroupByPassengerType',
	});

	const [flightLeg, setFlightLeg] = useState<FlightLeg>({ ...initialFlightLeg });
	const [initialPassengersGroup, setInitialPassengersGroup] = useState<IFlightServicePassengersByPassengerType[]>(
		service.passengersGroupByPassengerType,
	);

	const { auth } = useSelector((state: RootState) => state);
	const theCountry = auth.country;

	const handleFlightNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFlightLeg({ ...flightLeg, flightNumber: event.target.value });
	};

	const handleAirlineCodeChange = (event: SelectChangeEvent<string>) => {
		setFlightLeg({ ...flightLeg, airlineCode: event.target.value as string });
	};
	const handleDepartureAirportCodeChange = (
		_event: React.SyntheticEvent<Element, Event>,
		newValue: OpcionAirport | null,
	) => {
		// setFlightLeg({ ...flightLeg, airlineCode: event.target.value as string });
		if (newValue) {
			setFlightLeg({
				...flightLeg,
				departure: { ...flightLeg.departure, airportCode: newValue.id },
			});
		}
	};

	const handleDepartureDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const [date, hour] = event.target.value.split('T');
		setFlightLeg({
			...flightLeg,
			departure: { ...flightLeg.departure, date, hour },
		});
	};

	const handleArrivalAirportCodeChange = (
		_event: React.SyntheticEvent<Element, Event>,
		newValue: OpcionAirport | null,
	) => {
		// setFlightLeg({ ...flightLeg, airlineCode: event.target.value as string });
		if (newValue) {
			setFlightLeg({
				...flightLeg,
				arrival: { ...flightLeg.arrival, airportCode: newValue.id },
			});
		}
	};

	const handleArrivalDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const [date, hour] = event.target.value.split('T');
		setFlightLeg({
			...flightLeg,
			arrival: { ...flightLeg.arrival, date, hour },
		});
	};

	const [serviceCostVariationLogs, setServiceCostVariationLogs] = useState<IServiceCostVariationLogs[]>([]);

	const { id: saleOrderId } = useParams<{ id: string }>();
	const [oidIssuesList, setOidIssuesList] = useState<ISelectOption[]>([{ ...noAplicaGenerico }]);
	const [gdsRawData, setGdsRawData] = useState('');
	const [flightLegs, setFlightLegs] = useState<IFlightServiceLeg[]>([]);
	const idService = service.keyId;

	const filteredArray = filterServiceCostVariationLogs(serviceCostVariationLogs, idService);

	const unit = watch('unit' as `${string}.${string | number}`);
	const withTst = watch('withTst');

	const handleAddLeg = async () => {
		if (flightLeg.airlineCode === 'noselection') {
			alert('Tiene que elegir una aerolinea');
			return false;
		}

		if (flightLeg.departure.airportCode === 'noselection') {
			alert('Tiene que elegir un aeropuerto de salida');
			return false;
		}

		if (flightLeg.arrival.airportCode === 'noselection') {
			alert('Tiene que elegir un aeropuerto de llegada');
			return false;
		}

		if (flightLeg.flightNumber === '') {
			alert('Tienes que ingresar un numero de vuelo');
			return false;
		}

		if (
			isArrivalBeforeDeparture(
				flightLeg.departure.date,
				flightLeg.departure.hour,
				flightLeg.arrival.date,
				flightLeg.arrival.hour,
			)
		) {
			alert('La fecha de llegada no puede ser anterior a la fecha de salida');
			return false;
		}

		const data = { ...flightLeg, keyId: nanoidGenerator() };
		try {
			await putFlightLeg(saleOrderId, idService, data);
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Pierna Agregada',
			});
			setFlightLeg({
				...flightLeg,
				flightNumber: '',
				departure: { ...flightLeg.departure, airportCode: null },
				arrival: { ...flightLeg.arrival, airportCode: null },
			});
		} catch (error) {
			// @ts-ignore
			alert(error.response.data.message);
		} finally {
			setTimeout(() => {
				onSetLoading(false);
				onUpdateService();
			}, 500);
		}
	};

	const handleGdsRawDataChange = (inputValue: string) => {
		setGdsRawData(inputValue);
	};

	const handleAddTST = async () => {
		try {
			onSetLoading(true);
			const response = await parseGDSAirRawDataSaleOrder(gdsRawData);
			const result = response.data;

			if (Array.isArray(result) && result?.length > 0) {
				setFlightLegs(result);

				onSetAlert({
					show: true,
					severity: 'success',
					message: 'TST agregado',
				});
			}
		} catch (error) {
			// @ts-ignore
			alert(error.response.data.message);
		} finally {
			onSetLoading(false);
		}
	};

	const loadOidIssues = useCallback(async () => {
		try {
			onSetLoading(true);
			const oidOptionsResponse = (await getOIDIssues()).data;
			setOidIssuesList([{ ...noAplicaGenerico }, ...oidOptionsResponse]);
		} catch (error) {
			// @ts-ignore
			alert(error);
		} finally {
			onSetLoading(false);
		}
	}, [setOidIssuesList]);

	const loadCostLog = useCallback(async () => {
		const result = await getCostVariationLogsByService(saleOrderId, idService);
		setServiceCostVariationLogs(result.data);
	}, [setServiceCostVariationLogs]);

	useEffect(() => {
		reset();
		loadOidIssues();
		loadCostLog();
	}, [loadOidIssues, loadCostLog, service]);

	const handleUpdateService = async (data: IFlightService) => {
		try {
			onSetLoading(true);

			const flightService = data as IFlightService;

			// @ts-ignore
			flightService.markupPercentage = Number(flightService.markupPercentage);
			if (flightService.gds === 'n/a') {
				delete flightService.gds;
			}
			if (flightService.oidIssueId === 'n/a') {
				delete flightService.oidIssueId;
			}

			if (flightService.tariffProvider === 'n/a') {
				delete flightService.tariffProvider;
			} else if (typeof flightService.tariffProvider === 'string') {
				// @ts-ignore
				flightService.tariffProviderId = flightService.tariffProvider;
				delete flightService.tariffProvider;
			}
			// @ts-ignore
			if (flightService.tariffProviderId === 'n/a') {
				// @ts-ignore
				delete flightService.tariffProviderId;
			}

			if (flightService.flightLegs) {
				for (let flightLegIndex = 0; flightLegIndex < flightService.flightLegs.length; flightLegIndex++) {
					if (Boolean(flightService.flightLegs[flightLegIndex].keyId) === false) {
						flightService.flightLegs[flightLegIndex].keyId = nanoidGenerator();
					}
				}
			}

			if (flightLegs) {
				flightService.flightLegs = flightLegs.map((flightLeg) => ({
					...flightLeg,
					keyId: nanoidGenerator(),
				}));
			}

			flightService.serviceType = TypeServiceEnum.Flight;

			if (theCountry !== 'CO') {
				// @ts-ignore
				delete flightService.currencyRate;
				// @ts-ignore
				delete flightService.totalSaleAmountInLocalCurrency;
			}

			if (flightService.withTst === 'manual') {
				flightService.flightLegs = [];
				// @ts-ignore
				flightService.withTst = false;
				// @ts-ignore
				delete flightService.tst;
			} else {
				// @ts-ignore
				flightService.withTst = true;
			}

			if (Number(flightService.totalSaleAmount) == 0) {
				// @ts-ignore
				delete flightService.markupPercentage;
			} else {
				// @ts-ignore
				flightService.markupPercentage = parseFloat(
					calculatMarkupPercentaje(Number(flightService.totalCost), Number(flightService.totalSaleAmount)),
				);
			}

			flightService.passengersGroupByPassengerType = flightService.passengersGroupByPassengerType.map(
				(passengerGroup) => {
					if (passengerGroup.saleAmount === 0) {
						const group = { ...passengerGroup };
						delete group.markupPercentage;
						return group;
					} else return passengerGroup;
				},
			);

			await putServiceForm({ saleOrderId, serviceId: flightService.keyId, data: flightService });
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Servicio guardado con exito',
			});
		} catch (error) {
			// @ts-ignore
			if (error.response) {
				// @ts-ignore
				alert(error.response.data.message);
			} else {
				console.log('error', error);
			}
		} finally {
			onSetLoading(false);
			onUpdateService();
		}
	};

	useEffect(() => {
		reset();
		remove();
		setFlightLegs([]);

		resetField('limitDate');

		const input = document.querySelector('input[name="limitDate"]');
		if (input) {
			// @ts-ignore
			input.value = '';
		}

		const effectTariffProviderId =
			typeof service.tariffProvider === 'string'
				? service.tariffProvider || 'n/a'
				: service.tariffProvider?.id || 'n/a';
		setValue('keyId' as `${string}.${string | number}`, service.keyId);
		setValue('reference' as `${string}.${string | number}`, service.reference || '');
		// comente esto rene setValue('passengerIds' as `${string}.${string | number}`, service.passengerIds || '');

		setValue('gds' as `${string}.${string | number}`, service.gds ? service.gds : 'n/a');
		setValue('tariffProvider' as `${string}.${string | number}`, effectTariffProviderId);
		setValue('vc' as `${string}.${string | number}`, service.vc || '');
		setValue('carryOn' as `${string}.${string | number}`, service.carryOn || false);
		setValue('currency' as `${string}.${string | number}`, service.currency || 'USD');
		setValue(
			'baggageType' as `${string}.${string | number}`,
			service.baggageType ? service.baggageType : BaggageEnum.None,
		);
		setValue('oidIssueId' as `${string}.${string | number}`, service.oidIssueId ? service.oidIssueId : 'n/a');
		// @ts-ignore
		setValue('limitDate', service.limitDate || '');
		setValue(
			'unit' as `${string}.${string | number}`,
			service.unit.length === 0 ? ServiceUnitEnum.Person : service.unit,
		);
		setValue('totalCost' as `${string}.${string | number}`, service.totalCost || 0);
		setValue('totalSaleAmount' as `${string}.${string | number}`, service.totalSaleAmount || 0);
		setValue('tst' as `${string}.${string | number}`, service.tst || '');
		setValue('tqt' as `${string}.${string | number}`, service.tqt || '');
		setValue('voucherNotes' as `${string}.${string | number}`, service.voucherNotes || '');
		setValue('internalNotes' as `${string}.${string | number}`, service.internalNotes || '');
		// @ts-ignore
		setValue('withTst' as `${string}.${string | number}`, service.withTst === true ? 'tst' : 'manual');

		setValue('FXL', service.FXL || false);
		setValue('OPEROK', service.OPEROK || false);

		if (auth.country === 'CO') {
			if (!isExistingService) {
				const fetchRates = async () => {
					const result = await getRates();
					setValue('currencyRate', result.data.ratesList[0].values[0].fee);
				};
				fetchRates();
			} else {
				setValue('currencyRate', service.currencyRate || 1);
			}
			setValue('totalSaleAmountInLocalCurrency', service.totalSaleAmountInLocalCurrency || 1);
		}

		if (service.flightLegs?.length > 0) {
			setFlightLegs(service.flightLegs);
		}
	}, [setValue, index, service, reset, append, remove, isExistingService, auth]);

	useEffect(() => {
		setValue('currency' as `${string}.${string | number}`, 'USD');
		setValue('carryOn' as `${string}.${string | number}`, false);
	}, [setValue]);

	useEffect(() => {
		setInitialPassengersGroup([...service.passengersGroupByPassengerType]);
		// @ts-ignore
		replace(service.passengersGroupByPassengerType);
	}, [service]);

	const handleAddPassengerGroup = (passengerType: PassengerTypeEnum) => {
		const newPassengerGroup = createNewPassengerGroup(passengerType);
		setInitialPassengersGroup((prev) => {
			return [...prev, newPassengerGroup];
		});
		// @ts-ignore
		append(newPassengerGroup);
	};

	const handleRemovePassengerGroup = (index: number) => {
		setInitialPassengersGroup((prev) => {
			const updated = [...prev];
			updated.splice(index, 1);
			return updated;
		});

		remove(index);
	};

	const existBGroup = initialPassengersGroup.some((item) => item.passengerType === PassengerTypeEnum.B);
	const existCGroup = initialPassengersGroup.some((item) => item.passengerType === PassengerTypeEnum.C);
	const existDGroup = initialPassengersGroup.some((item) => item.passengerType === PassengerTypeEnum.D);

	const currencyRateValue = useWatch({
		control,
		name: 'currencyRate',
	});

	const passengersGroupByPassengerTypeValue = useWatch({
		control,
		name: 'passengersGroupByPassengerType',
	});

	const totalCostSum =
		passengersGroupByPassengerTypeValue?.reduce(
			(sum: any, group: { totalCost: any }) => sum + (group.totalCost || 0),
			0,
		) || 0;

	const totalSaleAmountSum =
		passengersGroupByPassengerTypeValue?.reduce(
			(sum: any, group: { totalSaleAmount: any }) => sum + (group.totalSaleAmount || 0),
			0,
		) || 0;

	const totalSaleAmountSumInLocalCurrency =
		passengersGroupByPassengerTypeValue?.reduce(
			(sum: any, group: { totalSaleAmountInLocalCurrency: any }) => sum + (group.totalSaleAmountInLocalCurrency || 0),
			0,
		) || 0;

	setValue('totalCost' as `${string}.${string | number}`, totalCostSum);
	setValue('totalSaleAmount' as `${string}.${string | number}`, totalSaleAmountSum);

	useEffect(() => {
		if (theCountry === 'CO') {
			setValue('totalSaleAmountInLocalCurrency', Number(totalSaleAmountSumInLocalCurrency));
		}
	}, [currencyRateValue, totalSaleAmountSum, totalCostSum]);

	const isButtonIssueRequestEnabled = isExistingService && service.reference && service.reference !== '-';

	const handleSendIssueRequest = async () => {
		try {
			onSetLoading(true);
			await sendFlightServiceIssueRequest(saleOrderId, idService);
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Solicitud de emisión enviada satisfactoriamente',
			});
		} catch (error) {
			onSetAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Error enviando la solicitud de emisión'),
			});
		} finally {
			setTimeout(() => {
				onSetLoading(false);
				onUpdateService();
			}, 500);
		}
	};

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit((data) => {
					handleUpdateService(data as IFlightService);
				})}
			>
				<div>
					<Grid container spacing={2} className={classes.form}>
						<Grid item xs={12} sm={12}>
							<Typography variant="h6">Datos de la reserva</Typography>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Referencia</Typography>
							<ControlledTextField
								name={'reference'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								helperText={checkShowErrorMessage(Boolean(errors.reference), errors.reference?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>GDS</Typography>
							<ControlledSelect
								name={'gds'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={gdsOptions}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Proveedor de tarifas</Typography>
							<AutocompleteProviders
								allowNotApplicable
								name={'tariffProvider'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Validating carrier</Typography>
							<Controller
								control={control}
								// @ts-ignore
								name={'vc'}
								rules={{ required: 'Este campo es requerido' }}
								render={({ field }) => (
									<>
										<Select
											className={classes.selected}
											{...field}
											size={'small'}
											value={field.value ? field.value.code : ''}
											onChange={(event) => {
												const selectedValue = event.target.value;
												const selectedItem = validatingCarriers?.find((item) => item.code === selectedValue);
												field.onChange(selectedItem);
											}}
										>
											{validatingCarriers?.map((item) => (
												<MenuItem key={item.code} value={item.code}>
													{item.name}
												</MenuItem>
											))}
										</Select>
										{errors['vc'] && <FormHelperText sx={{ color: 'red' }}>{errors['vc']?.message}</FormHelperText>}
									</>
								)}
							/>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>OID de emision</Typography>
							<ControlledSelect
								name={'oidIssueId'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={oidIssuesList?.map((item) => ({ id: item?.id?.toString(), name: item?.name }))}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Fecha limite</Typography>
							<ControlledDateTimePicker
								name={'limitDate'}
								rules={{ required: 'Este campo es requerido' }}
								required={true}
							/>
						</Grid>

						<Grid item xs={12}>
							{passengersGroupByPassengerType.map((_passengerGroup, passengerGroupByPassengerTypeIndex) => (
								<React.Fragment
									key={`passenger-group-${initialPassengersGroup[passengerGroupByPassengerTypeIndex].passengerType}`}
								>
									<FlightServicePassengerGroup
										unit={unit}
										allPassengers={passengers}
										value={initialPassengersGroup[passengerGroupByPassengerTypeIndex]}
										index={passengerGroupByPassengerTypeIndex}
										handleRemovePassengerGroup={handleRemovePassengerGroup}
										isLast={passengerGroupByPassengerTypeIndex === initialPassengersGroup.length - 1}
										existBGroup={existBGroup}
										existCGroup={existCGroup}
										existDGroup={existDGroup}
										handleAddPassengerGroup={handleAddPassengerGroup}
									/>
								</React.Fragment>
							))}
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography variant="h6"> Valores totales de referencia:</Typography>
						</Grid>
						{/* <Grid item xs={12} sm={3}>
							<Typography color={'Grey'}>Unidad por</Typography>
							<ControlledSelect
								name={'unit'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={excludeTypeUnits(typeUnit, ['Habitacion', 'Vehiculo', 'Camarote'])}
								iconTypeTrip={iconMapping}
							/>
						</Grid> */}

						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Moneda</Typography>
							<Typography color={'Grey'}>USD (Dolares Americanos)</Typography>
							{/* <ControlledSelect
								name={'currency'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={currencyesList.map((item) => ({ id: item.code.toString(), name: item.code }))}
							/> */}
						</Grid>

						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Costo Total</Typography>
							<ControlledTextField name={'totalCost'} disabled />
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Venta Total</Typography>
							<ControlledTextField name={'totalSaleAmount'} disabled />
						</Grid>

						{theCountry === 'CO' && (
							<>
								<Grid item xs={12} sm={4} />
								<Grid item xs={12} sm={4}>
									<Typography color={'Grey'}>Tipo de Cambio</Typography>
									<ControlledTextField name={'currencyRate'} disabled={isExistingService} />
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography color={'Grey'}>Total en COP</Typography>
									<ControlledTextField name={'totalSaleAmountInLocalCurrency'} disabled />
								</Grid>
							</>
						)}
						<Grid item xs={12} sm={12}>
							<Typography variant="h6"> Controles de operaciones:</Typography>
						</Grid>
						{
							<Grid item xl={2} xs={3} sm={6}>
								<ControlledCheckBox name={'FXL'} label="FXL" onChange={(isChecked) => setValue('FXL', isChecked)} />
							</Grid>
						}
						{
							<Grid item xl={2} xs={3} sm={6}>
								<ControlledCheckBox
									name={'OPEROK'}
									label="OPEROK"
									onChange={(isChecked) => setValue('OPEROK', isChecked)}
								/>
							</Grid>
						}
						<Grid item xs={12} sm={12}>
							<Typography variant="h6"> Itinerario:</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography color={'Grey'}>Aire con TST o Manual</Typography>
							<ControlledSelect
								name={'withTst'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={[
									{
										id: 'tst',
										name: 'Con Tst',
									},
									{
										id: 'manual',
										name: 'Manual',
									},
								]}
							/>
						</Grid>
						{withTst === 'tst' ? (
							<>
								<Grid item xs={12} sm={12}>
									<Typography color={'Grey'}>TST</Typography>
									<Controller
										// @ts-ignore
										name={'tst'}
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
													const inputValue = e.target.value;
													field.onChange(inputValue);
													handleGdsRawDataChange(inputValue);
												}}
												value={field.value}
												fullWidth
												variant="outlined"
												multiline
												rows={10}
												helperText={checkShowErrorMessage(Boolean(errors.internalNotes), errors.internalNotes?.message)}
											/>
										)}
									/>
								</Grid>
							</>
						) : (
							isExistingService && (
								<Box display="flex" mt={2} pl={2}>
									<TextField
										size="small"
										label="Numero de vuelo"
										value={flightLeg.flightNumber}
										onChange={handleFlightNumberChange}
										style={{ width: 80 }}
									/>

									<Select size="small" value={flightLeg.airlineCode} onChange={handleAirlineCodeChange}>
										<MenuItem key="noselection" value="noselection">
											Aerolinea
										</MenuItem>
										{validatingCarriers.map((option) => (
											<MenuItem key={option.code} value={option.code}>
												{option.name}
											</MenuItem>
										))}
									</Select>

									<Autocomplete
										value={
											flightLeg.departure.airportCode === null
												? null
												: airportsOptions.find((opt) => opt.id === flightLeg.departure.airportCode)
										}
										onChange={handleDepartureAirportCodeChange}
										options={airportsOptions}
										getOptionLabel={(option: OpcionAirport) => option.label}
										renderInput={(params) => (
											<TextField {...params} size="small" label="Salida de" variant="outlined" />
										)}
										style={{ width: 280 }}
									/>
									<TextField
										size="small"
										label="Fecha y Hora de Salida"
										type="datetime-local"
										InputLabelProps={{ shrink: true }}
										value={`${flightLeg.departure.date}T${flightLeg.departure.hour}`}
										onChange={handleDepartureDateTimeChange}
									/>

									<Autocomplete
										value={
											flightLeg.arrival.airportCode === null
												? null
												: airportsOptions.find((opt) => opt.id === flightLeg.arrival.airportCode)
										}
										onChange={handleArrivalAirportCodeChange}
										options={airportsOptions}
										getOptionLabel={(option: OpcionAirport) => option.label}
										renderInput={(params) => (
											<TextField {...params} size="small" label="Llegada a" variant="outlined" />
										)}
										style={{ width: 280 }}
									/>

									<TextField
										label="Fecha y Hora de Llegada"
										size="small"
										type="datetime-local"
										InputLabelProps={{ shrink: true }}
										value={`${flightLeg.arrival.date}T${flightLeg.arrival.hour}`}
										onChange={handleArrivalDateTimeChange}
									/>
								</Box>
							)
						)}
						{(withTst === 'tst' || (withTst !== 'tst' && isExistingService)) && (
							<Grid item xs={12} sm={12}>
								<Button
									type="button"
									fullWidth
									variant="contained"
									onClick={withTst === 'tst' ? handleAddTST : handleAddLeg}
								>
									Agregar Aire
								</Button>
							</Grid>
						)}
						{flightLegs && (
							<FlightsTable
								flightLegs={flightLegs}
								saleOrderId={saleOrderId}
								serviceId={idService}
								onUpdateService={onUpdateService}
								onSetAlert={onSetAlert}
								onSetLoading={onSetLoading}
								allowDelete={withTst === 'manual'}
							/>
						)}
						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'}>TQT</Typography>
							<Controller
								// @ts-ignore
								name={'tqt'}
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
											const inputValue = e.target.value;
											field.onChange(inputValue);
										}}
										value={field.value}
										fullWidth
										variant="outlined"
										multiline
										rows={10}
										helperText={checkShowErrorMessage(Boolean(errors.internalNotes), errors.internalNotes?.message)}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'}>Notas de voucher</Typography>
							<Controller
								// @ts-ignore
								name={'voucherNotes'}
								control={control}
								defaultValue="Favor de presentar voucher en el check-in"
								render={({ field }) => (
									<TextField
										fullWidth
										variant="outlined"
										multiline
										rows={5}
										{...field}
										helperText={checkShowErrorMessage(Boolean(errors.voucherNotes), errors.voucherNotes?.message)}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'}>
								<LockIcon /> Notas internas
							</Typography>
							<Controller
								// @ts-ignore
								name={'internalNotes'}
								control={control}
								render={({ field }) => (
									<TextField
										fullWidth
										variant="outlined"
										multiline
										rows={5}
										{...field}
										helperText={checkShowErrorMessage(Boolean(errors.internalNotes), errors.internalNotes?.message)}
									/>
								)}
							/>
						</Grid>
						<ServiceCostVariationLogs filteredArray={filteredArray} />
					</Grid>
					{
						<Box display="flex" flexDirection="row-reverse" mt={2} gap={2}>
							{
								// @ts-ignore
								(service.enable || service.enable === undefined) && (
									<Button type="submit" variant="contained" color="success">
										Guardar
									</Button>
								)
							}
							<IssueRequestButton
								showButton={!service.issueRequest}
								disabled={!isButtonIssueRequestEnabled}
								handleClick={handleSendIssueRequest}
							/>
							<IssueDetailButton issueRequestDetail={service.issueRequest} />
						</Box>
					}
				</div>
			</form>
		</FormProvider>
	);
};
