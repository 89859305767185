import {
	FlightAncillaryTypeEnum,
	BaggageEnum,
	GDSEnum,
	IFlightAncillaryTypeOption,
	IGDSOption,
	ISelectOption,
} from './types';

export const noAplicaGenerico: ISelectOption = {
	id: 'n/a',
	name: 'No Aplica',
};

export const gdsOptions: IGDSOption[] = [
	{ ...(noAplicaGenerico as IGDSOption) },
	{
		id: GDSEnum.Amadeus,
		name: 'Amadeus',
	},
	{
		id: GDSEnum.Sabre,
		name: 'Sabre',
	},
	{
		id: GDSEnum.Starlings,
		name: 'Starlings',
	},
	{
		id: GDSEnum.PlatformOfProvider,
		name: 'Plataforma del proveedor',
	},
	// {
	// 	id: GDSEnum.NDS,
	// 	name: 'NDS',
	// },
	// {
	// 	id: GDSEnum.NDX,
	// 	name: 'NDX',
	// },
	{
		id: GDSEnum.NDC,
		name: 'NDC',
	},
];

export const baggageOptions: ISelectOption[] = [
	{
		id: BaggageEnum.None,
		name: 'Sin equipaje',
	},
	{
		id: BaggageEnum.OneWay1x23KG,
		name: 'Solo ida -  1 x 23kg',
	},
	{
		id: BaggageEnum.OneWay2x23KG,
		name: 'Solo ida -  2 x 23kg',
	},
	{
		id: BaggageEnum.OneWay1x32KG,
		name: 'Solo ida -  1 x 32kg',
	},
	{
		id: BaggageEnum.OneWay2x32KG,
		name: 'Solo ida -  2 x 32kg',
	},

	{
		id: BaggageEnum.OnlyReturn1x23KG,
		name: 'Solo vuelta -  1 x 23kg',
	},
	{
		id: BaggageEnum.OnlyReturn2x23KG,
		name: 'Solo vuelta -  2 x 23kg',
	},
	{
		id: BaggageEnum.OnlyReturn1x32KG,
		name: 'Solo vuelta -  1 x 32kg',
	},
	{
		id: BaggageEnum.OnlyReturn2x32KG,
		name: 'Solo vuelta -  2 x 32kg',
	},
	{
		id: BaggageEnum.RoundTrip1x23KG,
		name: 'Ida y vuelta -  1 x 23kg',
	},
	{
		id: BaggageEnum.RoundTrip2x23KG,
		name: 'Ida y vuelta -  2 x 23kg',
	},
	{
		id: BaggageEnum.RoundTrip1x32KG,
		name: 'Ida y vuelta -  1 x 32kg',
	},
	{
		id: BaggageEnum.RoundTrip2x32KG,
		name: 'Ida y vuelta -  2 x 32kg',
	},
];

export const flightAncillaryOptions: IFlightAncillaryTypeOption[] = [
	{
		id: FlightAncillaryTypeEnum.Seat,
		name: 'Asientos',
	},
	{
		id: FlightAncillaryTypeEnum.CabinBaggage,
		name: 'Equipaje en cabina',
	},
	{
		id: FlightAncillaryTypeEnum.CheckedBaggage,
		name: 'Equipaje en bodega',
	},
	{
		id: FlightAncillaryTypeEnum.Pet,
		name: 'Mascota',
	},
	{
		id: FlightAncillaryTypeEnum.InFlightEntertainment,
		name: 'Entretenimiento a bordo',
	},
	{
		id: FlightAncillaryTypeEnum.Meal,
		name: 'Comidas',
	},
	{
		id: FlightAncillaryTypeEnum.SpecialLuggage,
		name: 'Equipajes especiales',
	},
	{
		id: FlightAncillaryTypeEnum.AccompaniedMinor,
		name: 'Menor acompañado',
	},
];
