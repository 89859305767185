import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Box, Button, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';

// import PersonIcon from '@mui/icons-material/Person';
// import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';

import {
	ICodeName,
	IFlightAncillaryService,
	IServiceCostVariationLogs,
	ServiceUnitEnum,
	TypeServiceEnum,
	typeUnit,
} from 'features/salesOrder/types';
import { ControlledTextField } from 'features/common/components/ControlledTextField';
import { checkShowErrorMessage } from 'features/common/helpers';
import { ControlledSelect } from 'features/common/components/ControlledSelect';
import {
	getCostVariationLogsByService,
	putServiceForm,
	sendFlightAncillaryServiceIssueRequest,
} from 'features/salesOrder/services';
import { IValidatingCarrier } from 'features/transactions/types';

import { AutocompleteProviders } from 'features/common/components/AutocompleteProviders';
import { flightAncillaryOptions, gdsOptions } from 'features/salesOrder/constants';
import { ControlledDateTimePicker } from 'features/common/components/ControlledDateTimePicker';
import { useStyles } from 'features/salesOrder/components/SalesOrderServiceForm/styles';
import {
	calcularPrecioFinal,
	calculatMarkupPercentaje,
	excludeTypeUnits,
	filterServiceCostVariationLogs,
	returnPaxName,
} from 'features/salesOrder/components/SalesOrderServiceForm/helpers';
import { ServiceCostVariationLogs } from 'features/salesOrder/components/SalesOrderServiceForm/components/ServiceCostVariationLogs';
import { useParams } from 'react-router-dom';
import { ShowAlertState } from 'features/common/types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getRates } from 'features/stats/services';
import { extractErrorMessage } from '../../../../../../features/quotation/helpers';
import IssueRequestButton from '../../IssueRequestButton';
import IssueDetailButton from '../../IssueDetailButton';
import { ControlledAutoComplete } from '../../../../../../features/common/components/ControlledAutoComplete';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';

interface Props {
	index: number;
	passengers: { keyId: string; name: string; lastName: string }[];
	service: IFlightAncillaryService;
	currencyesList: ICodeName[];
	validatingCarriers: IValidatingCarrier[];
	onUpdateService: () => void;
	onSetAlert: (values: ShowAlertState) => void;
	onSetLoading: (value: boolean) => void;
	isExistingService?: boolean;
}

const iconMapping = {
	Persona: <PersonIcon />,
	Grupo: <GroupIcon />,
};

export const FlightAncillaryService = ({
	index,
	passengers,
	service,
	validatingCarriers,
	onUpdateService,
	onSetAlert,
	onSetLoading,
	isExistingService,
}: Props): JSX.Element => {
	const classes: any = useStyles();
	const methods = useForm();

	const {
		control,
		formState: { errors },
		setValue,
		getValues,
		reset,
		resetField,
		watch,
		register,
	} = methods;

	const {
		fields: paxTickets,
		append,
		remove,
	} = useFieldArray({
		control,
		// @ts-ignore
		name: 'passengersTickets',
	});

	const { auth } = useSelector((state: RootState) => state);
	const theCountry = auth.country;

	const [serviceCostVariationLogs, setServiceCostVariationLogs] = useState<IServiceCostVariationLogs[]>([]);

	const { id: saleOrderId } = useParams<{ id: string }>();
	const idService = service.keyId;

	const filteredArray = filterServiceCostVariationLogs(serviceCostVariationLogs, idService);

	const unit = watch('unit' as `${string}.${string | number}`);
	const saleAmount = watch('saleAmount' as `${string}.${string | number}`);
	const cost = watch('cost' as `${string}.${string | number}`);

	const loadCostLog = useCallback(async () => {
		const result = await getCostVariationLogsByService(saleOrderId, idService);
		setServiceCostVariationLogs(result.data);
	}, [setServiceCostVariationLogs]);

	useEffect(() => {
		reset();
		loadCostLog();
	}, [loadCostLog, service]);

	const handleUpdateService = async (data: IFlightAncillaryService) => {
		try {
			onSetLoading(true);

			const flightAncillaryService = data as IFlightAncillaryService;

			// @ts-ignore
			flightAncillaryService.markupPercentage = Number(flightAncillaryService.markupPercentage);
			if (flightAncillaryService.gds === 'n/a') {
				delete flightAncillaryService.gds;
			}

			if (flightAncillaryService.tariffProvider === 'n/a') {
				delete flightAncillaryService.tariffProvider;
			} else if (typeof flightAncillaryService.tariffProvider === 'string') {
				// @ts-ignore
				flightAncillaryService.tariffProviderId = flightAncillaryService.tariffProvider;
				delete flightAncillaryService.tariffProvider;
			}
			// @ts-ignore
			if (flightAncillaryService.tariffProviderId === 'n/a') {
				// @ts-ignore
				delete flightAncillaryService.tariffProviderId;
			}

			flightAncillaryService.serviceType = TypeServiceEnum.FlightAncillary;

			if (theCountry !== 'CO') {
				// @ts-ignore
				delete flightAncillaryService.currencyRate;
				// @ts-ignore
				delete flightAncillaryService.totalSaleAmountInLocalCurrency;
			}

			if (Number(flightAncillaryService.totalSaleAmount) == 0) {
				// @ts-ignore
				delete flightAncillaryService.markupPercentage;
			} else {
				// @ts-ignore
				flightAncillaryService.markupPercentage = parseFloat(
					calculatMarkupPercentaje(
						Number(flightAncillaryService.totalCost),
						Number(flightAncillaryService.totalSaleAmount),
					),
				);
			}

			await putServiceForm({ saleOrderId, serviceId: flightAncillaryService.keyId, data: flightAncillaryService });
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Servicio guardado con exito',
			});
		} catch (error) {
			// @ts-ignore
			if (error.response) {
				// @ts-ignore
				alert(error.response.data.message);
			} else {
				console.log('error', error);
			}
		} finally {
			onSetLoading(false);
			onUpdateService();
		}
	};

	useEffect(() => {
		reset();
		remove();
		resetField('limitDate');

		const input = document.querySelector('input[name="limitDate"]');
		if (input) {
			// @ts-ignore
			input.value = '';
		}

		const effectTariffProviderId =
			typeof service.tariffProvider === 'string'
				? service.tariffProvider || 'n/a'
				: service.tariffProvider?.id || 'n/a';
		setValue('keyId' as `${string}.${string | number}`, service.keyId);
		setValue('reference' as `${string}.${string | number}`, service.reference || '');
		setValue('flightAncillaryType' as `${string}.${string | number}`, service.flightAncillaryType || '');
		setValue('passengerIds' as `${string}.${string | number}`, service.passengerIds || '');
		setValue('gds' as `${string}.${string | number}`, service.gds ? service.gds : 'n/a');
		setValue('tariffProvider' as `${string}.${string | number}`, effectTariffProviderId);
		setValue('vc' as `${string}.${string | number}`, service.vc || '');
		setValue('cost' as `${string}.${string | number}`, service.cost || 0);
		setValue('keyId' as `${string}.${string | number}`, service.keyId);
		setValue('saleAmount' as `${string}.${string | number}`, service.saleAmount || 0);
		setValue('currency' as `${string}.${string | number}`, service.currency || 'USD');
		setValue('markupPercentage' as `${string}.${string | number}`, service.markupPercentage || 0);
		// @ts-ignore
		setValue('limitDate', service.limitDate || '');
		setValue(
			'unit' as `${string}.${string | number}`,
			service.unit.length === 0 ? ServiceUnitEnum.Person : service.unit,
		);
		setValue('totalCost' as `${string}.${string | number}`, service.totalCost || 0);
		setValue('totalSaleAmount' as `${string}.${string | number}`, service.totalSaleAmount || 0);
		setValue('internalNotes' as `${string}.${string | number}`, service.internalNotes || '');
		service.cost && handleCostChange(Number(service.cost || 0));
		service.saleAmount && handleSaleAmountChange(Number(service.saleAmount || 0));

		if (auth.country === 'CO') {
			if (!isExistingService) {
				const fetchRates = async () => {
					const result = await getRates();
					setValue('currencyRate', result.data.ratesList[0].values[0].fee);
				};
				fetchRates();
			} else {
				setValue('currencyRate', service.currencyRate || 1);
				setValue('saleAmountInLocalCurrency' as `${string}.${string | number}`, service.saleAmountInLocalCurrency || 0);
			}
			setValue('totalSaleAmountInLocalCurrency', service.totalSaleAmountInLocalCurrency || 1);
		}
	}, [setValue, index, service, reset, append, remove, isExistingService, auth]);

	useEffect(() => {
		setValue('currency' as `${string}.${string | number}`, 'USD');
	}, [setValue]);

	const passengersSelected = watch('passengerIds' as `${string}.${string | number}`) || [];

	const calculateTotalCost = useCallback(() => {
		let cost = getValues('cost' as `${string}.${string | number}`) || 0;
		let saleAmount = getValues('saleAmount' as `${string}.${string | number}`) || 0;

		if (unit === 'Person') {
			cost = cost * passengersSelected.length;
			saleAmount =
				saleAmount * passengersSelected.length || (saleAmount ? saleAmount * passengersSelected.length : null);
		}

		setValue('totalCost' as `${string}.${string | number}`, cost);
		setValue('totalSaleAmount' as `${string}.${string | number}`, saleAmount);

		if (theCountry === 'CO') {
			let saleAmountInLocalCurrency = getValues('saleAmountInLocalCurrency' as `${string}.${string | number}`) || 0;
			saleAmountInLocalCurrency =
				unit === 'Person' ? saleAmountInLocalCurrency * passengersSelected.length : saleAmountInLocalCurrency;
			setValue('totalSaleAmountInLocalCurrency', saleAmountInLocalCurrency);
		}
	}, [getValues, passengersSelected, setValue, unit, auth]);

	useEffect(() => {
		const paxTicketsInForm = getValues('passengersTickets' as `${string}.${string | number}`) || [];
		const diff = paxTicketsInForm.length - passengersSelected.length;

		if (diff < 0) {
			for (let i = 0; i < passengersSelected.length; i++) {
				if (Boolean(paxTicketsInForm[i]) === false) {
					const ticket =
						service.passengersTickets?.find((item) => item.passengerId === passengersSelected[i])?.ticket || '-';
					append({ passengerId: passengersSelected[i], ticket: ticket });
				}
			}
		} else if (diff > 0) {
			for (let i = 0; i < paxTicketsInForm.length; i++) {
				// @ts-ignore
				if (passengersSelected.includes(paxTicketsInForm[i].passengerId) === false) {
					remove(i);
				}
			}
		}
		calculateTotalCost();
	}, [passengersSelected]);

	useEffect(() => {
		if (saleAmount !== null) {
			if (unit === 'Person') {
				const newTotalSaleAmount = saleAmount * passengersSelected.length;
				setValue('totalSaleAmount' as `${string}.${string | number}`, newTotalSaleAmount);
			} else {
				setValue('totalSaleAmount' as `${string}.${string | number}`, saleAmount);
			}
		}
	}, [unit, saleAmount, passengersSelected.length]);

	useEffect(() => {
		if (cost !== null) {
			if (unit === 'Person') {
				const newTotalCost = cost * passengersSelected.length;
				setValue('totalCost' as `${string}.${string | number}`, newTotalCost);
			} else {
				setValue('totalCost' as `${string}.${string | number}`, cost);
			}
		}
	}, [unit, cost, passengersSelected.length]);

	const handleCostChange = (newCost: number) => {
		// @ts-ignore
		setValue('cost', newCost, { shouldDirty: true });
		const saleAmount = getValues('saleAmount' as `${string}.${string | number}`) || 0;
		const markupPercentage = calculatMarkupPercentaje(newCost, saleAmount) as string;
		// @ts-ignore
		setValue('markupPercentage', markupPercentage, { shouldDirty: true });
		calculateTotalCost();
	};

	const handleMarkupPercentageChange = (newValue: number) => {
		// @ts-ignore
		setValue('markupPercentage', newValue, { shouldDirty: true });
		const cost = getValues('cost' as `${string}.${string | number}`) || 0;
		const newSaleAmount = calcularPrecioFinal(cost, newValue) as string;
		if (theCountry === 'CO') {
			const rateValue = getValues('currencyRate' as `${string}.${string | number}`) || 1;
			const valorLocalCurrency = Number(newSaleAmount) * rateValue;
			setValue('saleAmountInLocalCurrency', valorLocalCurrency, { shouldDirty: true });
		}
		// @ts-ignore
		setValue('saleAmount', newSaleAmount, { shouldDirty: true });
		calculateTotalCost();
	};

	const handleSaleAmountChange = (newValue: number) => {
		if (theCountry === 'CO') {
			const rateValue = getValues('currencyRate' as `${string}.${string | number}`) || 1;
			const valorLocalCurrency = newValue * rateValue;
			setValue('saleAmountInLocalCurrency', valorLocalCurrency, { shouldDirty: true });
		}
		// @ts-ignore
		setValue('saleAmount', newValue, { shouldDirty: true });
		const cost = getValues('cost' as `${string}.${string | number}`) || 0;

		if (newValue === 0) {
			setValue('markupPercentage', 0);
		} else {
			const newMarkup = calculatMarkupPercentaje(cost, newValue) as string;

			// @ts-ignore
			setValue('markupPercentage', newMarkup, { shouldDirty: true });
		}
		calculateTotalCost();
	};

	const handleSaleAmountChangeInLocalCurrency = (valorEnLocalCurrency: number) => {
		setValue('saleAmountInLocalCurrency', valorEnLocalCurrency, { shouldDirty: true });
		const rateValue = getValues('currencyRate' as `${string}.${string | number}`) || 1;
		const valorEnDolares = valorEnLocalCurrency / rateValue;
		// @ts-ignore
		setValue('saleAmount', valorEnDolares, { shouldDirty: true });
		const cost = getValues('cost' as `${string}.${string | number}`) || 0;

		if (valorEnDolares === 0) {
			setValue('markupPercentage', 0);
		} else {
			const newMarkup = calculatMarkupPercentaje(cost, valorEnDolares) as string;

			// @ts-ignore
			setValue('markupPercentage', newMarkup, { shouldDirty: true });
		}
		calculateTotalCost();
	};

	const isButtonIssueRequestEnabled = isExistingService && service.reference && service.reference !== '-';

	const handleSendIssueRequest = async () => {
		try {
			onSetLoading(true);
			await sendFlightAncillaryServiceIssueRequest(saleOrderId, idService);
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Solicitud de emisión enviada satisfactoriamente',
			});
		} catch (error) {
			onSetAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Error enviando la solicitud de emisión'),
			});
		} finally {
			setTimeout(() => {
				onSetLoading(false);
				onUpdateService();
			}, 500);
		}
	};

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit((data) => {
					handleUpdateService(data as IFlightAncillaryService);
				})}
			>
				<div>
					<Grid container spacing={2} className={classes.form}>
						<Grid item xs={12} sm={12}>
							<Typography variant="h6">Datos de la reserva</Typography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'} mb={1}>
								Pasajeros
							</Typography>
							<ControlledAutoComplete
								name={'passengerIds'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={passengers}
								isMultiple={true}
								helperText={checkShowErrorMessage(Boolean(errors.passengerIds), errors.passengerIds?.message)}
							/>
						</Grid>

						<Grid item xs={12}>
							<Typography color={'Grey'} mb={1}>
								Ticket x Pasajero
							</Typography>
							{paxTickets.map((field, indexTicket) => (
								<Box display="flex" key={field.id} alignItems="end" gap={2} mb={2}>
									<Typography color={'Grey'} width={300}>
										{returnPaxName(
											passengers,
											getValues(
												`passengersTickets.${indexTicket}.passengerId` as `${string}.${string | number}`,
											) as string,
										)}
									</Typography>
									<input
										type="hidden"
										// @ts-ignore
										{...register(`passengersTickets.${indexTicket}.passengerId`)}
									/>
									<ControlledTextField
										name={`passengersTickets.${indexTicket}.ticket`}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
										}}
										helperText={checkShowErrorMessage(
											Boolean(errors.passengersTickets?.[indexTicket]?.ticket),
											errors.passengersTickets?.[indexTicket]?.ticket?.message,
										)}
									/>
								</Box>
							))}
						</Grid>

						<Grid item xs={12} sm={6}>
							<Typography color={'Grey'}>Referencia</Typography>
							<ControlledTextField
								name={'reference'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								helperText={checkShowErrorMessage(Boolean(errors.reference), errors.reference?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color={'Grey'}>GDS</Typography>
							<ControlledSelect
								name={'gds'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={gdsOptions}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color={'Grey'}>Proveedor de tarifas</Typography>
							<AutocompleteProviders
								allowNotApplicable
								name={'tariffProvider'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color={'Grey'}>Validating carrier</Typography>
							<Controller
								control={control}
								// @ts-ignore
								name={'vc'}
								rules={{ required: 'Este campo es requerido' }}
								render={({ field }) => (
									<>
										<Select
											className={classes.selected}
											{...field}
											size={'small'}
											value={field.value ? field.value.code : ''}
											onChange={(event) => {
												const selectedValue = event.target.value;
												const selectedItem = validatingCarriers?.find((item) => item.code === selectedValue);
												field.onChange(selectedItem);
											}}
										>
											{validatingCarriers?.map((item) => (
												<MenuItem key={item.code} value={item.code}>
													{item.name}
												</MenuItem>
											))}
										</Select>
										{errors['vc'] && <FormHelperText sx={{ color: 'red' }}>{errors['vc']?.message}</FormHelperText>}
									</>
								)}
							/>
						</Grid>

						<Grid item xs={12} sm={7}>
							<Typography color={'Grey'}>Tipo de ancilliary</Typography>
							<ControlledSelect
								name={'flightAncillaryType'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								required={true}
								options={flightAncillaryOptions}
							/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<Typography color={'Grey'}>Fecha limite</Typography>
							<ControlledDateTimePicker
								name={'limitDate'}
								rules={{ required: 'Este campo es requerido' }}
								required={true}
							/>
						</Grid>

						<Grid item xs={12} sm={3}>
							<Typography color={'Grey'}>Unidad por</Typography>
							<ControlledSelect
								name={'unit'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={excludeTypeUnits(typeUnit, ['Habitacion', 'Vehiculo', 'Camarote'])}
								iconTypeTrip={iconMapping}
							/>
						</Grid>

						<Grid item xs={12} sm={3}>
							<Typography color={'Grey'}>Costo</Typography>
							<ControlledTextField
								name={'cost'}
								isNumber
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									min: {
										value: 0,
										message: 'El costo debe ser mayor a -1',
									},
								}}
								onChange={(value) => handleCostChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.cost), errors.cost?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Typography color={'Grey'}>Markup %</Typography>
							<ControlledTextField
								name={'markupPercentage'}
								isNumber
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									pattern: {
										value: /^-?\d*\.?\d+$/,
										message: 'El valor debe ser numérico',
									},
								}}
								onChange={(value) => handleMarkupPercentageChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.markupPercentage), errors.markupPercentage?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Typography color={'Grey'}>Venta</Typography>
							<ControlledTextField
								name={'saleAmount'}
								isNumber
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									pattern: {
										value: /^(0|[1-9]\d*)(\.\d+)?$/,
										message: 'El valor debe ser numérico y mayor o igual a 0',
									},
								}}
								onChange={(value) => handleSaleAmountChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.saleAmount), errors.saleAmount?.message)}
							/>
						</Grid>
						{theCountry === 'CO' && (
							<>
								<Grid item xs={12} sm={3} />
								<Grid item xs={12} sm={3} />
								<Grid item xs={12} sm={3} />
								<Grid item xs={12} sm={3}>
									<Typography color={'Grey'}>Venta en COP</Typography>
									<ControlledTextField
										name={'saleAmountInLocalCurrency'}
										isNumber
										onChange={(value) => handleSaleAmountChangeInLocalCurrency(parseFloat(value))}
										helperText={checkShowErrorMessage(Boolean(errors.saleAmount), errors.saleAmount?.message)}
									/>
								</Grid>
							</>
						)}

						<Grid item xs={12} sm={12}>
							<Typography variant="h6"> Valores totales de referencia:</Typography>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Moneda</Typography>
							<Typography color={'Grey'}>USD (Dolares Americanos)</Typography>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Costo Total</Typography>
							<ControlledTextField name={'totalCost'} disabled />
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Venta Total</Typography>
							<ControlledTextField name={'totalSaleAmount'} disabled />
						</Grid>

						{theCountry === 'CO' && (
							<>
								<Grid item xs={12} sm={4} />
								<Grid item xs={12} sm={4}>
									<Typography color={'Grey'}>Tipo de Cambio</Typography>
									<ControlledTextField name={'currencyRate'} disabled={isExistingService} />
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography color={'Grey'}>Total en COP</Typography>
									<ControlledTextField name={'totalSaleAmountInLocalCurrency'} disabled />
								</Grid>
							</>
						)}

						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'}>
								<LockIcon /> Notas internas
							</Typography>
							<Controller
								// @ts-ignore
								name={'internalNotes'}
								control={control}
								render={({ field }) => (
									<TextField
										fullWidth
										variant="outlined"
										multiline
										rows={5}
										{...field}
										helperText={checkShowErrorMessage(Boolean(errors.internalNotes), errors.internalNotes?.message)}
									/>
								)}
							/>
						</Grid>
						<ServiceCostVariationLogs filteredArray={filteredArray} />
					</Grid>
					{
						<Box display="flex" flexDirection="row-reverse" mt={2} gap={2}>
							{
								// @ts-ignore
								(service.enable || service.enable === undefined) && (
									<Button type="submit" variant="contained" color="success">
										Guardar
									</Button>
								)
							}
							<IssueRequestButton
								showButton={!service.issueRequest}
								disabled={!isButtonIssueRequestEnabled}
								handleClick={handleSendIssueRequest}
							/>
							<IssueDetailButton issueRequestDetail={service.issueRequest} />
						</Box>
					}
				</div>
			</form>
		</FormProvider>
	);
};
