import React, { useEffect, useRef, useState } from 'react';
import {
	DestinationSummaryForDestinationMultiple,
	HotelOptionDetailDestinationMultiple,
	QuotationDestinationMultipleSummaryPriceCombination,
	QuotationServiceSimple,
} from '../../types';
import { Box, Button, Container, Grid, IconButton, Paper, SwipeableDrawer, Typography } from '@mui/material';
import { HotelDetailCardMultiDestination } from '../HotelDetailCardMultiDestination';
import { styled } from '@mui/material/styles';
import { Global } from '@emotion/react';
import { RoomPricesMultiDestinationPreviewList } from '../RoomPricesMultiDestinationPreviewList';
import { AddOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import {
	getBoardName,
	getCityNameCleaned,
	GetPAXTotalsResponse,
	hasErrorInDestinationMultipleParams,
	separadorDeMiles,
	hasOnlyOneRoom,
} from '../../helpers';
import useBreakpoints from '../../../common/hooks/useBreakpoints';
import { IncludeMultiDestination } from '../IncludeMultiDestination';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { countryWAPMapping } from 'constants/constants';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import { FlightOption } from './FlightOption';
import { HotelOptionItem } from './HotelOptionItem';

interface Props {
	destinations: DestinationSummaryForDestinationMultiple[];
	priceCombinations: QuotationDestinationMultipleSummaryPriceCombination[];
	paxTotals: GetPAXTotalsResponse;
	manualServices: QuotationServiceSimple[];
	showUtility: boolean;
	markup: number | null;
	totalFlights: number;
	flightIndexSelected: number | null;
	handleFlightSelectionChange?: (index: number | null) => void;
	exchangeRate: number;
	localCurrency: string;
	showLocalCurrency?: boolean;
	quotationId?: number;
}

const Puller = styled(Box)(() => ({
	width: 30,
	height: 6,
	borderRadius: 3,
	backgroundColor: 'gray',
	position: 'absolute',
	top: 8,
	left: 'calc(50% - 15px)',
}));

export interface SelectedHotelByDestination {
	destinationIndex: number;
	destinationCityCode: string;
	hotel: HotelOptionDetailDestinationMultiple;
}

export const MultiSelectHotelsMultiDestination = ({
	destinations,
	priceCombinations,
	paxTotals,
	manualServices,
	showUtility,
	markup,
	totalFlights,
	flightIndexSelected,
	handleFlightSelectionChange,
	exchangeRate,
	localCurrency,
	showLocalCurrency,
	quotationId,
}: Props): JSX.Element => {
	const drawerBleeding = (destinations.length + 1) * 40;

	if (hasErrorInDestinationMultipleParams(priceCombinations, destinations, paxTotals)) {
		return <> </>;
	}
	const isMediumDown = useBreakpoints().mediumDown;

	/**En  priceCombinations se envian todas las combinaciones posibles cuando hay mas de 1 vuelo filtramos las que nos hace falta para mostrar**/

	const validCombinations =
		totalFlights > 1
			? priceCombinations.filter((x) => x.keys[0].flightIndex == flightIndexSelected)
			: priceCombinations;
	const priceCombinationsSorted = validCombinations.sort(function (a, b) {
		return a.summaryPriceForOneAdult - b.summaryPriceForOneAdult;
	});

	const combinationMinPrice = priceCombinationsSorted[0];
	const [selectedCombination, setSelectedCombination] =
		useState<QuotationDestinationMultipleSummaryPriceCombination | null>(combinationMinPrice);
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);
	const drawerRef = useRef<HTMLDivElement | null>(null);
	const pullerRef = useRef<HTMLDivElement | null>(null);
	/**Permite que al cargar por primera vez busque la mejor opción y no la que se le pase por default**/
	useEffect(() => {
		if (totalFlights > 1) {
			const allPriceCombinationsSorted = priceCombinations.sort(function (a, b) {
				return a.summaryPriceForOneAdult - b.summaryPriceForOneAdult;
			});
			const maybeFlightIndex = allPriceCombinationsSorted[0]?.keys[0]?.flightIndex;

			if (maybeFlightIndex != null && handleFlightSelectionChange) {
				handleFlightSelectionChange(maybeFlightIndex);
			}
		}
	}, []);

	const getSelectedHotels = (): SelectedHotelByDestination[] => {
		return combinationMinPrice.keys.map((key) => {
			const destination =
				destinations[
					destinations.findIndex((x, index) => x.cityCode == key.destinationCode && key.destinationIndex == index)
				];
			const hotel =
				destination.hotelOptions[
					destination.hotelOptions.findIndex(
						(x) => x.hotelId == key.hotelId && x.provider == key.hotelProvider && key.boardsKey == x.boardsKey,
					)
				];
			return {
				destinationIndex: key.destinationIndex,
				destinationCityCode: key.destinationCode,
				hotel: hotel,
			};
		});
	};

	useEffect(() => {
		setSelectedCombination(combinationMinPrice);
		setSelectedHotels(getSelectedHotels());
	}, [priceCombinations, flightIndexSelected]);

	const [selectedHotels, setSelectedHotels] = useState<SelectedHotelByDestination[]>(getSelectedHotels());

	const getDestinationIndex = (destinationCityCode: string, destinationIndex: number) =>
		selectedHotels.findIndex(
			(hotel) => hotel.destinationCityCode == destinationCityCode && hotel.destinationIndex == destinationIndex,
		);

	const handleSelectHotel = (
		destinationCityCode: string,
		destinationIndex: number,
		hotel: HotelOptionDetailDestinationMultiple,
	) => {
		const maybeDestinationSelected = getDestinationIndex(destinationCityCode, destinationIndex);
		const selectedHotelsCopy = [...selectedHotels];
		if (
			maybeDestinationSelected > -1 &&
			!isSelectedHotel(hotel.hotelId, destinationCityCode, destinationIndex, hotel.provider, hotel.boardsKey)
		) {
			selectedHotelsCopy.splice(maybeDestinationSelected, 1);
			const selectedNew = [
				...selectedHotelsCopy,
				{
					destinationCityCode,
					destinationIndex,
					hotel: hotel,
				},
			];

			const possiblesCombinations = validCombinations.filter(
				(x) =>
					x.keys.findIndex(
						(k) =>
							k.destinationCode == destinationCityCode &&
							k.destinationIndex == destinationIndex &&
							k.hotelId == hotel.hotelId &&
							k.hotelProvider == hotel.provider,
					) > -1,
			);
			const combinationForHotelsSelection = possiblesCombinations.find((x) =>
				x.keys.every(
					(k) =>
						selectedNew.findIndex(
							(s) =>
								s.destinationCityCode == k.destinationCode &&
								s.hotel.hotelId == k.hotelId &&
								s.hotel.provider == k.hotelProvider &&
								s.hotel.boardsKey === k.boardsKey,
						) > -1,
				),
			);
			setSelectedCombination(combinationForHotelsSelection || null);
			setSelectedHotels(selectedNew);
		}
	};

	const isSelectedHotel = (
		hotelId: number,
		destinationCityCode: string,
		destinationIndex: number,
		provider: string,
		boardsKey: string,
	) => {
		const selectHotel = selectedHotels[getDestinationIndex(destinationCityCode, destinationIndex)];
		return selectHotel
			? selectHotel.hotel.hotelId === hotelId &&
					selectHotel.hotel.provider === provider &&
					selectHotel.hotel.boardsKey == boardsKey
			: false;
	};

	const getBoardsForDestination = (destinationIndex: number, destinationCityCode: string): string => {
		const selectedHotel = selectedHotels[getDestinationIndex(destinationCityCode, destinationIndex)];
		return selectedHotel
			? Array.from(new Set(selectedHotel.hotel.rooms.map((x) => getBoardName(x.board)))).join(',')
			: '';
	};

	const existRepeatDestination = (): boolean =>
		destinations.some(
			(destination, index) => destinations.findIndex((x, i) => x.cityCode == destination.cityCode && i != index) > -1,
		);

	const oneRoomOnQuotation = hasOnlyOneRoom(paxTotals);

	// Mensaje de avanzar de whatsapp
	const { auth } = useSelector((state: RootState) => state);
	const countryWAP = countryWAPMapping[auth.country] || '59897683318';
	const flightMessage =
		flightIndexSelected !== null ? `* Itinerario opción ${flightIndexSelected + 1}` : 'Itinerario no seleccionado';
	const hotelsMessage = selectedHotels
		.map((hotel, index) => {
			const destination = destinations[index];
			return `* ${destination.nights} noches en ${getCityNameCleaned(destination.cityName)} con el hotel ${
				hotel.hotel.hotelName
			}.`;
		})
		.join('\n');

	const optionMessage = `Hola, quiero avanzar con la cotización n* ${quotationId}:\n${flightMessage}.\n${hotelsMessage}`;
	const encodeOptionMessage = encodeURIComponent(optionMessage);
	const moveForwardLink = `https://wa.me/${countryWAP}?text=${encodeOptionMessage}`;

	// Animacion
	const buttonRef = useRef<HTMLAnchorElement>(null);
	const [isButtonVisible, setIsButtonVisible] = useState(true);

	useEffect(() => {
		if (!buttonRef.current) return;

		const observer = new IntersectionObserver(
			([entry]) => setIsButtonVisible(entry.isIntersecting),
			{ threshold: 0.5 }, // Ajusta el umbral según tus necesidades
		);

		observer.observe(buttonRef.current);

		return () => {
			if (buttonRef.current) {
				observer.unobserve(buttonRef.current);
			}
		};
	}, [buttonRef.current]); // Dependencia importante
	//Animacion

	return (
		<Container sx={{ marginBottom: `${drawerBleeding}px`, paddingX: 0 }}>
			<Global
				styles={{
					'.MuiDrawer-root > .MuiPaper-root': {
						height: `calc(70% - ${drawerBleeding}px)`,
						overflow: 'visible',
					},
				}}
			/>
			{destinations.length &&
				destinations.map((destination, destinationIndex) => (
					<>
						<Typography
							component="h1"
							variant="h6"
							sx={{
								marginBottom: '12px',
								marginTop: '12px',
								width: '100%',
								backgroundColor: '#ED6602',
								paddingLeft: '16px',
								paddingRight: '16px',
								paddingTop: '2px',
								paddingBottom: '2px',
								borderRadius: '4px',
								fontSize: '1rem',
								color: 'white',
							}}
						>
							Alojamiento en: {getCityNameCleaned(destination.cityName)}
						</Typography>
						<Container disableGutters component="main" maxWidth="md" sx={{ paddingLeft: '0', paddingRight: '0' }}>
							<Container disableGutters key={destination.cityCode} sx={{ overflow: 'auto', paddingBottom: 1 }}>
								<Grid spacing={1} wrap="nowrap" p={0} container>
									{destination.hotelOptions.length > 0 &&
										destination.hotelOptions.map((hotel, index) => (
											<Grid
												key={`hotel-card-${destination.cityCode}-${destinationIndex}-${hotel.hotelId}-${hotel.provider}-${hotel.boardsKey}`}
												sx={{
													display: 'flex',
													alignItems: 'stretch',
													minWidth: { xs: '90%', sm: '340px' },
													maxWidth: { xs: '90%', sm: '40%' },
													paddingLeft: index === 0 ? 0 : undefined, // Sin padding para el primer hijo
													paddingRight: index === destination.hotelOptions.length - 1 ? 0 : undefined, // Sin padding para el último hijo
													paddingBottom: '12px',
												}}
												item
											>
												<HotelDetailCardMultiDestination
													isSelected={isSelectedHotel(
														hotel.hotelId,
														destination.cityCode,
														destinationIndex,
														hotel.provider,
														hotel.boardsKey,
													)}
													handleSelectHotel={() => handleSelectHotel(destination.cityCode, destinationIndex, hotel)}
													hotelOption={hotel}
												/>
											</Grid>
										))}
								</Grid>
							</Container>
						</Container>
					</>
				))}
			<SwipeableDrawer
				anchor="bottom"
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
				onOpen={() => setOpenDrawer(true)}
				swipeAreaWidth={drawerBleeding}
				disableSwipeToOpen={false}
				ModalProps={{
					keepMounted: true,
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						top: pullerRef.current ? -pullerRef.current?.offsetHeight : -drawerBleeding,
						backgroundColor: 'white',
						borderTopLeftRadius: 8,
						borderTopRightRadius: 8,
						visibility: 'visible',
						bottom: drawerRef.current ? drawerRef.current?.offsetHeight : '',
						right: 0,
						left: 0,
					}}
				>
					<Puller />

					<Grid p={2} pb={3} paddingTop={1} ref={pullerRef} justifyContent="space-between" container>
						<Grid xs={12} item>
							<Typography
								component="h1"
								variant="h6"
								sx={{
									marginBottom: '4px',
									width: '100%',
									backgroundColor: '#31194B',
									paddingLeft: '16px',
									paddingRight: '16px',
									paddingTop: '4px',
									paddingBottom: '4px',
									borderRadius: '4px',
									fontSize: '1rem',
									color: 'white',
								}}
							>
								Tu selección:{' '}
							</Typography>
						</Grid>
						<Container
							maxWidth="md"
							sx={{
								display: 'flex',
								alignItems: 'center',
								lineHeight: '1.0',
								fontFamily: 'Montserrat, sans-serif',
								paddingLeft: '4px',
								paddingRight: '4px',
							}}
						>
							<Grid xs={10} item>
								{selectedCombination && flightIndexSelected != null && totalFlights > 1 && (
									<Grid xs={12} gap={0} item display="flex" flexDirection="row">
										<Grid xs={12} item>
											<Typography component="span" fontWeight="400" color="primary" fontSize={10}>
												Vuelos:{' '}
											</Typography>
											<Typography
												component="span"
												fontSize={10}
												fontWeight="bold"
												color="secondary"
												sx={{ float: 'right' }}
											>
												{' '}
												Itinerario opción {flightIndexSelected + 1}
											</Typography>
										</Grid>
									</Grid>
								)}
								{selectedHotels.length > 0 &&
									destinations.map((destination, destinationIndex) => (
										<Grid width="100%" key={`${destinationIndex}-${destination.cityCode}-selection-hotels`} container>
											<Grid xs={12} item>
												<Typography component="span" fontSize={10} color="primary" fontWeight="400">
													{destination.nights} noches en {getCityNameCleaned(destination.cityName)}:{' '}
												</Typography>
												<Typography
													component="span"
													fontSize={10}
													color="secondary"
													fontWeight="bold"
													sx={{ float: 'right' }}
												>
													{' '}
													{selectedHotels[getDestinationIndex(destination.cityCode, destinationIndex)].hotel.hotelName}
												</Typography>
											</Grid>
										</Grid>
									))}
								{selectedCombination && (
									<Grid xs={12} gap={0} item display="flex" flexDirection="row">
										<Grid xs={12} item>
											<Typography component="span" fontWeight="bold" color="primary" fontSize={13}>
												Precio promedio por adulto:{' '}
											</Typography>
											<br />
											<div style={{ float: 'right' }}>
												<Typography component="span" fontSize={13} fontWeight="bold" color="secondary">
													USD {separadorDeMiles(selectedCombination.summaryPriceForOneAdult)}
												</Typography>
												{showLocalCurrency && (
													<Typography component="span" fontSize={13} fontWeight="bold" color="secondary">
														&nbsp; / {localCurrency} &nbsp;
														{Math.round(selectedCombination.summaryPriceForOneAdult * exchangeRate)
															.toLocaleString()
															.replace(/,/g, '.')}{' '}
													</Typography>
												)}
											</div>
										</Grid>
									</Grid>
								)}
							</Grid>

							<Grid item textAlign="center" justifyContent="center" sx={{ pointerEvents: 'all' }} xs={2}>
								<Typography fontSize={12}> {openDrawer ? 'Cerrar' : ''}</Typography>
								<IconButton
									sx={{
										backgroundColor: 'secondary.main',
										color: 'white',
										...(!openDrawer && {
											animation: 'pulse 1.5s infinite',
											'@keyframes pulse': {
												'0%': {
													transform: 'scale(1)',
													boxShadow: '0 0 0 0 rgba(255, 87, 34, 0.7)',
												},
												'50%': {
													transform: 'scale(1.1)',
													boxShadow: '0 0 20px 10px rgba(255, 87, 34, 0)',
												},
												'100%': {
													transform: 'scale(1)',
													boxShadow: '0 0 0 0 rgba(255, 87, 34, 0)',
												},
											},
										}),
										'&:hover': {
											'@media (hover: none)': {
												backgroundColor: 'secondary.main',
												'&:active': {
													backgroundColor: 'secondary.main',
												},
											},
										},
										'&:active': {
											backgroundColor: 'secondary.main',
										},
									}}
									onClick={() => setOpenDrawer(!openDrawer)}
								>
									{openDrawer ? <ExpandMoreOutlined /> : <AddOutlined />}
								</IconButton>
							</Grid>
						</Container>
					</Grid>
				</Box>
				<Paper
					ref={drawerRef}
					sx={{
						height: '100%',
						overflowY: 'auto',
					}}
				>
					{' '}
					<Container>
						{selectedCombination && flightIndexSelected != null && totalFlights > 1 && (
							<>
								<Typography
									component="h1"
									variant="h6"
									sx={{
										marginTop: '0',
										width: '100%',
										backgroundColor: '#ED6602',
										paddingLeft: '16px',
										paddingRight: '16px',
										paddingTop: '2px',
										paddingBottom: '2px',
										borderRadius: '4px',
										fontSize: '0.90rem',
										color: 'white',
									}}
								>
									Vuelo seleccionado:
								</Typography>
								<Container
									disableGutters
									sx={{
										overflowX: 'auto',
										overflowY: 'hidden',
										scrollbarWidth: 'none',
										'&::-webkit-scrollbar': {
											display: 'none',
										},
									}}
								>
									<Grid spacing={2} py={1} wrap="nowrap" justifyContent={{ sx: 'right' }} container>
										{Array.from({ length: totalFlights }, (_, index) => index).map((fIndex) => {
											const isSelected = fIndex === flightIndexSelected;
											return (
												<FlightOption
													key={`flight-${fIndex}`}
													isSelected={isSelected}
													flightIndex={fIndex}
													handleFlightSelectionChange={handleFlightSelectionChange}
												/>
											);
										})}
									</Grid>
								</Container>
							</>
						)}
						{destinations.length &&
							destinations.map((destination, destinationIndex) => (
								<>
									<Typography
										component="h1"
										variant="h6"
										sx={{
											marginTop: '0',
											width: '100%',
											backgroundColor: '#ED6602',
											paddingLeft: '16px',
											paddingRight: '16px',
											paddingTop: '2px',
											paddingBottom: '2px',
											borderRadius: '4px',
											fontSize: '0.90rem',
											color: 'white',
										}}
									>
										{destination.nights} noches en {getCityNameCleaned(destination.cityName)}:{' '}
									</Typography>
									{existRepeatDestination() &&
										getBoardsForDestination(destinationIndex, destination.cityCode) != '' && (
											<Typography textAlign={{ sx: 'right', md: 'center' }} fontWeight="bold" color="primary">
												{getBoardsForDestination(destinationIndex, destination.cityCode)}{' '}
											</Typography>
										)}
									<Container
										disableGutters
										sx={{
											overflowX: 'auto',
											overflowY: 'hidden',
											scrollbarWidth: 'none',
											'&::-webkit-scrollbar': {
												display: 'none',
											},
										}}
										key={`${destination.cityCode}-container-rooms`}
									>
										<Grid spacing={2} py={1} wrap="nowrap" justifyContent={{ sx: 'right' }} container>
											{destination.hotelOptions.length > 0 &&
												destination.hotelOptions.map((hotel) => (
													<HotelOptionItem
														key={`${hotel.hotelId}-${hotel.provider}-${hotel.boardsKey}`}
														isSelected={isSelectedHotel(
															hotel.hotelId,
															destination.cityCode,
															destinationIndex,
															hotel.provider,
															hotel.boardsKey,
														)}
														hotel={hotel}
														onSelect={() => handleSelectHotel(destination.cityCode, destinationIndex, hotel)}
													/>
												))}
										</Grid>
									</Container>
								</>
							))}
						<Typography
							component="h1"
							variant="h6"
							sx={{
								marginTop: '12px',
								marginBottom: '8px',
								width: '100%',
								backgroundColor: '#31194B',
								paddingLeft: '16px',
								paddingRight: '16px',
								paddingTop: '4px',
								paddingBottom: '4px',
								borderRadius: '4px',
								fontSize: '1rem',
								color: 'white',
							}}
						>
							Presupuesto:
						</Typography>
						<Container
							disableGutters
							sx={{ overflow: oneRoomOnQuotation ? 'none' : 'auto', marginY: oneRoomOnQuotation ? 0 : 2 }}
						>
							{selectedHotels.length > 0 && selectedCombination && (
								<RoomPricesMultiDestinationPreviewList
									hotels={selectedHotels.map((hotel) => hotel.hotel)}
									combination={selectedCombination}
									localCurrency={localCurrency}
									exchangeRate={exchangeRate}
									showLocalCurrency={showLocalCurrency}
									paxTotals={paxTotals}
									showUtility={showUtility && !isMediumDown}
									markup={markup}
								/>
							)}
						</Container>
						<Typography
							component="h1"
							variant="h6"
							sx={{
								width: '100%',
								backgroundColor: '#31194B',
								paddingLeft: '16px',
								paddingRight: '16px',
								paddingTop: '4px',
								paddingBottom: '4px',
								borderRadius: '4px',
								fontSize: '1rem',
								color: 'white',
							}}
						>
							Incluye:
						</Typography>
						<IncludeMultiDestination
							destinations={destinations}
							manualServices={manualServices}
							hasFlight={totalFlights > 0}
						/>
						<Button
							variant="contained"
							color="success"
							href={moveForwardLink}
							rel="noopener noreferrer"
							target="_blank"
							sx={{
								marginBottom: '24px',
								display: 'flex',
								justifySelf: 'center',
							}}
						>
							Quiero reservar mi selección
						</Button>
						{!isButtonVisible && (
							<Box
								sx={{
									position: 'fixed',
									bottom: '4px',
									left: '50%',
									transform: 'translateX(-50%)',
									zIndex: 1000,
									borderRadius: '4px',
									animation: 'blink 1.5s infinite',
									'@keyframes blink': {
										'0%': { opacity: 0.2 },
										'50%': { opacity: 0.7 },
										'100%': { opacity: 0.2 },
									},
								}}
							>
								<ArrowCircleDownIcon fontSize="large" color="secondary" />
							</Box>
						)}
					</Container>
				</Paper>
			</SwipeableDrawer>
		</Container>
	);
};
