/* eslint-disable react/prop-types */
import React from 'react';
import { Container, Grid, Typography, Card, CardContent, CardActions, Button, Theme, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from './celeste-asistencia-en-viajes-logo.webp';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import SecurityIcon from '@mui/icons-material/Security';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GavelIcon from '@mui/icons-material/Gavel';
import { CountryCodeEnum } from 'features/common/types';

interface SeguroAsistenciaProps {
	ntsQuantity: number;
	country: CountryCodeEnum;
	/** Tasa de conversión de 1 USD a COP (por ejemplo, 1 USD = 4800 COP) */
	exchangeRate?: number;
}

const products = [
	{
		name: 'Plan Básico',
		attributes: [
			'Asistencia médica x accidente o enfermedad hasta USD 40.000',
			'Enfermedad preexistente hasta USD 500',
			'Cancelación de viaje hasta USD 1.000',
			'Gastos por vuelo demorado hasta USD 200',
			'Gastos por demora de equipaje hasta USD 200',
		],
		dailyPrice: {
			[CountryCodeEnum.URUGUAY]: 4,
			[CountryCodeEnum.PARAGUAY]: 4,
			[CountryCodeEnum.COLOMBIA]: 4,
			[CountryCodeEnum.BOLIVIA]: 5,
			[CountryCodeEnum.TN_ITAU_PY]: 4,
		},
		pdfLink: '/Producto_basico_Celeste_Asistencia_en_viajes.pdf',
	},
	{
		name: 'Plan Estándar',
		attributes: [
			'Asistencia médica x accidente o enfermedad hasta USD 60.000',
			'Enfermedad preexistente hasta USD 400',
			'Cancelación de viaje hasta USD 1.000',
			'Gastos por vuelo demorado hasta USD 120',
			'Gastos por demora de equipaje hasta USD 300',
		],
		dailyPrice: {
			[CountryCodeEnum.URUGUAY]: 6,
			[CountryCodeEnum.PARAGUAY]: 6,
			[CountryCodeEnum.COLOMBIA]: 6,
			[CountryCodeEnum.BOLIVIA]: 8,
			[CountryCodeEnum.TN_ITAU_PY]: 6,
		},
		pdfLink: '/Producto_estandar_Celeste_asistencia_en_viajes.pdf',
	},
	{
		name: 'Plan Premium',
		attributes: [
			'Asistencia médica x accidente o enfermedad hasta USD 80.000',
			'Enfermedad preexistente hasta USD 15.000',
			'Cancelación de viaje hasta USD 2.000',
			'Gastos por vuelo demorado hasta USD 450',
			'Gastos por demora de equipaje hasta USD 120',
		],
		dailyPrice: {
			[CountryCodeEnum.URUGUAY]: 8,
			[CountryCodeEnum.PARAGUAY]: 8,
			[CountryCodeEnum.COLOMBIA]: 8,
			[CountryCodeEnum.BOLIVIA]: 10,
			[CountryCodeEnum.TN_ITAU_PY]: 8,
		},
		pdfLink: '/Producto_premium_Celeste_asistencia_en_viajes.pdf',
	},
];

const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	logoContainer: {
		textAlign: 'center',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	logo: {
		maxHeight: 35,
		[theme.breakpoints.down('md')]: {
			maxHeight: 25,
		},
	},
	highlights: {
		marginBottom: theme.spacing(2),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	cardsWrapper: {
		display: 'flex',
		overflowX: 'auto',
		[theme.breakpoints.up('md')]: {
			overflowX: 'hidden',
			justifyContent: 'space-between',
		},
	},
	card: {
		flex: '0 0 auto',
		width: '80%',
		margin: theme.spacing(1),
		[theme.breakpoints.up('md')]: {
			width: `calc((99% / 3) - ${theme.spacing(2)})`,
		},
	},
	button: {
		marginTop: theme.spacing(1),
	},
}));

const SeguroAsistencia: React.FC<SeguroAsistenciaProps> = ({ ntsQuantity, country, exchangeRate = 0 }) => {
	const classes = useStyles();
	const totalDays = ntsQuantity + 1;

	return (
		<Container component="main" maxWidth="md">
			<div className={classes.paper}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Typography
							component="h1"
							variant="h6"
							sx={{
								marginBottom: '8px',
								width: '100%',
								backgroundColor: '#31194B',
								paddingLeft: '16px',
								paddingRight: '16px',
								paddingTop: '8px',
								paddingBottom: '8px',
								borderRadius: '4px',
								fontSize: '1rem',
								color: 'white',
							}}
						>
							Seguro y asistencia en viaje:
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Card sx={{ padding: '16px' }}>
							<Typography variant="subtitle1" fontWeight={500}>
								Viaja con tranquilidad y seguridad en cada paso
							</Typography>
							<Typography variant="body2" sx={{ marginTop: 1 }}>
								Contratando Asistencia y Seguro al Viajero, no solo proteges tu salud, sino también tus planes y tu
								inversión. Adquiérelo en cualquier momento antes de tu viaje y, si lo haces al momento de compra de tu
								paquete, ¡accederás a un exclusivo seguro de cancelación!
							</Typography>
							<div className={classes.highlights} style={{ marginTop: 16 }}>
								<Box display="flex" alignItems="center" mb={1}>
									<MedicalServicesIcon sx={{ mr: 1 }} />
									<Typography variant="body2">
										<strong>Cuidado Integral de tu Salud:</strong> Accede a asistencia médica, odontológica y
										hospitalaria sin complicaciones, dondequiera que te encuentres.
									</Typography>
								</Box>
								<Box display="flex" alignItems="center" mb={1}>
									<SecurityIcon sx={{ mr: 1 }} />
									<Typography variant="body2">
										<strong>Tranquilidad en Todo Momento:</strong> Viaja con la certeza de contar con ayuda inmediata
										ante cualquier imprevisto.
									</Typography>
								</Box>
								<Box display="flex" alignItems="center" mb={1}>
									<AttachMoneyIcon sx={{ mr: 1 }} />
									<Typography variant="body2">
										<strong>Protección Económica:</strong> Evita gastos inesperados por accidentes, retrasos o
										emergencias médicas. Invertir en asistencia es ahorrar a futuro.
									</Typography>
								</Box>
								<Box display="flex" alignItems="center" mb={1}>
									<AccessTimeIcon sx={{ mr: 1 }} />
									<Typography variant="body2">
										<strong>Asistencia 24/7:</strong> Nuestro servicio te respalda las 24 horas, los 7 días de la
										semana, para que nunca estés solo.
									</Typography>
								</Box>
								<Box display="flex" alignItems="center">
									<GavelIcon sx={{ mr: 1 }} />
									<Typography variant="body2">
										<strong>Seguro de Cancelación Exclusivo:</strong> Contrata la asistencia junto con tu paquete de
										viaje y protege aún más tu inversión (sujeto a condiciones).
									</Typography>
								</Box>
							</div>
						</Card>
					</Grid>
					<Grid item xs={9} sx={{ alignContent: 'center' }}>
						<Typography variant="body2" sx={{ paddingLeft: 2 }}>
							Servicio brindado por el equipo de:
						</Typography>
					</Grid>
					<Grid item xs={3} className={classes.logoContainer}>
						<img src={Logo} alt="Celeste asistencia en viajes" className={classes.logo} />
					</Grid>
					<Grid item xs={12}>
						<div className={classes.cardsWrapper}>
							{products.map((product, index) => {
								// Precio base en USD de acuerdo al país
								const dailyPriceUSD = product.dailyPrice[country] ?? 10;

								// Decidimos si mostramos en USD o convertimos a COP
								let dailyPriceDisplay: string;
								let totalPriceDisplay: string;

								if (country === CountryCodeEnum.COLOMBIA) {
									// Convertir a COP
									const dailyPriceCOP = Math.round(dailyPriceUSD * exchangeRate);
									const totalPriceCOP = dailyPriceCOP * totalDays;

									// Formatear con separador de miles (opcional: "es-CO")
									dailyPriceDisplay = `COP ${dailyPriceCOP.toLocaleString('es-CO')}`;
									totalPriceDisplay = `COP ${totalPriceCOP.toLocaleString('es-CO')}`;
								} else {
									// Mostrar en USD
									const totalUSD = dailyPriceUSD * totalDays;
									dailyPriceDisplay = `USD ${dailyPriceUSD}`;
									totalPriceDisplay = `USD ${totalUSD}`;
								}

								return (
									<Card key={index} className={classes.card}>
										<CardContent>
											<Typography variant="h6" gutterBottom>
												{product.name}
											</Typography>
											<Typography variant="body2">
												{product.attributes.map((attr, i) => (
													<li key={i}>{attr}</li>
												))}
											</Typography>
											<Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
												<Typography variant="body2" color="textSecondary">
													Valor por día por pasajero:
												</Typography>
												<Typography variant="body2" color="textSecondary" textAlign="right">
													<strong>{dailyPriceDisplay}</strong>
												</Typography>
											</Box>
											<Box display="flex" alignItems="center" justifyContent="space-between">
												<Typography variant="body2" color="textSecondary">
													Total ({totalDays} días) p/pax:
												</Typography>
												<Typography variant="body2" color="textSecondary" textAlign="right">
													<strong>{totalPriceDisplay}</strong>
												</Typography>
											</Box>
										</CardContent>
										<CardActions sx={{ justifyContent: 'end' }}>
											<Button
												size="small"
												variant="contained"
												color="primary"
												className={classes.button}
												href={product.pdfLink}
												target="_blank"
												rel="noopener noreferrer"
											>
												Ver condiciones
											</Button>
										</CardActions>
									</Card>
								);
							})}
						</div>
					</Grid>
				</Grid>
			</div>
		</Container>
	);
};

export default SeguroAsistencia;
