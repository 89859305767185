import React, { useEffect, useState } from 'react';

import { Alert, Backdrop, CircularProgress, Grid, Snackbar, Typography } from '@mui/material';
import { Layout } from 'features/common/components/Layout';
import { useParams } from 'react-router-dom';
import { ShowAlertState } from '../../features/common/types';
import { IPassengerDetail } from '../../features/passengers/types';
import { extractErrorMessage, isNotFoundError } from '../../features/quotation/helpers';
import PassengerDetail from '../../features/passengers/components/PassengerDetail';
import { ErrorType, PageInternalError404 } from '../../features/common/components/PageInternalError404';
import { makeStyles } from '@mui/styles';
import { getPassengerDetails } from '../../features/passengers/services';

export const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));
export const PassengerDetailPage = (): JSX.Element => {
	const classes = useStyles();
	const { id } = useParams<{ id: string }>();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [passengerDetail, setPassengerDetail] = useState<IPassengerDetail | null>(null);
	const [showError404, setShowError404] = useState<boolean>(false);

	const loadPassengerDetails = async () => {
		try {
			setLoading(true);
			setPassengerDetail((await getPassengerDetails(id)).data);
			setLoading(false);
		} catch (error) {
			if (isNotFoundError(error)) {
				setLoading(false);
				setShowError404(true);
			} else {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al recibir los detalles del pasajero'),
				});
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		loadPassengerDetails();
	}, [id]);

	return (
		<>
			<Layout>
				<Grid container paddingY={5} justifyContent={'center'}>
					<Grid item xs={12} sx={{ ml: 20 }}>
						<Typography variant="h4" style={{ fontWeight: 500 }}>
							Details of Passenger
						</Typography>
					</Grid>
					<Grid paddingY={5}>
						<Backdrop className={classes.backdrop} open={loading}>
							<CircularProgress color="inherit" />
						</Backdrop>
						{passengerDetail && <PassengerDetail passengerDetail={passengerDetail} />}
						{showError404 && <PageInternalError404 errorType={ErrorType.INTERNAL_AUTHORIZATION_NOT_FUND} />}
					</Grid>
				</Grid>
			</Layout>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
